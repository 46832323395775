<template>
    <div class="container">
        <div>
            <organizacao-create/>
            <organizacao-update/>
            <organizacao-delete/>
            <organizacao-competencia-demanda-list/>
            <organizacao-competencia-demanda-create/>
            <organizacao-competencia-demanda-delete/>
            <organizacao-competencia-arquivodigital-list/>
            <organizacao-competencia-arquivodigital-create/>
            <organizacao-competencia-arquivodigital-delete/>
        </div>
        <div class="row remove-padding-left-right-grid">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card card-border-top">
                    <div class="card-body">
                        <h4 class="card-title">Município</h4>
                        <p class="card-description text-right"> 
                            <!-- <button type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-filter"></i>Filtrar
                            </button> -->
                            <button @click="initAdd()"  type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-plus-circle"></i>Novo
                            </button>
                        </p>
                        <div class="table-responsive" style="min-height: 60vh;">
                            <table class="table table-hover">
                                <thead>
                                    <tr class="d-flex">
                                        <th :style="columns.one" class="text-center">#</th>
                                        <th :style="columns.two"> Nome/UF </th>
                                        <th :style="columns.three"> Tipo </th>
                                        <th :style="columns.four" class="text-center">
                                            Periodicidade RGF
                                        </th>
                                        <th :style="columns.five" class="text-center">Data Cadastro</th>
                                        <th :style="columns.six" class="text-center"> Ações </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="d-flex" v-for="(item, index) in organizacoes">
                                        <td :style="columns.one" class="text-center"> {{ index + 1 }} </td>
                                        <td :style="columns.two"> {{ item.nome }}/{{ item.uf }} </td>
                                        <td :style="columns.three"> {{ item.tipo | formatTipo }} </td>
                                        <td :style="columns.four" class="text-center">
                                            {{ item.periodicidade_rgf | formatPeriodicidadeRGF }}
                                        </td>
                                        <td :style="columns.five" class="text-center"> 
                                            {{ item.created_at | formatDataBr }} 
                                        </td>
                                        <td :style="columns.six" class="text-center">
                                            <div class="dropdown">
                                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-outline-primary">
                                                    <i class="mdi mdi-format-list-bulleted"></i>
                                                </button>
                                                
                                                <div class="dropdown-menu" 
                                                    x-placement="top-start" >
                                                    <a @click="initUpdate(item)" class="dropdown-item">
                                                        Editar
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initDelete(item)" class="dropdown-item">
                                                        Deletar
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initModalCompetenciasDemandas(item)" class="dropdown-item">
                                                        Competências Demandas
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initModalCompetenciasArquivoDigital(item)" class="dropdown-item">
                                                        Competências Arquivo Digital
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="organizacoes.length == 0">
                                        <td colspan="4" class="text-center">
                                            Nenhum item encontrado.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="mt-3">
                            <pagination :paginationData="pagination" @navigate="goToPage"></pagination>
                        </div>

                    </div>
                </div>
            </div>
        </div>
      
    </div>
</template>

<style type="text/css" scoped>
    .card-title {
        margin-bottom: 0px !important;
        font-size: 18px !important;
        padding-top: 16px !important;
        padding-left: 10px !important;
    }
    .card-description {
        margin-bottom: 8px !important;
    }
    .card-border-top {
        border-top: 3px solid #149AF9 !important;
    }
    .card-body {
        padding: 0px 10px !important;
    }
    .btn {
        padding: 7px 7px !important;
    }
    .text-center {
        justify-content: center
    }
    table tbody tr td{
        font-size: 13px;
    }
    .dropdown .dropdown-menu .dropdown-item {
        font-size: 0.9rem;
        padding: .25rem 1rem;
    }

</style>

<script>

    import moment from 'moment';
    import { eventBus } from '../../app';
    import Pagination from '../paginacao/Pagination.vue';

    export default {

        components: {
            Pagination
        },

        data(){
            return {
                columns: {
                    one: 'width: 3%;',
                    two: 'width: 31%;',
                    three: 'width: 20%;',
                    four: 'width: 20%;',
                    five: 'width: 20%;',
                    six: 'width: 5%;'
                },
                
                isLoading: false,
                //ARRAYS
                organizacoes: [],

                //PAGINATION
                pagination: {}
            }
        },

        mounted() {
            this.registryEvents();
            this.getOrganizacoes();
        },

        filters: {
            formatDataBr: function (date) {
                if (date) {
                    return moment(date).format('DD/MM/YYYY');
                }
                return '-';
            },
            formatPeriodicidadeRGF: function (periodicidade) {
                if (periodicidade == 'quadrimestral') {
                    return 'QUADRIMESTRAL';
                }
                else if (periodicidade == 'semestral') {
                    return 'SEMESTRAL';
                }
                return periodicidade;
            },
            formatTipo: function (tipo) {
                if (tipo == 'prefeitura') {
                    return 'PREFEITURA';
                }
                else if (tipo == 'camara_municipal') {
                    return 'CÂMARA MUNICIPAL';
                }
                return tipo;
            },
        },

        methods: {
            //REGISTRY EVENTS
            registryEvents(){
                this.registryEventGetOrganizacoes();
            },
            registryEventGetOrganizacoes(){
                eventBus.$on('eventGetOrganizacoes', (page) => {
                    this.getOrganizacoes(page);
                });
            },
            //FIM REGISTRY EVENTS

            emitEventOpenModalCreate(){
                eventBus.$emit('eventOpenModalCreateOrganizacao');
            },
            emitEventOpenModalUpdate(item){
                eventBus.$emit('eventOpenModalUpdateOrganizacao', 
                    item,
                    this.pagination.current_page
                );
            },
            emitEventOpenModalDelete(item){
                eventBus.$emit('eventOpenModalDeleteOrganizacao', 
                    item,
                    this.pagination.current_page
                );
            },
            emitEventOpenModalGerenciarCompetenciasDemandas(item){
                eventBus.$emit('eventOpenModalGerenciarCompetenciasDemandasOrganizacao', 
                    item,
                    this.pagination.current_page
                );
            },
            emitEventOpenModalGerenciarCompetenciasArquivoDigital(item){
                eventBus.$emit('eventOpenModalGerenciarCompetenciasArquivoDigitalOrganizacao',
                    item,
                    this.pagination.current_page
                );
            },

            initAdd()
            {
                this.emitEventOpenModalCreate();
            }, 
            initUpdate(item)
            {
                this.emitEventOpenModalUpdate(item);
            },
            initDelete(item)
            {
                this.emitEventOpenModalDelete(item);
            },
            initModalCompetenciasDemandas(item){
                this.emitEventOpenModalGerenciarCompetenciasDemandas(item);
            },
            initModalCompetenciasArquivoDigital(item){
                this.emitEventOpenModalGerenciarCompetenciasArquivoDigital(item);  
            },

            getOrganizacoes(page)
            {
                var urlOrganizacoes = '/organizacao/listdata';

                this.isLoading = true;
                this.organizacoes = [];

                axios({
                    method: 'GET',
                    url: urlOrganizacoes,
                    params: {
                        filters: this.filtros,
                        page: page //UTILIZADO NA PAGINAÇÃO
                    }
                })
                .then(response => {
                    this.organizacoes = response.data.organizacoes.data;
                    this.pagination = response.data.pagination;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao atualizar a pagina');
                });
            },
            goToPage: function (page)
            {
                this.pagination.current_page = page;
                this.getOrganizacoes(page);
            },
        }
    }
</script>
