<template>
    <div class="modal fade" id="orgao_delete_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Deletar</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            Deseja realmente DELETAR o(a) <b>{{ delete_orgao.nome }}</b>?
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-danger"
                            @click.prevent.stop="deleteOrgao">
                            Deletar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';

    export default {

    	components: {
            Dot
        },


    	data(){
            return {
                currentPagePagination: null,

            	isLoading: false,

                //ARRAYS
            	errors: [],

                //OBJECTS
                delete_orgao: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalDeleteOrgao();
        },

        methods: {
            registryEventOpenModalDeleteOrgao(){
                eventBus.$on('eventOpenModalDeleteOrgao', (deleteOrgao, currentPage) => {
                	this.errors = [];
                    this.delete_orgao = Object.assign({}, deleteOrgao);
                    this.currentPagePagination = currentPage;
                    $("#orgao_delete_modal").modal("show");
                });
            },

            emitEventGetOrgaos(){
                eventBus.$emit('eventGetOrgaos', this.currentPagePagination);
            },

            deleteOrgao()
            {
                this.isLoading = true;
                axios.delete(
                    '/orgao/' + this.delete_orgao.id
                )
                .then(response => {
                    this.isLoading = false;
                    $("#orgao_delete_modal").modal("hide");
                    toastr.success(response.data.message);
                    this.emitEventGetOrgaos();
                })
                .catch(error => {
                    this.isLoading = false;
                    let erroObject = error.response.data;
                    if(erroObject.message)
                    {
                        toastr.error(erroObject.message);
                    }
                });
            }            
        }
    }
</script>