<template>
    <div class="modal fade" id="usuario_search_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Filtrar</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">

                            <div class="form-group">
                                <label class="col-form-label">Nome:</label>
                                <input type="text" placeholder="Nome " v-model="nome_search" class="form-control">
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                    	<div 
	                        v-if="(this.nome_search.length > 0)">
	                        <button type="button" @click.prevent.stop="limparFiltros" class="btn btn-danger pull-left">
	                            Limpar Filtros
	                        </button>
	                    </div>
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="filterUsuarios">
                            Filtrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { TheMask } from 'vue-the-mask';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            TheMask,
            BasicSelect
        },


    	data(){
            return {
                configDateMask: {
                    maskedValue: false
                },
            	isLoading: false,

                //ARRAYS
            	filtros: [],
                orgaos: [],

                //OBJECTS
                orgao: {},
                nome_search: '',
                cnpj_search: '',
                orgao_id_search: null
            }
        },

        mounted(){
        	this.registryEventOpenModalSearchUsuario();
        },

        methods: {
            registryEventOpenModalSearchUsuario(){
                eventBus.$on('eventOpenModalSearchUsuario', (filtrosParam) => {
                	this.filtros = filtrosParam;
                    this.checkClearFiltersForm();
                    $("#usuario_search_modal").modal("show");
                });
            },

            //EMIT EVENTS
            emitEventGetUsuariosFilter(){
                eventBus.$emit('eventGetUsuariosFilter', 
                    this.filtros
                );
            },
            //FIM EMIT EVENTS

            checkClearFiltersForm(){
                if(this.filtros.length == 0){
                    this.clearFiltros();
                }
            },

            clearFiltros(){
                this.filtros = [];
                this.nome_search = '';
            },

            limparFiltros(){
                this.clearFiltros();
                this.filterUsuarios();
            },

            filterUsuarios(){
                this.filtros = [];
                
                if (this.nome_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'nome',
                        'value': this.nome_search
                    });
                }

                $("#usuario_search_modal").modal("hide");
                this.emitEventGetUsuariosFilter();
            }
        }
    }
</script>