<template>
    <div class="modal fade" id="unidade_update_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Atualizar</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Nome:</label>
                                <input type="text" v-model="update_unidade.nome" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Órgão:</label>
                                <basic-select :options="orgaos"
                                        :selected-option="orgao"
                                        placeholder="Selecione o Órgão"
                                        @select="onSelectOrgao">
                                </basic-select>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="updateUnidade">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { TheMask } from 'vue-the-mask';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            TheMask,
            BasicSelect
        },


    	data(){
            return {
                configDateMask: {
                    maskedValue: false
                },
                currentPagePagination: null,

            	isLoading: false,

                //ARRAYS
            	errors: [],
                orgaos: [],

                //OBJECTS
                orgao: {},
                update_unidade: {},
                update_unidade_old: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalUpdateUnidade();
        },

        methods: {
            registryEventOpenModalUpdateUnidade(){
                eventBus.$on('eventOpenModalUpdateUnidade', (updateUnidade, currentPage) => {
                	this.errors = [];
                    this.currentPagePagination = currentPage;
                    $("#unidade_update_modal").modal("show");
                    this.setDataUnidade(updateUnidade);
                    this.getOrgaos();
                });
            },

            setDataUnidade(unidadeParam){
                this.update_unidade = Object.assign({}, unidadeParam);
                this.update_unidade_old = Object.assign({}, unidadeParam);

                this.orgao = {
                    value: unidadeParam.orgao_id,
                    text: unidadeParam.orgao_nome
                };
            },

            emitEventGetUnidades(){
                eventBus.$emit('eventGetUnidades', this.currentPagePagination);
            },

            //OUTROS METODOS
            onSelectOrgao(item) {
                this.orgao = item;
                this.update_unidade.orgao_id = this.orgao.value;
            },
            //FIM OUTROS METODOS

            checkForm:function(acao) {
                this.errors = [];

                if(this.update_unidade.nome == this.update_unidade_old.nome &&
                    this.update_unidade.orgao_id == this.update_unidade_old.orgao_id
                ) {
                    this.errors.push("Altere os dados");
                    return false;
                }

                if(this.update_unidade.nome &&
                    this.update_unidade.orgao_id
                ) {
                    return true;
                }
                
                if(!this.update_unidade.nome) this.errors.push("Informe o nome");
                if(!this.update_unidade.orgao_id) this.errors.push("Selecione o município");
            },

            updateUnidade()
            {
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.put('/unidade/' + this.update_unidade.id, this.update_unidade)
                    .then(response => {

                        this.isLoading = false;
                        $("#unidade_update_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetUnidades();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            },

            getOrgaos(){
                var urlOrgaos = '/orgao/combo';
                this.isLoading = true;
                
                axios({
                    method: 'GET',
                    url: urlOrgaos
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.orgaos = [];
                    $.each(response.data, function (key, value) {
                        me.orgaos.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar órgãos');
                });
            },
        }
    }
</script>