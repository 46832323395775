<template>
    <div class="modal fade" id="update_password_usuario_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>{{ modalTitle }}</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">

                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group" v-if="hiddenInputPasswordOld == false">
                                <label class="col-form-label">Senha Atual:</label>
                                <input type="password" v-model="usuario_update_senha.password_old" class="form-control" autofocus>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Senha:</label>
                                <input type="password" v-model="usuario_update_senha.password" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Confirme Senha:</label>
                                <input type="password" v-model="usuario_update_senha.password_confirmation" class="form-control">
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="updatePassword">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';

    export default {

    	components: {
            Dot,
        },


    	data(){
            return {
            	modalTitle: 'Alterar Senha',
                hiddenInputPasswordOld: false,
                isLoading: false,
                errors: [],
                usuario_update_senha: {
                    id: null,
                    name: null,
                    password_old: null,
                    password: null,
                    password_confirmation: null
                }
            }
        },

        mounted(){
        	this.registryEventOpenModalUpdatePasswordUsuario();
            this.registryEventOpenModalUpdatePasswordUsuarioSelected();
        },

        methods: {
            registryEventOpenModalUpdatePasswordUsuario(){
                eventBus.$on('eventUpdatePasswordUsuario', (filtrosParam) => {
                	this.clearDados();
                    this.hiddenInputPasswordOld = false;
                    this.modalTitle = "Alterar Senha";
                    $("#update_password_usuario_modal").modal("show");
                });
            },

            registryEventOpenModalUpdatePasswordUsuarioSelected(){
                eventBus.$on('eventUpdatePasswordUsuarioSelected', (usuario) => {
                    this.clearDados(this);
                    this.hiddenInputPasswordOld = true;
                    this.usuario_update_senha.id = usuario.id;
                    this.usuario_update_senha.name = usuario.name;
                    this.modalTitle = "Alterar Senha - "+ this.usuario_update_senha.name;
                    $("#update_password_usuario_modal").modal("show");
                });
            },
            
            clearDados(){
                this.errors = [];
                this.usuario_update_senha = {
                    id: null,
                    name: null,
                    password_old: null,
                    password: null,
                    password_confirmation: null
                }
            },

            checkForm:function() {
                this.errors = [];

                if (this.usuario_update_senha.id > 0) {
                    if(this.usuario_update_senha.password && 
                        this.usuario_update_senha.password_confirmation) {
                        return true;
                    }

                    if(!this.usuario_update_senha.password) this.errors.push("Informe a senha");
                    if(!this.usuario_update_senha.password_confirmation) this.errors.push("Informe a confirmação da senha");
                }
                else {
                    if(this.usuario_update_senha.password_old && 
                        this.usuario_update_senha.password && 
                        this.usuario_update_senha.password_confirmation) {
                        return true;
                    }

                    if(!this.usuario_update_senha.password_old) this.errors.push("Informe a senha atual");
                    if(!this.usuario_update_senha.password) this.errors.push("Informe a senha");
                    if(!this.usuario_update_senha.password_confirmation) this.errors.push("Informe a confirmação da senha");
                }
            },
            updatePassword()
            {
                var urlUpdatePassword = '/usuario/meu/password/update';

                //SE USUARIO TIVER ID, ESTOU CLICANDO NA GRID
                if (this.usuario_update_senha.id > 0) {
                    urlUpdatePassword = '/usuario/password/update/'+this.usuario_update_senha.id;
                }

                if(this.checkForm()){
                    this.isLoading = true;
                    axios.put(urlUpdatePassword, this.usuario_update_senha)
                    .then(response => {
                        this.isLoading = false;
                        $("#update_password_usuario_modal").modal("hide");
                        toastr.success(response.data.message);
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];

                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors.password, function (key, value) {
                                    me.errors.push(value);
                                });
                            }
                        }
                        else  if(error.response.status === 409){
                            if(erroObject.message){
                                me.errors.push(erroObject.message);
                            }
                        }
                        else{
                            toastr.error(erroObject.message);
                        }
                    });
                }
            }
           
        }
    }
</script>