<template>
    <div class="modal fade" id="arquivo_digital_importacoes_delete_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Deletar Importações</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 
                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            Deseja realmente DELETAR os itens?
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-danger"
                            @click.prevent.stop="deleteImportacoesArquivoDigital">
                            Deletar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';

    export default {

    	components: {
            Dot
        },

    	data(){
            return {
                currentPagePagination: null,

            	isLoading: false,

                //ARRAYS
            	errors: [],

                //OBJECTS
                importacoes_ref: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalDeleteImportacoesArquivoDigital();
        },

        methods: {
            registryEventOpenModalDeleteImportacoesArquivoDigital(){
                eventBus.$on('eventOpenModalDeleteImportacoesArquivoDigital', (importacoesRef) => {
                	this.errors = [];
                    this.importacoes_ref = Object.assign({}, importacoesRef);
                    $("#arquivo_digital_importacoes_delete_modal").modal("show");
                });
            },

            deleteImportacoesArquivoDigital()
            {
                this.isLoading = true;
                axios.delete(
                    '/arquivodigital/importacoes/remove/' + this.importacoes_ref.import_file_ref
                )
                .then(response => {
                    this.isLoading = false;
                    $("#arquivo_digital_importacoes_delete_modal").modal("hide");
                    toastr.success(response.data.message);
                    window.location.reload();
                })
                .catch(error => {
                    this.isLoading = false;
                    let erroObject = error.response.data;
                    if(erroObject.message)
                    {
                        toastr.error(erroObject.message);
                    }
                });
            }
        }
    }
</script>