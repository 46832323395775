<template>
    <div class="modal fade" id="demanda_delete_anexo_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Remover anexo
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 
                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>
                        </div>

                        <div>
                            Deseja realmente remover o anexo?
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button 
                            class="btn btn-danger"
                            type="button" 
                            @click.prevent.stop="deleteAnexoDemanda">
                            Deletar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../../app';
    import Dot from '../../loadder/Dot.vue';

    export default {

    	components: {
            Dot,
        },

    	data(){
            return {
            	isLoading: false,

                errors: [],

                demanda: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalDeleteAnexoDemanda();
        },

        methods: {
            emitEventGetAnexosDemanda(){
                eventBus.$emit('eventGetAnexosDemanda');
            },
            registryEventOpenModalDeleteAnexoDemanda(){
                eventBus.$on('eventOpenModalDeleteAnexoDemanda', (demandaAnexoParam) => {
                    this.demanda_anexo = demandaAnexoParam;
                    $("#demanda_delete_anexo_modal").modal("show");
                });
            },
            deleteAnexoDemanda(){
                if (!this.demanda_anexo.id) {
                    toastr.error('Anexo não identificado');
                    return false;
                }
                    
                this.isLoading = true;

                var urlDeleteAnexoDemanda = '/demanda/anexos/remove';

                axios({
                    method: 'DELETE',
                    url: urlDeleteAnexoDemanda,
                    params: {
                        anexo_id: this.demanda_anexo.id
                    }
                })
                .then(response => {
                    this.isLoading = false;
                    toastr.success(response.data.message);

                    $("#demanda_delete_anexo_modal").modal("hide");
                    this.emitEventGetAnexosDemanda();
                })
                .catch(error => {
                    this.isLoading = false;
                    if (error.response.data.message) {
                        toastr.error(error.response.data.message);
                    }
                    else {
                        toastr.error('Falha ao remover arquivo');
                    }
                });
            }
        }
    }
</script>