//USUÁRIO
Vue.component('usuario-perfil', require('../components/usuario/Perfil.vue').default);
Vue.component('usuario-update-password', require('../components/usuario/UpdatePassword.vue').default);
Vue.component('usuario-update-dados', require('../components/usuario/UpdateDados.vue').default);
Vue.component('usuario-search', require('../components/usuario/Search.vue').default);
Vue.component('usuario-list', require('../components/usuario/List.vue').default);
Vue.component('usuario-create', require('../components/usuario/Create.vue').default);
Vue.component('usuario-update', require('../components/usuario/Update.vue').default);
Vue.component('usuario-delete', require('../components/usuario/Delete.vue').default);

//ORGANIZAÇÃO
Vue.component('organizacao-list', require('../components/organizacao/List.vue').default);
Vue.component('organizacao-create', require('../components/organizacao/Create.vue').default);
Vue.component('organizacao-update', require('../components/organizacao/Update.vue').default);
Vue.component('organizacao-delete', require('../components/organizacao/Delete.vue').default);

//ORGANIZAÇÃO-COMPETENCIA-DEMANDA
Vue.component('organizacao-competencia-demanda-list', require('../components/organizacao/competenciademandas/List.vue').default);
Vue.component('organizacao-competencia-demanda-create', require('../components/organizacao/competenciademandas/Create.vue').default);
Vue.component('organizacao-competencia-demanda-delete', require('../components/organizacao/competenciademandas/Delete.vue').default);

//ORGANIZAÇÃO-COMPETENCIA-ARQUIVO-DIGITAL
Vue.component('organizacao-competencia-arquivodigital-list', require('../components/organizacao/competenciaarquivodigital/List.vue').default);
Vue.component('organizacao-competencia-arquivodigital-create', require('../components/organizacao/competenciaarquivodigital/Create.vue').default);
Vue.component('organizacao-competencia-arquivodigital-delete', require('../components/organizacao/competenciaarquivodigital/Delete.vue').default);

//UNIDADE-GESTORA
Vue.component('unidade-gestora-search', require('../components/unidadegestora/Search.vue').default);
Vue.component('unidade-gestora-list', require('../components/unidadegestora/List.vue').default);
Vue.component('unidade-gestora-create', require('../components/unidadegestora/Create.vue').default);
Vue.component('unidade-gestora-update', require('../components/unidadegestora/Update.vue').default);
Vue.component('unidade-gestora-delete', require('../components/unidadegestora/Delete.vue').default);

//ORGÃO
Vue.component('orgao-search', require('../components/orgao/Search.vue').default);
Vue.component('orgao-list', require('../components/orgao/List.vue').default);
Vue.component('orgao-create', require('../components/orgao/Create.vue').default);
Vue.component('orgao-update', require('../components/orgao/Update.vue').default);
Vue.component('orgao-delete', require('../components/orgao/Delete.vue').default);

//UNIDADE
Vue.component('unidade-search', require('../components/unidade/Search.vue').default);
Vue.component('unidade-list', require('../components/unidade/List.vue').default);
Vue.component('unidade-create', require('../components/unidade/Create.vue').default);
Vue.component('unidade-update', require('../components/unidade/Update.vue').default);
Vue.component('unidade-delete', require('../components/unidade/Delete.vue').default);

//SETOR
Vue.component('setor-search', require('../components/setor/Search.vue').default);
Vue.component('setor-list', require('../components/setor/List.vue').default);
Vue.component('setor-create', require('../components/setor/Create.vue').default);
Vue.component('setor-update', require('../components/setor/Update.vue').default);
Vue.component('setor-delete', require('../components/setor/Delete.vue').default);

//DEMANDA
Vue.component('demanda-search', require('../components/demanda/Search.vue').default);
Vue.component('demanda-list', require('../components/demanda/List.vue').default);
Vue.component('demanda-create', require('../components/demanda/Create.vue').default);
Vue.component('demanda-update', require('../components/demanda/Update.vue').default);
Vue.component('demanda-delete', require('../components/demanda/Delete.vue').default);
Vue.component('demanda-tramitar', require('../components/demanda/Tramitar.vue').default);
Vue.component('demanda-hitorico', require('../components/demanda/Historico.vue').default);
Vue.component('demanda-gerenciar-status', require('../components/demanda/GerenciarStatus.vue').default);
Vue.component('demanda-anexos-modal', require('../components/demanda/anexos/List.vue').default);
Vue.component('demanda-add-anexo-modal', require('../components/demanda/anexos/Create.vue').default);
Vue.component('demanda-remove-anexo-modal', require('../components/demanda/anexos/Delete.vue').default);

//CHART
Vue.component('line-chart', require('../components/chart/LineChart.vue').default);
Vue.component('bar-chart', require('../components/chart/BarChart.vue').default);

//DASHBOARD
Vue.component('dashboard', require('../components/dashboard/Dashboard.vue').default);

//ARQUIVO-DIGITAL
Vue.component('arquivodigital-search', require('../components/arquivodigital/Search.vue').default);
Vue.component('arquivodigital-list', require('../components/arquivodigital/List.vue').default);
Vue.component('arquivodigital-create', require('../components/arquivodigital/Create.vue').default);
Vue.component('arquivodigital-update', require('../components/arquivodigital/Update.vue').default);
Vue.component('arquivodigital-delete', require('../components/arquivodigital/Delete.vue').default);
Vue.component('arquivodigital-detalhes', require('../components/arquivodigital/Detalhes.vue').default);
Vue.component('arquivodigital-anexos-modal', require('../components/arquivodigital/anexos/List.vue').default);
Vue.component('arquivodigital-add-anexo-modal', require('../components/arquivodigital/anexos/Create.vue').default);
Vue.component('arquivodigital-remove-anexo-modal', require('../components/arquivodigital/anexos/Delete.vue').default);
