<template>
    <div class="modal fade" id="organizacao_competencia_arquivo_digital_create_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Nova</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Competência:</label>
                                <input type="number" v-model="organizacao_competencia_arquivo_digital.competencia" class="form-control">
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="createCompetencia">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../../app';
    import Dot from '../../loadder/Dot.vue';

    export default {

    	components: {
            Dot
        },


    	data(){
            return {
            	isLoading: false,

                //ARRAYS
            	errors: [],

                //OBJECTS
                organizacao: {},
                organizacao_competencia_arquivo_digital: {
                    competencia: '',
                    organizacao_id: null
                }
            }
        },

        mounted(){
        	this.registryEventOpenModalCreateCompetenciaArquivoDigitalOrganizacao();
        },

        methods: {
            registryEventOpenModalCreateCompetenciaArquivoDigitalOrganizacao(){
                eventBus.$on('eventOpenModalCreateCompetenciaArquivoDigitalOrganizacao', (organizacaoParam) => {
                	this.errors = [];
                    this.resetForm();
                    this.organizacao = Object.assign({}, organizacaoParam);
                    $("#organizacao_competencia_arquivo_digital_create_modal").modal("show");
                });
            },

            emitEventGetCompetenciasArquivoDigitalOrganizacao(){
                eventBus.$emit('eventGetCompetenciasArquivoDigitalOrganizacao');
            },

            resetForm()
            {
                this.errors = [];
                this.organizacao_competencia_arquivo_digital = {
                    competencia: '',
                    organizacao_id: null
                }
            },

            checkForm:function() {
                this.errors = [];

                if(this.organizacao_competencia_arquivo_digital.competencia &&
                    this.organizacao_competencia_arquivo_digital.organizacao_id
                ) {
                    return true;
                }
                
                if(!this.organizacao_competencia_arquivo_digital.competencia) this.errors.push("Informe a competência");
                if(!this.organizacao_competencia_arquivo_digital.organizacao_id) this.errors.push("Informe o organização");
            },

            createCompetencia()
            {
                this.organizacao_competencia_arquivo_digital.organizacao_id = this.organizacao.id;
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.post('/organizacao/competenciaarquivodigital', this.organizacao_competencia_arquivo_digital).then(response => {
                        this.isLoading = false;
                        $("#organizacao_competencia_arquivo_digital_create_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetCompetenciasArquivoDigitalOrganizacao();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            },
        }
    }
</script>