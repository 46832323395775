<template>
    <div class="modal fade" id="setor_create_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Novo(a)</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Nome:</label>
                                <input type="text" placeholder="Nome do Setor" v-model="setor.nome" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Resposável:</label>
                                <input type="text" placeholder="Nome do Responsável" v-model="setor.responsavel" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Unidade:</label>
                                <basic-select :options="unidades"
                                        :selected-option="unidade"
                                        placeholder="Selecione a Unidade"
                                        @select="onSelectUnidade">
                                </basic-select>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="createSetor">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            BasicSelect
        },


    	data(){
            return {
            	isLoading: false,

                //ARRAYS
            	errors: [],
                unidades: [],

                //OBJECTS
                unidade: {},
                setor: {
                    nome: '',
                    responsavel: '',
                    unidade_id: null
                }
            }
        },

        mounted(){
        	this.registryEventOpenModalCreateSetor();
        },

        methods: {
            registryEventOpenModalCreateSetor(){
                eventBus.$on('eventOpenModalCreateSetor', () => {
                	this.errors = [];
                    this.resetForm();
                    this.getUnidades();
                    $("#setor_create_modal").modal("show");
                });
            },

            emitEventGetSetores(){
                eventBus.$emit('eventGetSetores');
            },

            resetForm()
            {
                this.errors = [];
                this.unidade = {};
                this.setor = {
                    nome: '',
                    responsavel: '',
                    unidade_id: null
                }
            },

            //OUTROS METODOS
            onSelectUnidade(item) {
                this.unidade = item;
                this.setor.unidade_id = this.unidade.value;
            },
            //FIM OUTROS METODOS

            checkForm:function() {
                this.errors = [];

                if(this.setor.nome &&
                    this.setor.responsavel &&
                    this.setor.unidade_id
                ) {
                    return true;
                }
                
                if(!this.setor.nome) this.errors.push("Informe o nome");
                if(!this.setor.responsavel) this.errors.push("Informe o responsável");
                if(!this.setor.unidade_id) this.errors.push("Selecione a Unidade");
            },

            createSetor()
            {
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.post('/setor', this.setor).then(response => {
                        this.isLoading = false;
                        $("#setor_create_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetSetores();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            },
            getUnidades(){

                var urlUnidades = '/unidade/combo';
                
                this.isLoading = true;
                
                axios({
                    method: 'GET',
                    url: urlUnidades
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.unidades = [];
                    $.each(response.data, function (key, value) {
                        me.unidades.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar unidades');
                });
            },
        }
    }
</script>