<template>
    <div class="modal fade" id="organizacao_competencia_demandas_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Competências das Demandas</b>
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <p class="card-description text-right"> 
                                <button @click="emitEventOpenModalCreate()"  type="button" class="btn btn-outline-primary">
                                    <i class="mdi mdi-plus-circle"></i>Nova
                                </button>
                            </p>
                            <div class="table-responsive" style="min-height: 30vh;">
                                <table class="table table-hover">
                                    <thead>
                                        <tr class="d-flex">
                                            <th :style="columns.one" class="text-center">#</th>
                                            <th :style="columns.two"> Competência </th>
                                            <th :style="columns.three"> Ativa </th>
                                            <th :style="columns.four" class="text-center"> Ações </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="d-flex" v-for="(item, index) in competencias">
                                            <td :style="columns.one" class="text-center"> {{ index + 1 }} </td>
                                            <td :style="columns.two"> {{ item.competencia }} </td>
                                            <td :style="columns.three"> {{ (item.ativa == true) ? 'Sim' : 'Não' }} </td>
                                            <td :style="columns.four" class="text-center">
                                                <div class="dropdown">
                                                    <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-outline-primary">
                                                        <i class="mdi mdi-format-list-bulleted"></i>
                                                    </button>
                                                    <div class="dropdown-menu" 
                                                        x-placement="top-start" >
                                                        <a @click="emitEventOpenModalDelete(item)" class="dropdown-item">
                                                            Deletar
                                                        </a>
                                                        <div class="dropdown-divider"></div>
                                                        <a @click="emitEventUpdate(item)" class="dropdown-item">
                                                            <span v-if="!item.ativa">Ativar</span>
                                                            <span v-else>Desativar</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="competencias.length == 0">
                                            <td colspan="3" class="text-center">
                                                Nenhum item encontrado.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mt-3 font-size-doze">
                                <pagination :paginationData="pagination" @navigate="goToPage"></pagination>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .modal-dialog {
        min-width: 500px;
    }
    .card-title {
        margin-bottom: 0px !important;
        font-size: 18px !important;
        padding-top: 16px !important;
        padding-left: 10px !important;
    }
    .card-description {
        margin-bottom: 8px !important;
    }
    .card-border-top {
        border-top: 3px solid #149AF9 !important;
    }
    .card-body {
        padding: 5px 10px !important;
        background-color: #fff
    }
    .btn {
        padding: 7px 7px !important;
    }
    .text-center {
        justify-content: center
    }
    table tbody tr td{
        font-size: 13px;
    }
    .font-size-doze {
        font-size: 12px;
    }
    .dropdown .dropdown-menu .dropdown-item {
        font-size: 0.9rem;
        padding: .25rem 1rem;
    }
</style> 

<script>

    import moment from 'moment';
    import { eventBus } from '../../../app';
    import Pagination from '../../paginacao/Pagination.vue';

    export default {

        components: {
            Pagination
        },

    	data(){
            return {
                columns: {
                    one: 'width: 3%;',
                    two: 'width: 70%;',
                    three: 'width: 10%;',
                    four: 'width: 12%;'
                },

                competencias: [],

                //OBJECTS
                organizacao: {},

                //PAGINATION
                pagination: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalCompetenciasDemandasOrganizacao();
            this.registryEventGetCompetenciasDemandaOrganizacao();
        },

        methods: {
            registryEventOpenModalCompetenciasDemandasOrganizacao(){
                eventBus.$on('eventOpenModalGerenciarCompetenciasDemandasOrganizacao', (organizacaoParam) => {
                    this.organizacao = Object.assign({}, organizacaoParam);
                    this.getCompetenciasDemandaOrganizacao();
                    $("#organizacao_competencia_demandas_modal").modal("show");
                });
            },
            registryEventGetCompetenciasDemandaOrganizacao(){
                eventBus.$on('eventGetCompetenciasDemandaOrganizacao', (page) => {
                    this.getCompetenciasDemandaOrganizacao(page);
                });
            },
            emitEventOpenModalCreate(){
                eventBus.$emit('eventOpenModalCreateCompetenciaDemandaOrganizacao', this.organizacao);
            },
            emitEventOpenModalDelete(item){
                eventBus.$emit('eventOpenModalDeleteCompetenciaDemandaOrganizacao', 
                    item,
                    this.pagination.current_page
                );
            },
            emitEventUpdate(item){
                this.isLoading = true;
                axios.put('/organizacao/competenciademanda/' + item.id, {
                    ativa: !item.ativa
                })
                .then(response => {

                    this.isLoading = false;
                    $("#organizacao_update_modal").modal("hide");
                    toastr.success(response.data.message);
                    this.getCompetenciasDemandaOrganizacao();
                })
                .catch(error => {
                    let me = this;
                    this.isLoading = false;
                    let erroObject = error.response.data;
                    this.errors = [];
                    if( error.response.status === 422 ){
                        if (erroObject.errors) {
                            $.each(erroObject.errors, function (key, value) {
                                toastr.error(value[0]);
                            });
                        }
                    }
                    else {
                        toastr.error(erroObject.message);
                    }
                });
            },
            getCompetenciasDemandaOrganizacao(page)
            {
                var urlCompetenciaDemandaOrganizacao = '/organizacao/competenciademanda';
                this.isLoading = true;
                this.competencias = [];
                axios({
                    method: 'GET',
                    url: urlCompetenciaDemandaOrganizacao,
                    params: {
                        organizacao_id: this.organizacao.id,
                        page: page //UTILIZADO NA PAGINAÇÃO
                    }
                })
                .then(response => {
                    this.competencias = response.data.competencias.data;
                    this.pagination = response.data.pagination;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao atualizar a pagina');
                });
            },
            goToPage: function (page)
            {
                this.pagination.current_page = page;
                this.getCompetenciasDemandaOrganizacao(page);
            },
        }
    }
</script>