<template>
    <div class="container">
        <div>
            <unidade-search/>
            <unidade-create/>
            <unidade-update/>
            <unidade-delete/>
        </div>
        <div class="row remove-padding-left-right-grid">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card card-border-top">
                    <div class="card-body">
                        <h4 class="card-title">Unidades</h4>
                        <p class="card-description text-right"> 
                            <button @click="initSearch()" type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-filter"></i>
                                {{ titleButtonFilter }}
                            </button>
                            <button @click="initAdd()"  type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-plus-circle"></i>Nova
                            </button>
                        </p>
                        <div class="table-responsive" style="min-height: 60vh;">
                            <table class="table table-hover">
                                <thead>
                                    <tr class="d-flex">
                                        <th :style="columns.one"> # </th>
                                        <th :style="columns.two"> Nome </th>    
                                        <th :style="columns.three"> Órgão </th>
                                        <th :style="columns.four"> Ações </th>
                                    </tr>
                                </thead>
                                <tbody style="font-size: 20px;">
                                    <tr class="d-flex" v-for="(item, index) in unidades">
                                        <td :style="columns.one"> {{ index + 1 }} </td>
                                        <td :style="columns.two"> {{ item.nome }}</td>
                                        <td :style="columns.three" class="text-left"> 
                                            {{ item.orgao_nome }} 
                                        </td>
                                        <td :style="columns.four">
                                            <div class="dropdown">
                                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-outline-primary">
                                                    <i class="mdi mdi-format-list-bulleted"></i>
                                                </button>
                                                
                                                <div class="dropdown-menu" 
                                                    x-placement="top-start" >
                                                    <a @click="initUpdate(item)" class="dropdown-item">
                                                        Editar
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initDelete(item)" class="dropdown-item">
                                                        Deletar
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="unidades.length == 0">
                                        <td colspan="4" class="text-center">
                                            Nenhum item encontrado.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="mt-3">
                            <pagination :paginationData="pagination" @navigate="goToPage"></pagination>
                        </div>

                    </div>
                </div>
            </div>
        </div>
      
    </div>
</template>

<style type="text/css" scoped>
    .card-title {
        margin-bottom: 0px !important;
        font-size: 18px !important;
        padding-top: 16px !important;
        padding-left: 10px !important;
    }
    .card-description {
        margin-bottom: 8px !important;
    }
    .card-border-top {
        border-top: 3px solid #149AF9 !important;
    }
    .card-body {
        padding: 0px 10px !important;
    }
    .btn {
        padding: 7px 7px !important;
    }
    table tbody tr td{
        font-size: 13px;
    }
</style>

<script>

    import moment from 'moment';
    import { eventBus } from '../../app';
    import Pagination from '../paginacao/Pagination.vue';

    export default {

        components: {
            Pagination
        },

        data(){
            return {
                columns: {
                    one: 'width: 3%;',
                    two: 'width: 48%;',
                    three: 'width: 43%;',
                    four: 'width: 5%;'
                },
                titleButtonFilter: 'Filtrar',
                
                isLoading: false,
                //ARRAYS
                unidades: [],

                //SEARCH DATA
                filtros: [],

                //PAGINATION
                pagination: {}
            }
        },

        mounted() {
            this.registryEvents();
            this.getUnidades();
        },

        methods: {
            //REGISTRY EVENTS
            registryEvents(){
                this.registryEventGetUnidades();
                this.registryEventGetUnidadesFilter();
            },
            registryEventGetUnidades(){
                eventBus.$on('eventGetUnidades', (page) => {
                    this.filtros = [];
                    this.setTitleFilterButton();
                    this.getUnidades(page);
                });
            },
            registryEventGetUnidadesFilter(){
                eventBus.$on('eventGetUnidadesFilter', (filtrosEvent) => {
                    this.filtros = filtrosEvent;
                    this.setTitleFilterButton();
                    this.getUnidades();
                });
            },
            //FIM REGISTRY EVENTS
            emitEventOpenModalSearch(){
                eventBus.$emit('eventOpenModalSearchUnidade', this.filtros);
            },
            emitEventOpenModalCreate(){
                eventBus.$emit('eventOpenModalCreateUnidade');
            },
            emitEventOpenModalUpdate(item){
                eventBus.$emit('eventOpenModalUpdateUnidade', 
                    item,
                    this.pagination.current_page
                );
            },
            emitEventOpenModalDelete(item){
                eventBus.$emit('eventOpenModalDeleteUnidade', 
                    item,
                    this.pagination.current_page
                );
            },

            initSearch(){
                this.emitEventOpenModalSearch();
            },
            initAdd()
            {
                this.emitEventOpenModalCreate();
            }, 
            initUpdate(item)
            {
                this.emitEventOpenModalUpdate(item);
            },
            initDelete(item)
            {
                this.emitEventOpenModalDelete(item);
            },

            getUnidades(page)
            {
                var urlUnidades = '/unidade/listdata';

                this.isLoading = true;

                axios({
                    method: 'GET',
                    url: urlUnidades,
                    params: {
                        filters: this.filtros,
                        page: page //UTILIZADO NA PAGINAÇÃO
                    }
                })
                .then(response => {
                    this.unidades = response.data.unidades.data;
                    this.pagination = response.data.pagination;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao atualizar a pagina');
                });
            },
            goToPage: function (page)
            {
                this.pagination.current_page = page;
                this.getUnidades(page);
            },
            setTitleFilterButton(){
                if(this.filtros.length > 0){
                    var textFilter = this.filtros.length > 1 ? 'Filtros' : 'Filtro';
                    this.titleButtonFilter = '('+this.filtros.length+') '+textFilter;
                }
                else {
                    this.titleButtonFilter = 'Filtrar'; 
                }
            },
        }
    }
</script>
