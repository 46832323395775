<template>
    <div class="modal fade" id="usuario_update_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Atualizar</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Nome:</label>
                                <input type="text" placeholder="Nome" v-model="update_usuario.name" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Email:</label>
                                <input type="email" placeholder="Email" v-model="update_usuario.email" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Órgão:</label>
                                <basic-select :options="orgaos"
                                        :selected-option="orgao"
                                        placeholder="Selecione o Órgão"
                                        @select="onSelectOrgao">
                                </basic-select>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Setor:</label>
                                <basic-select :options="setores"
                                        :selected-option="setor"
                                        placeholder="Selecione o setor"
                                        @select="onSelectSetor">
                                </basic-select>
                            </div>

                            <div class="form-group" v-show="update_usuario.setor_id > 0">
                                <label class="col-form-label" for="title">Responsável Setor:</label>
                                <basic-select :options="opcoes_responsavel_setor"
                                        :selected-option="responsavel_setor"
                                        placeholder="Selecione"
                                        @select="onSelectResponsavelSetor">
                                </basic-select>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="updateUsuario">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            BasicSelect
        },


    	data(){
            return {
            	isLoading: false,

                //ARRAYS
            	errors: [],
                orgaos: [],
                setores: [],
                opcoes_responsavel_setor: [
                    {
                        'value': true,
                        'text': 'Sim'
                    },
                    {
                        'value': false,
                        'text': 'Não'
                    }
                ],

                //OBJECTS
                orgao: {},
                setor: {},
                responsavel_setor: {
                    'value': false,
                    'text': 'Não'
                },
                update_usuario: {},
                update_usuario_old: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalUpdateUsuarioSelected();
        },

        methods: {
            registryEventOpenModalUpdateUsuarioSelected(){
                eventBus.$on('eventUpdateDadosUsuarioSelected', (updateUsuario) => {
                	this.errors = [];
                    this.update_usuario = Object.assign({}, updateUsuario);
                    this.update_usuario_old = Object.assign({}, updateUsuario);
                    this.orgao = {
                        'value': updateUsuario.orgao_id,
                        'text': updateUsuario.orgao_nome
                    };
                    this.setor = {
                        'value': updateUsuario.setor_id,
                        'text': updateUsuario.setor_nome
                    };
                    this.responsavel_setor = {
                        'value': updateUsuario.responsavel_setor,
                        'text': (updateUsuario.responsavel_setor) ? 'Sim' : 'Não'
                    };
                    this.getOrgaos();
                    this.getSetores();
                    $("#usuario_update_modal").modal("show");
                });
            },

            emitEventGetUsuarios(){
                eventBus.$emit('eventGetUsuarios');
            },

            onSelectOrgao(item) {
                this.orgao = item;
                this.update_usuario.orgao_id = this.orgao.value;
                this.setor = {};
                this.update_usuario.setor_id = null;
                this.responsavel_setor = {
                    'value': false,
                    'text': 'Não'
                };
                this.update_usuario.responsavel_setor = false;
            },

            onSelectSetor(item) {
                this.setor = item;
                this.update_usuario.setor_id = this.setor.value;
                this.orgao = {};
                this.update_usuario.orgao_id = null;
                this.responsavel_setor = {
                    'value': false,
                    'text': 'Não'
                };
                this.update_usuario.responsavel_setor = false;
            },
            onSelectResponsavelSetor(item) {
                this.responsavel_setor = item;
                this.update_usuario.responsavel_setor = this.responsavel_setor.value;
            },

            checkForm:function() {
                this.errors = [];

                if(this.update_usuario.name == this.update_usuario_old.name &&
                    this.update_usuario.email == this.update_usuario_old.email &&
                    this.update_usuario.orgao_id == this.update_usuario_old.orgao_id &&
                    this.update_usuario.setor_id == this.update_usuario_old.setor_id &&
                    this.update_usuario.responsavel_setor == this.update_usuario_old.responsavel_setor
                ) {
                    this.errors.push("Altere os dados");
                    return false;
                }

                if(this.update_usuario.name &&
                    this.update_usuario.email) {

                    if(!this.update_usuario.orgao_id && !this.update_usuario.setor_id){
                        this.errors.push("Selecione um ÓRGÃO ou SETOR para o usuário.")
                        return false;
                    }

                    return true;
                }

                if(!this.update_usuario.name) this.errors.push("Informe o nome");
                if(!this.update_usuario.email) this.errors.push("Informe o email");
                // if(!this.update_usuario.setor_id) this.errors.push("Selecione o setor.");
            },

            updateUsuario()
            {
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.put('/usuario/' + this.update_usuario.id, this.update_usuario)
                    .then(response => {

                        this.isLoading = false;
                        $("#usuario_update_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetUsuarios();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            },
            getOrgaos(){
                var urlOrgaos = '/orgao/combo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlOrgaos
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.orgaos = [];
                    $.each(response.data, function (key, value) {
                        me.orgaos.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar órgãos');
                });
            },
            getSetores(){
                var urlSetores = '/setor/combo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlSetores
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.setores = [];
                    $.each(response.data, function (key, value) {
                        me.setores.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar setores');
                });
            },
        }
    }
</script>