<template>
    <div class="modal fade" id="arquivo_digital_create_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Novo(a)</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="row form-group">
                                <div class="col-lg-9">
                                    <label class="col-form-label" for="title">Tipo:</label>
                                    <basic-select :options="tipos"
                                            :selected-option="tipo"
                                            placeholder="Selecione o tipo"
                                            @select="onSelectTipo">
                                    </basic-select>
                                </div>
                                <div class="col-lg-3">
                                    <label class="col-form-label" for="title">Competência:</label>
                                    <input style="height: 44px;" type="number" placeholder="Não Informado" v-model="arquivo_digital.competencia" class="form-control" disabled>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Descrição Resumida:</label>
                                <textarea
                                    class="form-control" 
                                    rows="4"
                                    v-model="arquivo_digital.descricao_resumida"
                                    placeholder="Descrição..."></textarea>
                            </div>
                            <div v-if="arquivo_digital.tipo == 'empenho'">
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Número do Empenho:</label>
                                        <the-mask 
                                        class="form-control"
                                        placeholder="12345678/2022"
                                        :mask="['########/####']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="arquivo_digital.empenho_numero"/>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Data Emissão:</label>
                                        <the-mask 
                                        class="form-control"
                                        placeholder="01/01/2021"
                                        :mask="['##/##/####']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="arquivo_digital.empenho_data_emissao"/>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Credor:</label>
                                        <input type="text" placeholder="1 - Joao da Silva" v-model="arquivo_digital.empenho_credor" class="form-control">
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">CPF/CNPJ do Credor:</label>
                                        <the-mask 
                                        class="form-control"
                                        placeholder="123.456.789-09/12.345.678/0001-00"
                                        :mask="['###.###.###-##', '##.###.###/####-##']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="arquivo_digital.empenho_documento_credor"/>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label" for="title">Objeto do Empenho:</label>
                                    <textarea 
                                        class="form-control" 
                                        rows="4"
                                        v-model="arquivo_digital.empenho_objeto"
                                        placeholder="Objeto do Empenho..."></textarea>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Órgão Orçamentário do Empenho:</label>
                                        <basic-select :options="orgaos"
                                                :selected-option="orgao"
                                                placeholder="Selecione o órgão"
                                                @select="onSelectOrgao">
                                        </basic-select>
                                    </div>

                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Fornecedor:</label>
                                        <input style="height: 44px;" type="text" placeholder="Fornecedor" v-model="arquivo_digital.empenho_fornecedor" class="form-control">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Classificação do Empenho:</label>
                                        <input type="text" placeholder="1.23.45.67 - Classificação" v-model="arquivo_digital.empenho_classificacao" class="form-control">
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Ação do Empenho:</label>
                                        <input type="text" placeholder="1.23 - Ação" v-model="arquivo_digital.empenho_acao" class="form-control">
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <label class="col-form-label" for="title">Valor do Empenho:</label>
                                    <money 
                                    class="form-control"
                                    v-model="arquivo_digital.empenho_valor" 
                                    v-bind="money"></money>
                                </div>
                            </div>
                            <div v-if="arquivo_digital.tipo == 'liquidacao'">
                                <div class="form-group">
                                    <label class="col-form-label" for="title">Empenho:</label>
                                    <basic-select :options="empenhos"
                                            :selected-option="empenho"
                                            placeholder="Selecione o empenho"
                                            @select="onSelectEmpenho">
                                    </basic-select>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-4">
                                        <label class="col-form-label" for="title">Data Vencimento:</label>
                                        <the-mask 
                                        class="form-control"
                                        placeholder="01/01/2021"
                                        :mask="['##/##/####']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="arquivo_digital.liquidacao_data_vencimento"/>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="col-form-label" for="title">Número Aviso de Liquidação:</label>
                                        <input type="number" placeholder="Número Aviso de Liquidação" v-model="arquivo_digital.liquidacao_numero_aviso" class="form-control">
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="col-form-label" for="title">Data de Emissão:</label>
                                        <the-mask 
                                        class="form-control"
                                        placeholder="01/01/2021"
                                        :mask="['##/##/####']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="arquivo_digital.liquidacao_data_emissao"/>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Documento Fiscal:</label>
                                        <input type="text" placeholder="Documento Fiscal" v-model="arquivo_digital.liquidacao_documento_fiscal" class="form-control">
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Valor da Liquidação:</label>
                                        <money 
                                        class="form-control"
                                        v-model="arquivo_digital.liquidacao_valor"
                                        v-bind="money"></money>
                                    </div>
                                </div>
                            </div>
                            <div v-if="arquivo_digital.tipo == 'pagamento'">
                                <div class="form-group">
                                    <label class="col-form-label" for="title">Liquidação:</label>
                                    <basic-select :options="liquidacoes"
                                            :selected-option="liquidacao"
                                            placeholder="Selecione a liquidação"
                                            @select="onSelectLiquidacao">
                                    </basic-select>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Data de Movimentação:</label>
                                        <the-mask 
                                        class="form-control"
                                        placeholder="01/01/2021"
                                        :mask="['##/##/####']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="arquivo_digital.pagamento_data_movimentacao"/>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Número de Sequência:</label>
                                        <input type="number" placeholder="Número de Sequência" v-model="arquivo_digital.pagamento_numero_sequencia" class="form-control">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Valor do Pagamento:</label>
                                        <money 
                                        class="form-control"
                                        v-model="arquivo_digital.pagamento_valor_pago"
                                        v-bind="money"></money>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Conta Contábil:</label>
                                        <input type="text" placeholder="Conta Contábil" v-model="arquivo_digital.pagamento_conta_contabil" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div v-if="arquivo_digital.tipo == 'prestacao_contas'">
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Nome:</label>
                                        <input type="text" placeholder="Nome" v-model="arquivo_digital.prestacao_contas_nome" class="form-control">
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Ano:</label>
                                        <input type="number" placeholder="Ano" v-model="arquivo_digital.prestacao_contas_ano" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div v-if="arquivo_digital.tipo == 'licitacao'">
                                <!-- <div class="row form-group" v-for="(find, index) in this.arquivo_digital.licitacao_orgao">
                                    <div class="col-lg-1" style="display: flex;align-items: center;">
                                        <label class="col-form-label" for="title">Órgão:</label>
                                    </div>
                                    <div class="col-lg-10" style="padding-left: 0px;">
                                        <input type="text" placeholder="Órgão"  v-model="arquivo_digital.licitacao_orgao[index]" class="form-control">
                                    </div>
                                    <div class="col-lg-1" style="padding-left: 0px;">
                                        <span v-if="index == 0">
                                            <button type="button" class="btn btn-outline-primary button-add-remove-input" @click="addInputOrgao()">
                                                 <i class="mdi mdi-plus-circle"></i>
                                            </button>
                                        </span>
                                        <span v-if="index > 0">
                                            <button type="button" class="btn btn-outline-danger button-add-remove-input" @click="removeInputOrgao(index)">
                                                 <i class="mdi mdi-trash-can"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div> -->

                                <div class="row form-group">
                                    <div class="col-lg-1" style="display: flex;align-items: center;">
                                        <label class="col-form-label" for="title">Órgão(s):</label>
                                    </div>
                                    <div :class="(itemsOrgaosLicitacao.length == 0) ? 'col-lg-11' : 'col-lg-10'">
                                        <multi-select 
                                            :options="orgaos"
                                            :selected-options="itemsOrgaosLicitacao"
                                            placeholder="Selecione o órgão"
                                            @select="onSelectOrgaoLicitacao">
                                        </multi-select>
                                    </div>
                                    <div class="col-lg-1" style="padding-left: 0px;" v-if="itemsOrgaosLicitacao.length > 0">
                                        <button type="button" class="btn btn-outline-danger btn-clear-orgaos-selected-licitacao" @click="resetOrgaosLicitacao()">
                                             <i class="mdi mdi-backspace"></i>
                                        </button>
                                    </div>
                                </div>

                                <!-- <div class="row form-group" v-for="(find, index) in this.arquivo_digital.licitacao_orgaos">
                                    <div class="col-lg-1" style="display: flex;align-items: center;">
                                        <label class="col-form-label" for="title">Órgão:</label>
                                    </div>
                                    <div class="col-lg-10" style="padding-left: 0px;">
                                        
                                        <multi-select :options="orgaos"
                                                :selected-option="orgaos_temp_licitacao"
                                                placeholder="Selecione o orgao"
                                                @select="onSelectOrgaoLicitacao">
                                        </multi-select>

                                    </div>
                                    <div class="col-lg-1" style="padding-left: 0px;">
                                        <span v-if="index == 0">
                                            <button type="button" class="btn btn-outline-primary button-add-remove-input" @click="addInputOrgao()">
                                                 <i class="mdi mdi-plus-circle"></i>
                                            </button>
                                        </span>
                                        <span v-if="index > 0">
                                            <button type="button" class="btn btn-outline-danger button-add-remove-input" @click="removeInputOrgao(index)">
                                                 <i class="mdi mdi-trash-can"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div> -->
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Número da Licitação:</label>
                                        <input type="number" placeholder="Número da Licitação" v-model="arquivo_digital.licitacao_numero" class="form-control">
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Modalidade:</label>
                                        <basic-select :options="modalidades_licitacao"
                                                :selected-option="modalidade_licitacao"
                                                placeholder="Selecione a modalidade"
                                                @select="onSelectModalidade">
                                        </basic-select>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Data da Licitação:</label>
                                        <the-mask 
                                        class="form-control"
                                        placeholder="01/01/2021"
                                        :mask="['##/##/####']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="arquivo_digital.licitacao_data"/>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Valor da Licitação:</label>
                                        <money 
                                        class="form-control"
                                        v-model="arquivo_digital.licitacao_valor"
                                        v-bind="money"></money>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label" for="title">Objeto da Licitação:</label>
                                    <textarea
                                        class="form-control" 
                                        rows="4"
                                        v-model="arquivo_digital.licitacao_objeto"
                                        placeholder="Objeto da Licitação..."></textarea>
                                </div>
                                <div class="row form-group" v-for="(find, index) in this.arquivo_digital.licitacao_vencedor">
                                    <div class="col-lg-3" style="display: flex;align-items: center;">
                                        <label class="col-form-label" for="title">Vencedor da Licitação:</label>
                                    </div>
                                    <div class="col-lg-8" style="padding-left: 0px;">
                                        <input type="text" placeholder="Vencedor da Licitação"  v-model="arquivo_digital.licitacao_vencedor[index]" class="form-control">
                                    </div>
                                    <div class="col-lg-1" style="padding-left: 0px;">
                                        <span v-if="index == 0">
                                            <button type="button" class="btn btn-outline-primary button-add-remove-input" @click="addInputVencedorLicitacao()">
                                                 <i class="mdi mdi-plus-circle"></i>
                                            </button>
                                        </span>
                                        <span v-if="index > 0">
                                            <button type="button" class="btn btn-outline-danger button-add-remove-input" @click="removeInputVencedorLicitacao(index)">
                                                 <i class="mdi mdi-trash-can"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="arquivo_digital.tipo == 'setor_pessoal'">
                            <div class="row form-group">
                                <div class="col-lg-6">
                                    <label class="col-form-label" for="title">Nome:</label>
                                    <input type="text" placeholder="Nome" v-model="arquivo_digital.setor_pessoal_nome" class="form-control">
                                </div>
                                <div class="col-lg-6">
                                    <label class="col-form-label" for="title">CPF:</label>
                                    <the-mask
                                        class="form-control" 
                                        placeholder="012.456.789-00" 
                                        v-model="arquivo_digital.setor_pessoal_cpf"
                                        :mask="['###.###.###-##']"/> 
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label" for="title">Cargo:</label>
                                <input type="text" placeholder="Cargo" v-model="arquivo_digital.setor_pessoal_cargo" class="form-control">
                            </div>
                            <div class="form-group">
                                <label class="col-form-label" for="title">Secretaria:</label>
                                <input type="text" placeholder="Cargo" v-model="arquivo_digital.setor_pessoal_secretaria" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="createArquivoDigital">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .modal-dialog {
        min-width: 700px;
    }
    .button-add-remove-input {
        width: 45px; 
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-clear-orgaos-selected-licitacao {
        width: 45px; 
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
    }
</style> 

<script>

    import { Money } from 'v-money';
	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { TheMask } from 'vue-the-mask';
    import { BasicSelect, MultiSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            Money,
            TheMask,
            BasicSelect,
            MultiSelect
        },


    	data(){
            return {
                configDateMask: {
                    maskedValue: true
                },
                //MASK-MONEY
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'R$ ',
                    //suffix: ' #',
                    precision: 2,
                    masked: false
                },
            	isLoading: false,

                //ARRAYS
            	errors: [],
                tipos: [
                    {
                        'text': 'Empenho', 
                        'value': 'empenho'
                    },
                    {
                        'text': 'Liquidação', 
                        'value': 'liquidacao'
                    },
                    {
                        'text': 'Pagamento', 
                        'value': 'pagamento'
                    },
                    {
                        'text': 'Prestação de Contas', 
                        'value': 'prestacao_contas'
                    },
                    {
                        'text': 'Licitação', 
                        'value': 'licitacao'
                    },
                    /*{
                        'text': 'Setor Pessoal', 
                        'value': 'setor_pessoal'
                    },*/
                    {
                        'text': 'Outros', 
                        'value': 'outros'
                    }
                ],
                empenhos: [],
                liquidacoes: [],
                modalidades_licitacao: [
                    {
                        'text': 'Concorrência', 
                        'value': 'concorrencia'
                    },
                    {
                        'text': 'Pregão Presencial', 
                        'value': 'pregao_presencial'
                    },
                    {
                        'text': 'Pregão Eletrônico', 
                        'value': 'pregao_eletronico'
                    },
                    {
                        'text': 'Tomada de Preços', 
                        'value': 'tomada_precos'
                    },
                    {
                        'text': 'Dispensa Presencial', 
                        'value': 'dispensa_presencial'
                    },
                    {
                        'text': 'Dispensa Eletrônica', 
                        'value': 'dispensa_eletronica'
                    }
                ],
                orgaos: [],

                //OBJECTS
                tipo: {},
                empenho: {},
                liquidacao: {},
                modalidade_licitacao: {},
                orgao: {},
                orgao_licitacao: {},
                arquivo_digital: {
                    tipo: '',
                    competencia: '',
                    descricao_resumida: '',
                    empenho_numero: '',
                    empenho_data_emissao: '',
                    empenho_credor: '',
                    empenho_documento_credor: '',
                    empenho_objeto: '',
                    empenho_orgao_orcamentario_id: null,
                    empenho_fornecedor: '',
                    empenho_classificacao: '',
                    empenho_acao: '',
                    empenho_valor: '',
                    liquidacao_empenho_numero: '',
                    liquidacao_data_vencimento: '',
                    liquidacao_numero_aviso: '',
                    liquidacao_data_emissao: '',
                    liquidacao_documento_fiscal: '',
                    liquidacao_valor: '',

                    pagamento_liquidacao_numero_aviso: '',
                    pagamento_data_movimentacao: '',
                    pagamento_numero_sequencia: '',
                    pagamento_valor_pago: '',
                    pagamento_conta_contabil: '',

                    prestacao_contas_nome: '',
                    prestacao_contas_ano: '',

                    // licitacao_orgaos: [''],
                    licitacao_orgaos: [],
                    licitacao_data: '',
                    licitacao_numero: '',
                    licitacao_modalidade: '',
                    licitacao_objeto: '',
                    licitacao_vencedor: [],
                    licitacao_valor: '',
                    //orgaos_temp_licitacao: [],
                    

                    setor_pessoal_nome: '',
                    setor_pessoal_cpf: '',
                    setor_pessoal_cargo: '',
                    setor_pessoal_secretaria: ''
                },
                itemsOrgaosLicitacao: [],
                lastSelectItemOrgaoLicitacao: {},

                ano_competencia: null
            }
        },

        mounted(){
        	this.registryEventOpenModalCreateArquivoDigital();
        },

        methods: {
            addInputVencedorLicitacao(){
                this.arquivo_digital.licitacao_vencedor.push('');
            },
            removeInputVencedorLicitacao(indexFind){
                if(indexFind > 0){
                    this.arquivo_digital.licitacao_vencedor.splice(indexFind, 1);
                }
            },
            registryEventOpenModalCreateArquivoDigital(){
                eventBus.$on('eventOpenModalCreateArquivoDigital', (anoCompetencia) => {
                	this.errors = [];
                    this.ano_competencia = anoCompetencia;
                    this.resetForm();
                    $("#arquivo_digital_create_modal").modal("show");
                });
            },
            emitEventGetArquivosDigital(){
                eventBus.$emit('eventGetArquivosDigital');
            },
            resetFormByTipo(){
                this.errors = [];
                this.itemsOrgaosLicitacao = [];
                this.lastSelectItemOrgaoLicitacao = {};
                this.empenho = {};
                this.liquidacao = {};
                if(this.arquivo_digital.tipo == 'empenho'){
                    this.arquivo_digital = {
                        tipo: this.arquivo_digital.tipo,
                        competencia: this.arquivo_digital.competencia,
                        descricao_resumida: this.arquivo_digital.descricao_resumida,
                        empenho_numero: this.arquivo_digital.empenho_numero,
                        empenho_data_emissao: this.arquivo_digital.empenho_data_emissao,
                        empenho_credor: this.arquivo_digital.empenho_credor,
                        empenho_documento_credor: this.arquivo_digital.empenho_documento_credor,
                        empenho_objeto: this.arquivo_digital.empenho_objeto,
                        empenho_orgao_orcamentario_id: this.arquivo_digital.empenho_orgao_orcamentario_id,
                        empenho_fornecedor: this.arquivo_digital.empenho_fornecedor,
                        empenho_classificacao: this.arquivo_digital.empenho_classificacao,
                        empenho_acao: this.arquivo_digital.empenho_acao,
                        empenho_valor: this.arquivo_digital.empenho_valor,
                    };
                }
                else if(this.arquivo_digital.tipo == 'liquidacao'){
                    this.arquivo_digital = {
                        tipo: this.arquivo_digital.tipo,
                        competencia: this.arquivo_digital.competencia,
                        descricao_resumida: this.arquivo_digital.descricao_resumida,
                        liquidacao_empenho_numero: this.arquivo_digital.liquidacao_empenho_numero,
                        liquidacao_data_vencimento: this.arquivo_digital.liquidacao_data_vencimento,
                        liquidacao_numero_aviso: this.arquivo_digital.liquidacao_numero_aviso,
                        liquidacao_data_emissao: this.arquivo_digital.liquidacao_data_emissao,
                        liquidacao_documento_fiscal: this.arquivo_digital.liquidacao_documento_fiscal,
                        liquidacao_valor: this.arquivo_digital.liquidacao_valor
                    };
                }
                else if(this.arquivo_digital.tipo == 'pagamento'){
                    this.arquivo_digital = {
                        tipo: this.arquivo_digital.tipo,
                        competencia: this.arquivo_digital.competencia,
                        descricao_resumida: this.arquivo_digital.descricao_resumida,
                        pagamento_liquidacao_numero_aviso: this.arquivo_digital.pagamento_liquidacao_numero_aviso,
                        pagamento_data_movimentacao: this.arquivo_digital.pagamento_data_movimentacao,
                        pagamento_numero_sequencia: this.arquivo_digital.pagamento_numero_sequencia,
                        pagamento_valor_pago: this.arquivo_digital.pagamento_valor_pago,
                        pagamento_conta_contabil: this.arquivo_digital.pagamento_conta_contabil
                    };
                }
                else if(this.arquivo_digital.tipo == 'prestacao_contas'){
                    this.arquivo_digital = {
                        tipo: this.arquivo_digital.tipo,
                        competencia: this.arquivo_digital.competencia,
                        descricao_resumida: this.arquivo_digital.descricao_resumida,
                        prestacao_contas_nome: this.arquivo_digital.prestacao_contas_nome,
                        prestacao_contas_ano: this.arquivo_digital.prestacao_contas_ano
                    };
                }
                else if(this.arquivo_digital.tipo == 'licitacao'){
                    this.arquivo_digital = {
                        tipo: this.arquivo_digital.tipo,
                        competencia: this.arquivo_digital.competencia,
                        descricao_resumida: this.arquivo_digital.descricao_resumida,
                        licitacao_orgaos: this.arquivo_digital.licitacao_orgaos,
                        licitacao_data: this.arquivo_digital.licitacao_data,
                        licitacao_numero: this.arquivo_digital.licitacao_numero,
                        licitacao_modalidade: this.arquivo_digital.licitacao_modalidade,
                        licitacao_objeto: this.arquivo_digital.licitacao_objeto,
                        licitacao_vencedor: this.arquivo_digital.licitacao_vencedor,
                        licitacao_valor: this.arquivo_digital.licitacao_valor,
                    };
                }
                else if(this.arquivo_digital.tipo == 'setor_pessoal'){
                    this.arquivo_digital = {
                        tipo: this.arquivo_digital.tipo,
                        competencia: this.arquivo_digital.competencia,
                        descricao_resumida: this.arquivo_digital.descricao_resumida,
                        setor_pessoal_nome: this.arquivo_digital.setor_pessoal_nome,
                        setor_pessoal_cpf: this.arquivo_digital.setor_pessoal_cpf,
                        setor_pessoal_cargo: this.arquivo_digital.setor_pessoal_cargo,
                        setor_pessoal_secretaria: this.arquivo_digital.setor_pessoal_secretaria
                    };
                }
                else if(this.arquivo_digital.tipo == 'outros'){
                    this.arquivo_digital = {
                        tipo: this.arquivo_digital.tipo,
                        competencia: this.arquivo_digital.competencia,
                        descricao_resumida: this.arquivo_digital.descricao_resumida
                    };
                }
            },

            //OUTROS METODOS
            onSelectTipo(item) {
                this.tipo = item;
                this.arquivo_digital.tipo = this.tipo.value;
                if(this.arquivo_digital.tipo == 'empenho' || this.arquivo_digital.tipo == 'licitacao'){
                    this.getOrgaos();
                }
                else if(this.arquivo_digital.tipo == 'liquidacao'){
                    this.getEmpenhos();
                }
                else if(this.arquivo_digital.tipo == 'pagamento'){
                    this.getLiquidacoes();
                }
                this.resetFormByTipo();
            },
            onSelectOrgao(item) {
                this.orgao = item;
                this.arquivo_digital.empenho_orgao_orcamentario_id = this.orgao.value;
            },
            onSelectOrgaoLicitacao(itemsParam, lastSelectItemParam) {
                let me = this;
                me.itemsOrgaosLicitacao = itemsParam;
                me.lastSelectItemOrgaoLicitacao = lastSelectItemParam;
                me.arquivo_digital.licitacao_orgaos = [];
                $.each(me.itemsOrgaosLicitacao, function (key, itemOrgao) {
                    me.arquivo_digital.licitacao_orgaos.push(itemOrgao.value);
                });
            },
            resetOrgaosLicitacao() {
                this.itemsOrgaosLicitacao = [];
            },
            onSelectEmpenho(item) {
                this.empenho = item;
                this.arquivo_digital.liquidacao_empenho_numero = this.empenho.value;
            },
            onSelectLiquidacao(item){
                this.liquidacao = item;
                this.arquivo_digital.pagamento_liquidacao_numero_aviso = this.liquidacao.value;
            },
            onSelectModalidade(item){
                this.modalidade_licitacao = item;
                this.arquivo_digital.licitacao_modalidade = this.modalidade_licitacao.value;
            },
            //FIM OUTROS METODOS

            resetForm()
            {
                this.errors = [];                
                this.tipo = {};
                this.empenho = {};
                this.liquidacao = {};
                this.orgao = {};
                this.arquivo_digital = {
                    tipo: '',
                    competencia: this.ano_competencia,
                    descricao_resumida: '',
                    empenho_numero: '',
                    empenho_data_emissao: '',
                    empenho_credor: '',
                    empenho_documento_credor: '',
                    empenho_objeto: '',
                    empenho_orgao_orcamentario_id: null,
                    empenho_fornecedor: '',
                    empenho_classificacao: '',
                    empenho_acao: '',
                    empenho_valor: '',
                    liquidacao_empenho_numero: '',
                    liquidacao_data_vencimento: '',
                    liquidacao_numero_aviso: '',
                    liquidacao_data_emissao: '',
                    liquidacao_documento_fiscal: '',
                    liquidacao_valor: '',
                    pagamento_liquidacao_numero_aviso: '',
                    pagamento_data_movimentacao: '',
                    pagamento_numero_sequencia: '',
                    pagamento_valor_pago: '',
                    pagamento_conta_contabil: '',
                    prestacao_contas_nome: '',
                    prestacao_contas_ano: '',
                    licitacao_orgaos: [],
                    licitacao_data: '',
                    licitacao_numero: '',
                    licitacao_modalidade: '',
                    licitacao_objeto: '',
                    licitacao_vencedor: [''],
                    licitacao_valor: '',
                    setor_pessoal_nome: '',
                    setor_pessoal_cpf: '',
                    setor_pessoal_cargo: '',
                    setor_pessoal_secretaria: ''
                };
            },

            validateData(data){
                var regex = "(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4}";
                var re = new RegExp(regex);
                return (re.test(data));
            },
            validateCpf(strCPF){
                var Soma;
                var Resto;
                var i;
                Soma = 0;
                if (strCPF == "00000000000") {
                    return false;
                }
                for (i=1; i<=9; i++) {
                    Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
                }
                Resto = (Soma * 10) % 11;
                if ((Resto == 10) || (Resto == 11))  {
                    Resto = 0;
                }
                if (Resto != parseInt(strCPF.substring(9, 10)) ) {
                    return false;
                }
                Soma = 0;
                for (i = 1; i <= 10; i++) {
                    Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
                }
                Resto = (Soma * 10) % 11;
                if ((Resto == 10) || (Resto == 11))  {
                    Resto = 0;
                }
                if (Resto != parseInt(strCPF.substring(10, 11) ) ) {
                    return false;
                }
                return true;
            },

            checkFormEmpenho:function() {
                this.errors = [];
                if(this.arquivo_digital.empenho_numero &&
                    this.arquivo_digital.empenho_data_emissao &&
                    this.arquivo_digital.empenho_credor &&
                    this.arquivo_digital.empenho_documento_credor &&
                    this.arquivo_digital.empenho_objeto &&
                    this.arquivo_digital.empenho_orgao_orcamentario_id &&
                    this.arquivo_digital.empenho_fornecedor &&
                    this.arquivo_digital.empenho_classificacao &&
                    this.arquivo_digital.empenho_acao &&
                    this.arquivo_digital.empenho_valor
                ) {
                    if(this.validateData(this.arquivo_digital.empenho_data_emissao) == false){
                        this.errors.push("Informe uma data válida");
                        $("#arquivo_digital_create_modal").scrollTop(0);
                        return false;
                    }
                    return true;
                }
                if(!this.arquivo_digital.empenho_numero) this.errors.push("Informe o número do empenho.");
                if(!this.arquivo_digital.empenho_data_emissao) this.errors.push("Informe a data de emissão.");
                if(!this.arquivo_digital.empenho_credor) this.errors.push("Informe o credor.");
                if(!this.arquivo_digital.empenho_documento_credor) this.errors.push("Informe o CPF/CNPJ do credor.");
                if(!this.arquivo_digital.empenho_objeto) this.errors.push("Informe o objeto do empenho.");
                if(!this.arquivo_digital.empenho_orgao_orcamentario_id) this.errors.push("Selecione o órgão orçamentário.");
                if(!this.arquivo_digital.empenho_fornecedor) this.errors.push("Informe o fornecedor.");
                if(!this.arquivo_digital.empenho_classificacao) this.errors.push("Informe a classificação.");
                if(!this.arquivo_digital.empenho_acao) this.errors.push("Informe a ação.");
                if(!this.arquivo_digital.empenho_valor) this.errors.push("Informe o valor do empenho.");
                $("#arquivo_digital_create_modal").scrollTop(0);
                return false;
            },
            checkFormLiquidacao:function() {
                this.errors = [];
                if(this.arquivo_digital.liquidacao_empenho_numero &&
                    this.arquivo_digital.liquidacao_data_vencimento &&
                    this.arquivo_digital.liquidacao_numero_aviso &&
                    this.arquivo_digital.liquidacao_data_emissao &&
                    this.arquivo_digital.liquidacao_documento_fiscal &&
                    this.arquivo_digital.liquidacao_valor
                ) {
                    if(this.validateData(this.arquivo_digital.liquidacao_data_vencimento) == false){
                        this.errors.push("Informe uma data de vencimento válida");
                        $("#arquivo_digital_create_modal").scrollTop(0);
                        return false;
                    }
                    if(this.validateData(this.arquivo_digital.liquidacao_data_emissao) == false){
                        this.errors.push("Informe uma data de emissão válida");
                        $("#arquivo_digital_create_modal").scrollTop(0);
                        return false;
                    }
                    return true;
                }
                if(!this.arquivo_digital.liquidacao_empenho_numero) this.errors.push("Selecione o empenho da liquidação.");
                if(!this.arquivo_digital.liquidacao_data_vencimento) this.errors.push("Informe a data de vencimento da liquidação.");
                if(!this.arquivo_digital.liquidacao_numero_aviso) this.errors.push("Informe o número de aviso da liquidação.");
                if(!this.arquivo_digital.liquidacao_data_emissao) this.errors.push("Informe a data de emissão da liquidação.");
                if(!this.arquivo_digital.liquidacao_documento_fiscal) this.errors.push("Informe o documento fiscal da liquidação.");
                if(!this.arquivo_digital.liquidacao_valor) this.errors.push("Informe o valor da liquidação.");
                $("#arquivo_digital_create_modal").scrollTop(0);
                return false;
            },
            checkFormPagamento:function(){
                this.errors = [];
                if(this.arquivo_digital.pagamento_liquidacao_numero_aviso &&
                    this.arquivo_digital.pagamento_data_movimentacao &&
                    this.arquivo_digital.pagamento_numero_sequencia &&
                    this.arquivo_digital.pagamento_valor_pago &&
                    this.arquivo_digital.pagamento_conta_contabil
                ) {
                    if(this.validateData(this.arquivo_digital.pagamento_data_movimentacao) == false){
                        this.errors.push("Informe uma data de movimentação válida");
                        $("#arquivo_digital_create_modal").scrollTop(0);
                        return false;
                    }
                    return true;
                }
                if(!this.arquivo_digital.pagamento_liquidacao_numero_aviso) this.errors.push("Selecione a liquidação.");
                if(!this.arquivo_digital.pagamento_data_movimentacao) this.errors.push("Informe a data de movimentação do pagamento.");
                if(!this.arquivo_digital.pagamento_numero_sequencia) this.errors.push("Informe o número de sequência do pagamento.");
                if(!this.arquivo_digital.pagamento_valor_pago) this.errors.push("Informe o valor do pagamento.");
                if(!this.arquivo_digital.pagamento_conta_contabil) this.errors.push("Informe a conta contábil.");
                $("#arquivo_digital_create_modal").scrollTop(0);
                return false;
            },
            checkFormPrestacaoContas:function(){
                this.errors = [];
                if(this.arquivo_digital.prestacao_contas_nome &&
                    this.arquivo_digital.prestacao_contas_ano
                ) {
                    return true;
                }
                if(!this.arquivo_digital.prestacao_contas_nome) this.errors.push("Informe o nome.");
                if(!this.arquivo_digital.prestacao_contas_ano) this.errors.push("Informe o ano.");
                $("#arquivo_digital_create_modal").scrollTop(0);
                return false;
            },
            checkFormLicitacao:function(){
                let me = this;
                this.errors = [];
                if((this.arquivo_digital.licitacao_orgaos.length > 0) &&
                    this.arquivo_digital.licitacao_data &&
                    this.arquivo_digital.licitacao_numero &&
                    this.arquivo_digital.licitacao_modalidade &&
                    this.arquivo_digital.licitacao_objeto &&
                    this.arquivo_digital.licitacao_valor
                ) {
                    if(this.validateData(this.arquivo_digital.licitacao_data) == false){
                        this.errors.push("Informe uma data da licitação válida");
                        $("#arquivo_digital_create_modal").scrollTop(0);
                        return false;
                    }

                    let arrayCheckLicitacaoVencedor = [];
                    $.each(me.arquivo_digital.licitacao_vencedor, function (key, item) {
                        if(item.length > 0){
                            arrayCheckLicitacaoVencedor.push(item);
                        }
                    });
                    if(arrayCheckLicitacaoVencedor.length == 0){
                        this.errors.push("Informe o(s) vencedor(es) da licitação.");
                        return false;
                    }

                    return true;
                }
                if(this.arquivo_digital.licitacao_orgaos.length == 0) this.errors.push("Informe o(s) órgão(s).");
                if(!this.arquivo_digital.licitacao_numero) this.errors.push("Informe o número da licitação.");
                if(!this.arquivo_digital.licitacao_modalidade) this.errors.push("Selecione a modalide da licitação.");
                if(!this.arquivo_digital.licitacao_data) this.errors.push("Informe a data da licitação.");
                if(!this.arquivo_digital.licitacao_valor) this.errors.push("Informe o valor da licitação.");
                if(!this.arquivo_digital.licitacao_objeto) this.errors.push("Informe o objeto da licitação.");
                $("#arquivo_digital_create_modal").scrollTop(0);
                return false;
            },
            checkFormSetorPessoal:function(){
                this.errors = [];
                if(this.arquivo_digital.setor_pessoal_nome &&
                    this.arquivo_digital.setor_pessoal_cpf &&
                    this.arquivo_digital.setor_pessoal_cargo &&
                    this.arquivo_digital.setor_pessoal_secretaria
                ) {
                    if((this.arquivo_digital.setor_pessoal_cpf) &&
                        this.validateCpf(this.arquivo_digital.setor_pessoal_cpf) == false){
                        this.errors.push("Informe um número de CPF válido");
                        return false;
                    }
                    return true;
                }
                if(!this.arquivo_digital.setor_pessoal_nome) this.errors.push("Informe o nome.");
                if(!this.arquivo_digital.setor_pessoal_cpf) this.errors.push("Informe o CPF.");
                if(!this.arquivo_digital.setor_pessoal_cargo) this.errors.push("Informe o cargo.");
                if(!this.arquivo_digital.setor_pessoal_secretaria) this.errors.push("Informe a secretaria.");
                $("#arquivo_digital_create_modal").scrollTop(0);
                return false;
            },
            checkForm:function() {
                this.errors = [];
                if(this.arquivo_digital.tipo &&
                    this.arquivo_digital.competencia &&
                    this.arquivo_digital.descricao_resumida
                ) {
                    if(this.arquivo_digital.tipo == 'empenho'){
                        if(this.checkFormEmpenho() == false){
                            return false;
                        }
                    }
                    else if(this.arquivo_digital.tipo == 'liquidacao'){
                        if(this.checkFormLiquidacao() == false){
                            return false;
                        }
                    }
                    else if(this.arquivo_digital.tipo == 'pagamento'){
                        if(this.checkFormPagamento() == false){
                            return false;
                        }
                    }
                    else if(this.arquivo_digital.tipo == 'prestacao_contas'){
                        if(this.checkFormPrestacaoContas() == false){
                            return false;
                        }
                    }
                    else if(this.arquivo_digital.tipo == 'licitacao'){
                        if(this.checkFormLicitacao() == false){
                            return false;
                        }
                    }
                    else if(this.arquivo_digital.tipo == 'setor_pessoal'){
                        if(this.checkFormSetorPessoal() == false){
                            return false;
                        }
                    }
                    return true;
                }
                if(!this.arquivo_digital.tipo) this.errors.push("Selecione o tipo");
                if(!this.arquivo_digital.competencia) this.errors.push("Informe a competência");
                if(!this.arquivo_digital.descricao_resumida) this.errors.push("Informe a descrição resumida");
                $("#arquivo_digital_create_modal").scrollTop(0);
            },
            createArquivoDigital()
            {
                if(this.checkForm()){                    
                    this.isLoading = true;
                    axios.post('/arquivodigital', this.arquivo_digital).then(response => {
                        this.isLoading = false;
                        $("#arquivo_digital_create_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetArquivosDigital();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            },
            getOrgaos(){
                var urlOrgaos = '/orgao/combo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlOrgaos
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.orgaos = [];
                    $.each(response.data, function (key, item) {
                        me.orgaos.push({
                            'value': item.id,
                            'text': item.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar órgaos.');
                });
            },
            getEmpenhos(){
                var urlEmpenhos = '/arquivodigital/empenhos';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlEmpenhos
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.empenhos = [];
                    $.each(response.data, function (key, item) {
                        me.empenhos.push({
                            'value': item.empenho_numero,
                            'text': "Número: "+item.empenho_numero+". Credor: "+item.empenho_credor+". Valor: "+me.formatMoneyBr(item.empenho_valor)
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar empenhos');
                });
            },
            getLiquidacoes(){
                var urlLiquidacoes = '/arquivodigital/liquidacoes';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlLiquidacoes
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.liquidacoes = [];
                    $.each(response.data, function (key, item) {
                        me.liquidacoes.push({
                            'value': item.liquidacao_numero_aviso,
                            'text': "Liquidação Nº Empenho: "+item.liquidacao_empenho_numero+". Aviso de Liquidação: "+item.liquidacao_numero_aviso+". Valor: "+me.formatMoneyBr(item.liquidacao_valor)
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar liquidacoes');
                });
            },
            formatMoneyBr(valor){
                var formatter = new Intl.NumberFormat('pt-br', {
                  style: "currency",
                  currency: "BRL"
                });
                return formatter.format(valor);
            },
        }
    }
</script>