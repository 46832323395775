<template>
    <div>
        <canvas id="bar-chart-dashboard" style="width: 984px; height: 262px;"></canvas>
    </div>
</template>

<script>
    import { eventBus } from '../../app';
    import Chart from 'chart.js';
    
    export default {

        data(){
            return {
                barChartData: null
            }
        },

        mounted () {
            let me = this;
            eventBus.$on('eventSetDataBarChart', (barChartData) => {
                me.barChartData = barChartData;
                // console.log(JSON.stringify(me.barChartData));
                me.renderizarGrafico();
            });
        },
        
        methods: {
            renderizarGrafico: function () {
                let me = this;
                const ctx = document.getElementById('bar-chart-dashboard');
                new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: me.barChartData.labels,
                        datasets: me.barChartData.dados,
                    },
                    options: {
                        layout: {
                            padding: {
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0
                            }
                        },

                        scales: {
                            responsive: true,
                            maintainAspectRatio: true,
                            yAxes: [
                                {
                                    display: true,
                                    gridLines: {
                                        color: "rgba(0, 0, 0, 0.03)"
                                    }
                                }
                            ],
                            xAxes: [
                                {
                                    display: true,//OMITE/EXIBE LABELS (NOME DOS ORGAOS)
                                    categoryPercentage: 1.0, // here 
                                    barPercentage: 0.80,  // here
                                    gridLines: {
                                        display: false
                                    }
                                }
                            ]
                        },
                        legend: {
                            display: true
                        }
                    }
                });
            }
        }

        /*mounted () {
            // Overwriting base render method with actual data.
            this.renderChart({
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                datasets: [
                    {
                        label: 'Quantidade por Secretaria',
                        backgroundColor: '#4B94BF',
                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
                    }
                ]
            })
        }*/
    }
</script>