<template>
    <div class="modal fade" id="orgao_update_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Atualizar</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Nome:</label>
                                <input type="text" v-model="update_orgao.nome" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Sigla:</label>
                                <input type="text" placeholder="Sigla do Órgão" v-model="update_orgao.sigla" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Resposável:</label>
                                <input type="text" placeholder="Nome do Responsável" v-model="update_orgao.responsavel" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Unidade Gestora:</label>
                                <basic-select :options="unidades_gestoras"
                                        :selected-option="unidade_gestora"
                                        placeholder="Selecione a Unidade Gestora"
                                        @select="onSelectUnidadeGestora">
                                </basic-select>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="updateOrgao">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            BasicSelect
        },


    	data(){
            return {
                currentPagePagination: null,

            	isLoading: false,

                //ARRAYS
            	errors: [],
                unidades_gestoras: [],

                //OBJECTS
                unidade_gestora: {},
                update_orgao: {},
                update_orgao_old: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalUpdateOrgao();
        },

        methods: {
            registryEventOpenModalUpdateOrgao(){
                eventBus.$on('eventOpenModalUpdateOrgao', (updateOrgao, currentPage) => {
                	this.errors = [];
                    this.currentPagePagination = currentPage;
                    $("#orgao_update_modal").modal("show");
                    this.setDataOrgao(updateOrgao);
                    this.getUnidadesGestoras();
                });
            },

            setDataOrgao(orgaoParam){
                this.update_orgao = Object.assign({}, orgaoParam);
                this.update_orgao_old = Object.assign({}, orgaoParam);
                this.unidade_gestora = {
                    value: orgaoParam.unidade_gestora_id,
                    text: orgaoParam.unidade_gestora_nome
                };
            },

            emitEventGetOrgaos(){
                eventBus.$emit('eventGetOrgaos', this.currentPagePagination);
            },

            //OUTROS METODOS
            onSelectUnidadeGestora(item) {
                this.unidade_gestora = item;
                this.update_orgao.unidade_gestora_id = this.unidade_gestora.value;
            },
            //FIM OUTROS METODOS

            checkForm:function(acao) {
                this.errors = [];

                if(this.update_orgao.nome == this.update_orgao_old.nome &&
                    this.update_orgao.sigla == this.update_orgao_old.sigla &&
                    this.update_orgao.responsavel == this.update_orgao_old.responsavel &&
                    this.update_orgao.unidade_gestora_id == this.update_orgao_old.unidade_gestora_id
                ) {
                    this.errors.push("Altere os dados");
                    return false;
                }

                if(this.update_orgao.nome &&
                    this.update_orgao.sigla &&
                    this.update_orgao.responsavel &&
                    this.update_orgao.unidade_gestora_id
                ) {
                    return true;
                }
                
                if(!this.update_orgao.nome) this.errors.push("Informe o nome");
                if(!this.update_orgao.sigla) this.errors.push("Informe a sigla");
                if(!this.update_orgao.responsavel) this.errors.push("Informe o responsável");
                if(!this.update_orgao.unidade_gestora_id) this.errors.push("Selecione a Unidade Gestora");
            },

            updateOrgao()
            {
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.put('/orgao/' + this.update_orgao.id, this.update_orgao)
                    .then(response => {

                        this.isLoading = false;
                        $("#orgao_update_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetOrgaos();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            },

            getUnidadesGestoras(){

                var urlUnidadesGestoras = '/unidadegestora/combo';
                
                this.isLoading = true;
                
                axios({
                    method: 'GET',
                    url: urlUnidadesGestoras
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.unidades_gestoras = [];
                    $.each(response.data, function (key, value) {
                        me.unidades_gestoras.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar unidades gestoras');
                });
            },
        }
    }
</script>