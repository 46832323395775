<template>
    <div class="container">
        <div>
            <unidade-gestora-search/>
            <unidade-gestora-create/>
            <unidade-gestora-update/>
            <unidade-gestora-delete/>
        </div>
        <div class="row remove-padding-left-right-grid">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card card-border-top">
                    <div class="card-body">
                        <h4 class="card-title">Unidades Gestoras</h4>
                        <p class="card-description text-right"> 
                            <button @click="initSearch()" type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-filter"></i>
                                {{ titleButtonFilter }}
                            </button>
                            <button @click="initAdd()"  type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-plus-circle"></i>Nova
                            </button>
                        </p>
                        <div class="table-responsive" style="min-height: 60vh;">
                            <table class="table table-hover">
                                <thead>
                                    <tr class="d-flex">
                                        <!-- <th class="col-1 text-center"> # </th> -->
                                        <th :style="columns.one"> Nome/ CNPJ </th>    
                                        <th :style="columns.two"> Responsável </th>
                                        <th :style="columns.three"> Endereço/ Município </th>
                                        <th :style="columns.four" class="text-center"> Ações </th>
                                    </tr>
                                </thead>
                                <tbody style="font-size: 20px;">
                                    <tr class="d-flex" v-for="(item, index) in unidades_gestoras">
                                        <!-- <td class="text-center"> {{ index + 1 }} </td> -->
                                        <td :style="columns.one"> 
                                            {{ item.nome }} / <br>
                                            {{ item.cnpj | formatCnpj }} 
                                        </td>
                                        <td :style="columns.two" class="vertical-center-middle"> 
                                            {{ item.responsavel }}
                                        </td>
                                        <td :style="columns.three"> 
                                            {{ item.endereco }} / <br/>
                                            {{ item.organizacao_nome }} 
                                        </td>
                                        <td :style="columns.four" class="text-center">
                                            <div class="dropdown">
                                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-outline-primary">
                                                    <i class="mdi mdi-format-list-bulleted"></i>
                                                </button>
                                                
                                                <div class="dropdown-menu" 
                                                    x-placement="top-start" >
                                                    <a @click="initUpdate(item)" class="dropdown-item">
                                                        Editar
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initDelete(item)" class="dropdown-item">
                                                        Deletar
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="unidades_gestoras.length == 0">
                                        <td colspan="6" class="text-center">
                                            Nenhum item encontrado.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="mt-3">
                            <pagination :paginationData="pagination" @navigate="goToPage"></pagination>
                        </div>

                    </div>
                </div>
            </div>
        </div>
      
    </div>
</template>

<style type="text/css" scoped>
    .card-title {
        margin-bottom: 0px !important;
        font-size: 18px !important;
        padding-top: 16px !important;
        padding-left: 10px !important;
    }
    .card-description {
        margin-bottom: 8px !important;
    }
    .card-border-top {
        border-top: 3px solid #149AF9 !important;
    }
    .card-body {
        padding: 0px 10px !important;
    }
    .btn {
        padding: 7px 7px !important;
    }
    .vertical-center-middle {
        white-space:normal;
        text-align: justify;
        display: flex;
        align-items: center;
    }
    .text-center {
        justify-content: center
    }
    table tbody tr td{
        font-size: 13px;
    }
</style>

<script>

    import moment from 'moment';
    import { eventBus } from '../../app';
    import Pagination from '../paginacao/Pagination.vue';

    export default {

        components: {
            Pagination
        },

        data(){
            return {
                columns: {
                    one: 'width: 40%;',
                    two: 'width: 28%;',
                    three: 'width: 26%;',
                    four: 'width: 5%;'
                },
                titleButtonFilter: 'Filtrar',
                
                isLoading: false,
                //ARRAYS
                unidades_gestoras: [],

                //SEARCH DATA
                filtros: [],

                //PAGINATION
                pagination: {}
            }
        },

        mounted() {
            this.registryEvents();
            this.getUnidadesGestoras();
        },

        filters: {
            formatCnpj: function(cnpj){
                if(cnpj){
                    cnpj =  cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-$5");
                    return cnpj;
                }
                else {
                    return "-";
                }
            }
        },

        methods: {
            //REGISTRY EVENTS
            registryEvents(){
                this.registryEventGetUnidadesGestoras();
                this.registryEventGetUnidadesGestorasFilter();
            },
            registryEventGetUnidadesGestoras(){
                eventBus.$on('eventGetUnidadesGestoras', (page) => {
                    this.filtros = [];
                    this.setTitleFilterButton();
                    this.getUnidadesGestoras(page);
                });
            },
            registryEventGetUnidadesGestorasFilter(){
                eventBus.$on('eventGetUnidadesGestorasFilter', (filtrosEvent) => {
                    this.filtros = filtrosEvent;
                    this.setTitleFilterButton();
                    this.getUnidadesGestoras();
                });
            },
            //FIM REGISTRY EVENTS
            emitEventOpenModalSearch(){
                eventBus.$emit('eventOpenModalSearchUnidadeGestora', this.filtros);
            },
            emitEventOpenModalCreate(){
                eventBus.$emit('eventOpenModalCreateUnidadeGestora');
            },
            emitEventOpenModalUpdate(item){
                eventBus.$emit('eventOpenModalUpdateUnidadeGestora', 
                    item,
                    this.pagination.current_page
                );
            },
            emitEventOpenModalDelete(item){
                eventBus.$emit('eventOpenModalDeleteUnidadeGestora', 
                    item,
                    this.pagination.current_page
                );
            },

            initSearch(){
                this.emitEventOpenModalSearch();
            },
            initAdd()
            {
                this.emitEventOpenModalCreate();
            }, 
            initUpdate(item)
            {
                this.emitEventOpenModalUpdate(item);
            },
            initDelete(item)
            {
                this.emitEventOpenModalDelete(item);
            },

            getUnidadesGestoras(page)
            {
                var urlUnidadesGestoras = '/unidadegestora/listdata';

                this.isLoading = true;

                axios({
                    method: 'GET',
                    url: urlUnidadesGestoras,
                    params: {
                        filters: this.filtros,
                        page: page //UTILIZADO NA PAGINAÇÃO
                    }
                })
                .then(response => {
                    this.unidades_gestoras = response.data.unidades_gestoras.data;
                    this.pagination = response.data.pagination;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao atualizar a pagina');
                });
            },
            goToPage: function (page)
            {
                this.pagination.current_page = page;
                this.getUnidadesGestoras(page);
            },
            setTitleFilterButton(){
                if(this.filtros.length > 0){
                    var textFilter = this.filtros.length > 1 ? 'Filtros' : 'Filtro';
                    this.titleButtonFilter = '('+this.filtros.length+') '+textFilter;
                }
                else {
                    this.titleButtonFilter = 'Filtrar'; 
                }
            },
        }
    }
</script>
