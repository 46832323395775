<template>
    <div class="modal fade" id="unidade_gestora_search_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Filtrar</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">

                            <div class="form-group">
                                <label class="col-form-label">Nome:</label>
                                <input type="text" placeholder="Nome da Unidade Gestora" v-model="nome_search" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">CNPJ:</label>
                                <the-mask 
                                    class="form-control"
                                    placeholder="12.345.678/0001-99"
                                    :mask="['##.###.###/####-##']"
                                    :masked="configDateMask.maskedValue"
                                    v-model="cnpj_search"/>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Município: *</label>
                                <basic-select :options="municipios"
                                        :selected-option="municipio"
                                        placeholder="Selecione o Município"
                                        @select="onSelectMunicipio">
                                </basic-select>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                    	<div 
	                        v-if="(this.nome_search.length > 0 || 
	                        this.cnpj_search.length > 0 ||
	                        this.municipio_id_search > 0 )">
	                        <button type="button" @click.prevent.stop="limparFiltros" class="btn btn-danger pull-left">
	                            Limpar Filtros
	                        </button>
	                    </div>
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="filterUnidadeGestora">
                            Filtrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { TheMask } from 'vue-the-mask';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            TheMask,
            BasicSelect
        },


    	data(){
            return {
                configDateMask: {
                    maskedValue: false
                },
            	isLoading: false,

                //ARRAYS
            	filtros: [],
                municipios: [],

                //OBJECTS
                municipio: {},
                nome_search: '',
                cnpj_search: '',
                municipio_id_search: null
            }
        },

        mounted(){
        	this.registryEventOpenModalSearchUnidadeGestora();
        },

        methods: {
            registryEventOpenModalSearchUnidadeGestora(){
                eventBus.$on('eventOpenModalSearchUnidadeGestora', (filtrosParam) => {
                	this.filtros = filtrosParam;
                    this.checkClearFiltersForm();
                    this.getOrganizacoes();
                    $("#unidade_gestora_search_modal").modal("show");
                });
            },

            //EMIT EVENTS
            emitEventGetUnidadesGestorasFilter(){
                eventBus.$emit('eventGetUnidadesGestorasFilter', 
                    this.filtros
                );
            },
            //FIM EMIT EVENTS

            checkClearFiltersForm(){
                if(this.filtros.length == 0){
                    this.clearFiltros();
                }
            },

            clearFiltros(){
                this.filtros = [];
                this.municipio = {};
                this.nome_search = '';
                this.cnpj_search = '';
                this.municipio_id_search = null;
            },

            limparFiltros(){
                this.clearFiltros();
                this.filterUnidadeGestora();
            },

            //OUTROS METODOS
            onSelectMunicipio(item) {
                this.municipio = item;
                this.municipio_id_search = this.municipio.value;
            },
            //FIM OUTROS METODOS

            filterUnidadeGestora(){
                this.filtros = [];
                
                if (this.nome_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'nome',
                        'value': this.nome_search
                    });
                }

                if (this.cnpj_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'cnpj',
                        'value': this.cnpj_search
                    });
                }

                if (this.municipio_id_search > 0) {
                    this.filtros.push({ 
                        'property': 'organizacao_id',
                        'value': this.municipio_id_search
                    });
                }

                $("#unidade_gestora_search_modal").modal("hide");
                this.emitEventGetUnidadesGestorasFilter();
            },
            getOrganizacoes(){

                var urlMunicipios = '/organizacao/combo';
                
                this.isLoading = true;
                
                axios({
                    method: 'GET',
                    url: urlMunicipios
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.municipios = [];
                    $.each(response.data, function (key, value) {
                        me.municipios.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar municípios');
                });
            },
        }
    }
</script>