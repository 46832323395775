<template>
    <div class="modal fade" id="demanda_add_anexo_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Novo(a)</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>
                        </div>

                        <div>
                            <div class="form-group">
                                <label class="col-form-label">Nome:</label>
                                <input type="text" placeholder="Nome" v-model="anexo_nome" class="form-control">
                            </div>
                            <div class="form-group">
                                <label>Anexo:</label>
                                <input 
                                    class="form-control-file" 
                                    id="file" 
                                    type="file" 
                                    ref="file" 
                                    name="file"
                                    v-on:change="onFileChange">
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="salvarAnexo">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../../app';
    import Dot from '../../loadder/Dot.vue';

    export default {

    	components: {
            Dot,
        },

    	data(){
            return {
            	isLoading: false,

                
                errors: [],
                //files: [],

                arquivo_pdf: '',
                formData: {},
                attachment: '',
                anexo_nome: '',

                demanda: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalAddAnexoDemanda();
        },

        methods: {
            registryEventOpenModalAddAnexoDemanda(){
                eventBus.$on('eventOpenModalAddAnexoDemanda', (demandaParam) => {
                	this.errors = [];
                    this.resetForm();
                    this.demanda = demandaParam;
                    $("#demanda_add_anexo_modal").modal("show");
                });
            },

            emitEventGetAnexosDemanda(){
                eventBus.$emit('eventGetAnexosDemanda');
            },

            validationSizeFile(file){
                this.errors = [];
                var fileSize = (file.size/1024/1024);
                if (fileSize > 2) {
                    this.errors.push('Arquivo muito GRANDE. Selecione um arquivo de no máximo 2MB (MegaBytes) de tamanho.');
                    return false;
                }
                return true;
            },

            validationTypeFile(file){
                this.errors = [];
                var fileType = file.type;
                var typesAceptsImages = ["application/pdf"];
                if (typesAceptsImages.includes(fileType) == false) {
                    this.errors.push('Tipo de arquivo não permitido. Selecione um arquivo do tipo PDF.');
                    return false;
                }
                return true;
            },

            onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createFile(files[0]);
            },
            createFile(file) {
                let reader = new FileReader();
                let vm = this;
                reader.onload = (e) => {
                    vm.arquivo_pdf = e.target.result;
                };
                reader.readAsDataURL(file);
                this.errors = [];
            },

            //OUTROS METODOS
            
            //FIM OUTROS METODOS

            resetForm()
            {
                this.errors = [];
                this.demanda = {};
                this.attachment = '';
                this.arquivo_pdf = '';
                this.formData = {};
                $("#file").val('');
                this.anexo_nome = '';
            },

            salvarAnexo(){
                this.errors = [];
                if(!this.anexo_nome){
                    this.errors.push('Informe um nome para o arquivo.');
                    return false;
                }
                if (this.$refs.file.files.length == 0) {
                    this.errors.push('Selecione um arquivo');
                    return false;
                }

                this.attachment = this.$refs.file.files[0];

                if (this.validationTypeFile(this.attachment) == true &&
                    this.validationSizeFile(this.attachment) == true) {

                    this.formData = new FormData();
                    this.formData.append('nome', this.anexo_nome);
                    this.formData.append('file', this.attachment);
                    this.formData.append('demanda_id', this.demanda.id);

                    this.isLoading = true;

                    let baseRUL = window.location.origin+'/demanda/anexos/upload';
                    axios.post(baseRUL, this.formData, 
                        {headers: {'Content-Type': 'multipart/form-data'}}
                    )
                    .then(response => {
                        this.isLoading = false;
                        toastr.success(response.data.message);
                        
                        $("#demanda_add_anexo_modal").modal("hide");
                        this.emitEventGetAnexosDemanda();
                    })
                    .catch(error => {
                        let me = this;
                        me.isLoading = false;
                        let erroObject = error.response.data;
                        me.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            let mensagem = 'Falha ao enviar arquivo!';
                            let erroObject = error.response.data;
                            if(erroObject.message){
                                mensagem = erroObject.message;
                            }
                            toastr.error(mensagem);
                        }
                    });
                }
            }
        }
    }
</script>