<template>
    <div class="modal fade" id="demanda_search_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Filtrar</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">

                            <div class="alert alert-danger" v-if="this.numero_sequencia != null">
                                <ul>
                                    <li>
                                        O filtro número de sequência: <b>{{ this.numero_sequencia }}</b> está definido como padrão, clique em <b>"LIMPAR FILTROS"</b> para habilitar os demais filtros.
                                    </li>
                                </ul>
                            </div>

                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="row form-group">
                                <div class="col-lg-3">
                                    <label class="col-form-label" for="title">Competência:</label>
                                    <input 
                                        class="form-control" 
                                        type="number" 
                                        placeholder="Não Informado" 
                                        v-model="ano_search" disabled>
                                </div>
                                <div class="col-lg-4">
                                    <label class="col-form-label" for="title">Data Prazo:</label>
                                    <the-mask 
                                        class="form-control"
                                        placeholder="01/01/2021"
                                        :mask="['##/##/####']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="data_prazo_search"
                                        :disabled="this.numero_sequencia != null"
                                    />
                                </div>
                                <div class="col-lg-5">
                                    <label class="col-form-label" for="title">Mês de Referência:</label>
                                    <basic-select :options="meses"
                                        :selected-option="mes_search"
                                        placeholder="Selecione o mês"
                                        @select="onSelectMesSearch"
                                        :isDisabled="this.numero_sequencia != null"
                                        >
                                    </basic-select>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-lg-6">
                                    <label class="col-form-label" for="title">Nº Sequência:</label>
                                    <input 
                                        class="form-control" 
                                        type="number" 
                                        placeholder="Nº Sequência" 
                                        v-model="numero_sequencia_search" 
                                        :disabled="this.numero_sequencia != null">
                                </div>
                                <div class="col-lg-6">
                                    <label class="col-form-label" for="title">Obrigação Legal:</label>
                                    <basic-select :options="all_obrigacao_legal_sim_nao"
                                        :selected-option="obrigacao_legal_sim_nao"
                                        placeholder="Selecione a opçao"
                                        @select="onSelectObrigacao"
                                        :isDisabled="this.numero_sequencia != null"
                                        >
                                    </basic-select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Órgão:</label>
                                <basic-select :options="orgaos"
                                        :selected-option="orgao"
                                        placeholder="Selecione o Órgão"
                                        @select="onSelectOrgao"
                                        :isDisabled="this.numero_sequencia != null"
                                        >
                                </basic-select>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Unidade:</label>
                                <basic-select :options="unidades"
                                        :selected-option="unidade"
                                        placeholder="Selecione a Unidade"
                                        @select="onSelectUnidade"
                                        :isDisabled="this.numero_sequencia != null"
                                        >
                                </basic-select>
                            </div>

                            <div class="row form-group">
                                <div class="col-lg-6">
                                    <label class="col-form-label" for="title">Setor Origem:</label>
                                    <basic-select :options="setores_origem"
                                            :selected-option="setor_origem"
                                            placeholder="Selecione o setor de origem"
                                            @select="onSelectSetorOrigem"
                                            :isDisabled="this.numero_sequencia != null"
                                            >
                                    </basic-select>
                                </div>
                                <div class="col-lg-6">
                                    <label class="col-form-label" for="title">Setor Destino:</label>
                                    <basic-select :options="setores_destino"
                                            :selected-option="setor_destino"
                                            placeholder="Selecione o setor de destino"
                                            @select="onSelectSetorDestino"
                                            :isDisabled="this.numero_sequencia != null"
                                            >
                                    </basic-select>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-lg-8">
                                    <label class="col-form-label" for="title">Tipo:</label>
                                    <basic-select :options="tipos"
                                            :selected-option="tipo"
                                            placeholder="Selecione o tipo"
                                            @select="onSelectTipo"
                                            :isDisabled="this.numero_sequencia != null"
                                            >
                                    </basic-select>
                                </div>
                                <div class="col-lg-4">
                                    <label class="col-form-label" for="title">Status:</label>
                                    <basic-select :options="all_status"
                                            :selected-option="status"
                                            placeholder="Selecione..."
                                            @select="onSelectStatus"
                                            :isDisabled="this.numero_sequencia != null"
                                            >
                                    </basic-select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Objetivo:</label>
                                <textarea 
                                    class="form-control" 
                                    rows="4"
                                    v-model="objetivo_search"
                                    placeholder="Objetivo..."
                                    :disabled="this.numero_sequencia != null"
                                    ></textarea>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                    	<div 
	                        v-if="(this.numero_sequencia_search > 0 ||
                            this.mes_search.value > 0 ||
                            (this.obrigacao_legal_sim_nao.value != null) ||
                            this.orgao_id_search > 0 || 
                            this.unidade_id_search > 0 || 
                            this.setor_origem_id_search > 0 || 
                            this.setor_destino_id_search > 0 || 
	                        this.tipo_search.length > 0 ||
                            this.objetivo_search.length > 0 ||
	                        this.data_prazo_search.length > 0 ||
                            this.status_search.length > 0)">
	                        <button type="button" @click.prevent.stop="limparFiltros" class="btn btn-danger pull-left">
	                            Limpar Filtros
	                        </button>
	                    </div>
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="filterDemanda">
                            Filtrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { TheMask } from 'vue-the-mask';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            TheMask,
            BasicSelect
        },


    	data(){
            return {
                configDateMask: {
                    maskedValue: true
                },
            	isLoading: false,

                //ARRAYS
            	filtros: [],
                errors: [],
                orgaos: [],
                unidades: [],
                setores_origem: [],
                setores_destino: [],
                tipos: [],
                all_status: [
                    {
                        value: 'criado',
                        text: 'Criado'
                    },
                    {
                        value: 'recebido',
                        text: 'Recebido'
                    },
                    {
                        value: 'concluido',
                        text: 'Concluído'
                    },
                    {
                        value: 'com_pendencia',
                        text: 'Pendência'
                    },
                    {
                        value: 'atestado',
                        text: 'Atestado'
                    },
                    {
                        value: 'em_atraso',
                        text: 'Em Atraso'
                    }
                ],
                all_obrigacao_legal_sim_nao: [
                    {
                        'value': null,
                        'text': 'Todos'
                    },
                    {
                        'value': true,
                        'text': 'Sim'
                    },
                    {
                        'value': false,
                        'text': 'Não'
                    },
                ],
                meses: [
                    {
                        'value': '0',
                        'text': 'Todos'
                    },
                    {
                        'value': '01',
                        'text': 'Janeiro'
                    },
                    {
                        'value': '02',
                        'text': 'Fevereiro'
                    },
                    {
                        'value': '03',
                        'text': 'Março'
                    },
                    {
                        'value': '04',
                        'text': 'Abril'
                    },
                    {
                        'value': '05',
                        'text': 'Maio'
                    },
                    {
                        'value': '06',
                        'text': 'Junho'
                    },
                    {
                        'value': '07',
                        'text': 'Julho'
                    },
                    {
                        'value': '08',
                        'text': 'Agosto'
                    },
                    {
                        'value': '09',
                        'text': 'Setembro'
                    },
                    {
                        'value': '10',
                        'text': 'Outubro'
                    },
                    {
                        'value': '11',
                        'text': 'Novembro'
                    },
                    {
                        'value': '12',
                        'text': 'Dezembro'
                    }
                ],

                //OBJECTS
                mes_search: {
                    'value': '0',
                    'text': 'Todos'
                },
                obrigacao_legal_sim_nao: {
                    'value': null,
                    'text': 'Todos'
                },
                orgao: {},
                unidade: {},
                setor_origem: {},
                setor_destino: {},
                tipo: {},
                status: {},
                numero_sequencia_search: null,
                ano_search: null,
                orgao_id_search: null,
                unidade_id_search: null,
                setor_origem_id_search: null,
                setor_destino_id_search: null,
                tipo_search: '',
                objetivo_search: '',
                data_prazo_search: '',
                status_search: '',

                ano_competencia: null,
                numero_sequencia: null
            }
        },

        mounted(){
        	this.registryEventOpenModalSearchDemanda();
        },

        methods: {
            registryEventOpenModalSearchDemanda(){
                eventBus.$on('eventOpenModalSearchDemanda', (
                    filtrosParam, anoCompetencia, numeroSequencia
                ) => {
                	this.filtros = filtrosParam;
                    this.ano_competencia = anoCompetencia;
                    this.numero_sequencia = numeroSequencia;
                    this.checkClearFiltersForm();
                    this.getOrgaos();
                    this.getUnidades();
                    this.getSetoresOrigem();
                    this.getSetoresDestino();
                    this.getTiposDemanda();
                    $("#demanda_search_modal").modal("show");
                });
            },

            //EMIT EVENTS
            emitEventGetDemandasFilter(){
                eventBus.$emit('eventGetDemandasFilter', 
                    this.filtros
                );
            },
            //FIM EMIT EVENTS

            checkClearFiltersForm(){
                if(this.filtros.length <= 1 || (this.ano_competencia && this.numero_sequencia)){
                    this.clearFiltros();
                }
            },

            clearFiltros(){
                this.filtros = [];
                this.mes_search = {
                    'value': '0',
                    'text': 'Todos'
                };
                this.obrigacao_legal_sim_nao = {
                    'value': null,
                    'text': 'Todos'
                };
                this.orgao = {};
                this.unidade = {};
                this.setor_origem = {};
                this.setor_destino = {};
                this.tipo = {};
                this.status = {};
                this.ano_search = this.ano_competencia;
                this.numero_sequencia_search = this.numero_sequencia;
                this.orgao_id_search = null;
                this.unidade_id_search = null;
                this.setor_origem_id_search = null;
                this.setor_destino_id_search = null;
                this.objetivo_search = '';
                this.tipo_search = '';
                this.data_prazo_search = '';
                this.status_search = '';
            },

            limparFiltros(){
                this.clearFiltros();
                // this.filterDemanda();
                let urlDestiny = window.location.origin+'/demanda/competencia/'+this.ano_competencia;
                window.location.href = urlDestiny;
            },

            //OUTROS METODOS
            onSelectMesSearch(item){
                this.mes_search = item;
            },
            onSelectObrigacao(item){
                this.obrigacao_legal_sim_nao = item;
            },
            onSelectOrgao(item) {
                this.orgao = item;
                this.orgao_id_search = this.orgao.value;
            },
            onSelectUnidade(item) {
                this.unidade = item;
                this.unidade_id_search = this.unidade.value;
            },
            onSelectSetorOrigem(item) {
                this.setor_origem = item;
                this.setor_origem_id_search = item.value;
            },
            onSelectSetorDestino(item) {
                this.setor_destino = item;
                this.setor_destino_id_search = item.value;
            },
            onSelectTipo(item) {
                this.tipo = item;
                this.tipo_search = this.tipo.value;
            },
            onSelectStatus(item){
                this.status = item;
                this.status_search = this.status.value;
            },
            //FIM OUTROS METODOS

            filterDemanda(){
                this.errors = [];
                this.filtros = [];
                
                if (!this.ano_search) {
                    this.errors.push("Ano de competência não informado.");
                    return false;
                }

                if (this.ano_search && this.ano_search > 0) {
                    var anoLocal = this.ano_search;
                    var anoLocalString = anoLocal.toString();
                    
                    if (anoLocalString.length < 4) {
                        this.errors.push("Informe um ano de cadastro da demanda válido.");
                        return false;
                    }

                    this.filtros.push({
                        'property': 'competencia',
                        'value': this.ano_search
                    });
                }

                if (this.numero_sequencia_search > 0) {
                    this.filtros.push({ 
                        'property': 'numero_sequencia',
                        'value': this.numero_sequencia_search
                    });
                }

                if (this.orgao_id_search > 0) {
                    this.filtros.push({ 
                        'property': 'orgao_id',
                        'value': this.orgao_id_search
                    });
                }

                if (this.unidade_id_search > 0) {
                    this.filtros.push({ 
                        'property': 'unidade_id',
                        'value': this.unidade_id_search
                    });
                }

                if (this.setor_origem_id_search > 0) {
                    this.filtros.push({ 
                        'property': 'setor_origem_id',
                        'value': this.setor_origem_id_search
                    });
                }

                if (this.setor_destino_id_search > 0) {
                    this.filtros.push({ 
                        'property': 'setor_destino_id',
                        'value': this.setor_destino_id_search
                    });
                }

                if (this.tipo_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'tipo',
                        'value': this.tipo_search
                    });
                }

                if (this.objetivo_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'objetivo',
                        'value': this.objetivo_search
                    });
                }

                if (this.data_prazo_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'data_prazo',
                        'value': this.data_prazo_search
                    });
                }

                if (this.status_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'status',
                        'value': this.status_search
                    });
                }

                if (this.mes_search.value && this.mes_search.value > 0) {
                    this.filtros.push({ 
                        'property': 'data_referencia',
                        'value': this.ano_competencia+'-'+this.mes_search.value+'-01'
                    });
                }

                if (this.obrigacao_legal_sim_nao.value != null) {
                    this.filtros.push({ 
                        'property': 'obrigacao_legal',
                        'value': this.obrigacao_legal_sim_nao.value
                    });
                }

                $("#demanda_search_modal").modal("hide");
                this.emitEventGetDemandasFilter();
            },
            getOrgaos(){

                var urlOrgaos = '/orgao/combo';
                
                this.isLoading = true;
                
                axios({
                    method: 'GET',
                    url: urlOrgaos
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.orgaos = [];
                    $.each(response.data, function (key, value) {
                        me.orgaos.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar órgãos');
                });
            },
            getUnidades(){

                var urlUnidades = '/unidade/combo';
                
                this.isLoading = true;
                
                axios({
                    method: 'GET',
                    url: urlUnidades
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.unidades = [];
                    $.each(response.data, function (key, value) {
                        me.unidades.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar unidades');
                });
            },
            getSetoresOrigem(){
                var urlSetores = '/setor/combo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlSetores,
                    params: {
                        check_setor_user: true
                    }
                })
                .then(response => {
                    let me = this;
                    me.isLoading = false;
                    me.setores_origem = [];
                    $.each(response.data, function (key, value) {
                        me.setores_origem.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar setores');
                });
            },
            getSetoresDestino(){
                var urlSetores = '/setor/combo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlSetores,
                    // params: {
                    //     check_user: checkUserParam
                    // }
                })
                .then(response => {
                    let me = this;
                    me.isLoading = false;
                    me.setores_destino = [];
                    $.each(response.data, function (key, value) {
                        me.setores_destino.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar setores');
                });
            },
            getTiposDemanda(){
                var urlTiposDemanda = '/demanda/tipo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlTiposDemanda
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.tipos = [];
                    $.each(response.data, function (key, item) {
                        me.tipos.push({
                            'value': item.value,
                            'text': item.text
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar tipos de demanda');
                });
            },
        }
    }
</script>