<template>
    <div class="modal fade" id="organizacao_delete_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Deletar</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            Deseja realmente DELETAR o(a) <b>{{ delete_organizacao.nome }}</b>?
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-danger"
                            @click.prevent.stop="deleteOrganizacao">
                            Deletar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';

    export default {

    	components: {
            Dot
        },


    	data(){
            return {
                currentPagePagination: null,

            	isLoading: false,

                //ARRAYS
            	errors: [],

                //OBJECTS
                delete_organizacao: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalDeleteOrganizacao();
        },

        methods: {
            registryEventOpenModalDeleteOrganizacao(){
                eventBus.$on('eventOpenModalDeleteOrganizacao', (deleteOrganizacao, currentPage) => {
                	this.errors = [];
                    this.delete_organizacao = Object.assign({}, deleteOrganizacao);
                    this.currentPagePagination = currentPage;
                    $("#organizacao_delete_modal").modal("show");
                });
            },

            emitEventGetOrganizacoes(){
                eventBus.$emit('eventGetOrganizacoes', this.currentPagePagination);
            },

            deleteOrganizacao()
            {
                this.isLoading = true;
                axios.delete(
                    '/organizacao/' + this.delete_organizacao.id
                )
                .then(response => {
                    this.isLoading = false;
                    $("#organizacao_delete_modal").modal("hide");
                    toastr.success(response.data.message);
                    this.emitEventGetOrganizacoes();
                })
                .catch(error => {
                    this.isLoading = false;
                    let erroObject = error.response.data;
                    if(erroObject.message)
                    {
                        toastr.error(erroObject.message);
                    }
                });
            }

            
            
        }
    }
</script>