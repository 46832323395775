<template>
    <div class="modal fade" id="demanda_anexos_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Anexos</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">

                            <div>
                                <button 
                                    @click.prevent.stop="initOpenModalAddAnexo()" 
                                    class="btn btn-success">
                                    <span class="glyphicon glyphicon-plus"></span>
                                    Adicionar Anexo
                                </button>
                            </div>

                            <br/>

                            <div class="panel panel-default">
                                <div class="panel-body">
                                    <div class="table-responsive" style="min-height: 60vh;">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr class="d-flex">
                                                    <th :style="columns.one"> Nome</th>
                                                    <th :style="columns.two" class="text-center"> Ações </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="d-flex" v-for="(item, index) in anexos_demanda">
                                                    <td :style="columns.one" class="vertical-center-middle">
                                                        {{ item.nome }}
                                                    </td>
                                                    <td :style="columns.two" class="vertical-center-middle text-center"> 
                                                        <div class="dropdown">
                                                            <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-outline-primary">
                                                                <i class="mdi mdi-format-list-bulleted"></i>
                                                            </button>
                                                            
                                                            <div class="dropdown-menu" 
                                                                x-placement="top-start" >
                                                                <a @click="openAnexoPdf(item.id)" class="dropdown-item">
                                                                    Abrir
                                                                </a>
                                                                <div class="dropdown-divider"></div>
                                                                <a @click="initDeleteAnexo(item)" class="dropdown-item">
                                                                    Remover
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr v-if="anexos_demanda.length == 0">
                                                    <td colspan="2" class="text-center">
                                                        Nenhum item encontrado.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .modal-dialog {
        min-width: 700px;
    }
</style> 

<script>

    import { eventBus } from '../../../app';
    import Dot from '../../loadder/Dot.vue';

    export default {

        components: {
            Dot,
        },

        data(){
            return {
                isLoading: false,
                columns: {
                    one: 'width: 85%;',
                    two: 'width: 15%;'
                },

                //ARRAYS
                anexos_demanda: [],
                tipos: [],

                //OBJECTS
                demanda: {}
            }
        },

        mounted(){
            this.registryEventOpenModalAnexosDemanda();
            this.registryEventGetAnexosDemanda();
        },

        methods: {
            registryEventOpenModalAnexosDemanda(){
                eventBus.$on('eventOpenModalAnexosDemanda', (demandaParam) => {
                    this.errors = [];
                    this.demanda = demandaParam;
                    this.getAnexosDemanda();
                    $("#demanda_anexos_modal").modal("show");
                });
            },

            registryEventGetAnexosDemanda(){
                eventBus.$on('eventGetAnexosDemanda', () => {
                    this.getAnexosDemanda();
                });
            },

            initOpenModalAddAnexo(){
                eventBus.$emit('eventOpenModalAddAnexoDemanda', this.demanda);
            },

            initDeleteAnexo(anexo)
            {
                eventBus.$emit('eventOpenModalDeleteAnexoDemanda', anexo);
            },

            openAnexoPdf(anexoId){
                var urlDemandaPdf = '/demanda/anexos/pdf?anexo_id='+anexoId;

                this.isLoading = true;

                var child = window.open(urlDemandaPdf); 
                child.focus(); 

                this.isLoading = false;
            },

            getAnexosDemanda(){
                var urlAnexosDemanda = '/demanda/anexos';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlAnexosDemanda,
                    params: {
                        demanda_id: this.demanda.id
                    }
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.anexos_demanda = response.data.anexos_demanda;
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar anexos da demanda');
                });
            },
        }
    }
</script>