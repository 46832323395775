<template>
    <div class="container">

        <div>
            <usuario-update-password/>
            <usuario-update-dados/>
        </div>

        <div class="row remove-padding-left-right-grid">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card card-border-top">
                    <div class="card-body">
                        <h4 class="card-title">Perfil</h4>
                        <p class="card-description text-right"> 
                            <button @click="initUpdatePassword()"  type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-lock"></i>Alterar Senha
                            </button>
                            <button @click="initUpdate()"  type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-lead-pencil"></i>Alterar Dados
                            </button>
                        </p>
                        <div class="table-responsive" style="min-height: 30vh;">
                            <table class="table table-borderless w-100 mt-4">
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Nome :</strong> {{ usuario.name }}
                                        </td>
                                        <td>
                                            <strong>E-mail :</strong> {{ usuario.email }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Administrador :</strong> 
                                            {{ (usuario.administrador == 1) ? 'Sim' : 'Não' }}
                                        </td>
                                        <td>
                                            <strong>Data Cadastro :</strong>
                                            {{ usuario.created_at | formatDataTimeBr }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td v-if="usuario.setor_nome">
                                            <strong>Setor :</strong> {{ usuario.setor_nome }}
                                        </td>
                                        <td v-if="usuario.orgao_nome">
                                            <strong>Órgão :</strong> {{ usuario.orgao_nome }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                </div>


            </div>
        </div>
    </div>
</template>

<style type="text/css" scoped>
    .card-title {
        margin-bottom: 0px !important;
        font-size: 18px !important;
        padding-top: 16px !important;
        padding-left: 10px !important;
    }
    .card-description {
        margin-bottom: 8px !important;
    }
    .card-border-top {
        border-top: 3px solid #149AF9 !important;
    }
    .card-body {
        padding: 0px 10px !important;
    }
</style>

<script>

    import moment from 'moment';
    import { eventBus } from '../../app';
    
    // import Pagination from '../paginacao/Pagination.vue';

    // import Loading from 'vue-loading-overlay';

    // import 'vue-loading-overlay/dist/vue-loading.css';

    export default {

        components: {
            // Pagination,
            // Loading
        },

        data(){
            return {
                // isLoading: false,
                usuario: {}
            }
        },
        mounted()
        {
            this.registryEvents();
            this.getDataPerfil();
        },
        filters: {
            formatDataTimeBr: function (date) {
                return moment(date).format('DD/MM/YYYY HH:mm');
            }
        },
        methods: {
            //REGISTRY EVENTS
            registryEvents(){
                this.registryEventRefreshPage();
            },
            registryEventRefreshPage(){
                eventBus.$on('eventRefreshPage', () => {
                    location.reload();
                });
            },
            //FIM REGISTRY EVENTS

            //EMIT EVENTS
            emitEventUpdatePasswordUsuario(){
                eventBus.$emit('eventUpdatePasswordUsuario', this.usuario);
            },
            emitEventUpdatePerfilUsuario(){
                // var usuario_update_perfil = {
                //     id: this.usuario.usuario_id,
                //     name: this.usuario.name,
                //     email: this.usuario.email
                // };
                eventBus.$emit('eventUpdateDadosUsuario', this.usuario);
            },
            //FIM EMIT EVENTS

            //OPEN MODALS
            
            initUpdatePassword(){
                this.emitEventUpdatePasswordUsuario();
            },
            initUpdate(){
                this.emitEventUpdatePerfilUsuario();
            },
            //FIM OPEN MODALS

            //LISTAGEM
            getDataPerfil()
            {
                var urlMeuPerfil = '/usuario/meusdados';

                this.isLoading = true;

                axios({
                    method: 'GET',
                    url: urlMeuPerfil
                })
                .then(response => {
                    this.usuario = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar meus dados');
                });
            }
            //FIM LISTAGEM
        }
    }
</script>