<template>
    <div class="modal fade" id="demanda_create_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Novo(a)</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="row form-group">
                                
                                <div :class="(!demanda.obrigacao_legal) ? 'col-lg-12' : 'col-lg-6'">
                                    <label class="col-form-label" for="title">Obrigação Legal:</label>
                                    <basic-select :options="all_obrigacao_legal_sim_nao"
                                        :selected-option="obrigacao_legal_sim_nao"
                                        placeholder="Selecione a opçao"
                                        @select="onSelectObrigacaoLegal">
                                    </basic-select>
                                </div>
                                <div class="col-lg-6" v-if="(demanda.obrigacao_legal)">
                                    <label class="col-form-label" for="title">Mês de Referência:</label>
                                    <basic-select :options="meses"
                                        :selected-option="mes_referencia"
                                        placeholder="Selecione o mês"
                                        @select="onSelectMesReferencia">
                                    </basic-select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Setor Origem:</label>
                                <basic-select :options="setores_origem"
                                        :selected-option="setor_origem"
                                        placeholder="Selecione o setor de origem"
                                        @select="onSelectSetorOrigem">
                                </basic-select>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Setor Destino:</label>
                                <basic-select :options="setores_destino"
                                        :selected-option="setor_destino"
                                        placeholder="Selecione o setor de destino"
                                        @select="onSelectSetorDestino">
                                </basic-select>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Tipo:</label>
                                <basic-select :options="tipos"
                                        :selected-option="tipo"
                                        placeholder="Selecione o tipo"
                                        @select="onSelectTipo">
                                </basic-select>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Objetivo:</label>
                                <textarea 
                                    class="form-control" 
                                    rows="4"
                                    v-model="demanda.objetivo"
                                    placeholder="Objetivo..."></textarea>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="createDemanda">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            BasicSelect
        },

    	data(){
            return {
            	isLoading: false,

                //ARRAYS
            	errors: [],
                all_obrigacao_legal_sim_nao: [
                    {
                        'value': true,
                        'text': 'Sim'
                    },
                    {
                        'value': false,
                        'text': 'Não'
                    },
                ],
                meses: [
                    {
                        'value': '01',
                        'text': 'Janeiro'
                    },
                    {
                        'value': '02',
                        'text': 'Fevereiro'
                    },
                    {
                        'value': '03',
                        'text': 'Março'
                    },
                    {
                        'value': '04',
                        'text': 'Abril'
                    },
                    {
                        'value': '05',
                        'text': 'Maio'
                    },
                    {
                        'value': '06',
                        'text': 'Junho'
                    },
                    {
                        'value': '07',
                        'text': 'Julho'
                    },
                    {
                        'value': '08',
                        'text': 'Agosto'
                    },
                    {
                        'value': '09',
                        'text': 'Setembro'
                    },
                    {
                        'value': '10',
                        'text': 'Outubro'
                    },
                    {
                        'value': '11',
                        'text': 'Novembro'
                    },
                    {
                        'value': '12',
                        'text': 'Dezembro'
                    }
                ],
                setores_origem: [],
                setores_destino: [],
                setores: [],
                tipos: [],

                //OBJECTS
                obrigacao_legal_sim_nao: {
                    'value': false,
                    'text': 'Não'
                },
                mes_referencia: {},
                setor_origem: {},
                setor_destino: {},
                tipo: {},
                demanda: {
                    competencia: null,
                    obrigacao_legal: false,
                    mes_referencia: null,
                    setor_origem_id: '',
                    setor_destino_id: '',
                    objetivo: '',
                    tipo: ''
                },

                ano_competencia: null
            }
        },

        mounted(){
        	this.registryEventOpenModalCreateDemanda();
        },

        methods: {
            registryEventOpenModalCreateDemanda(){
                eventBus.$on('eventOpenModalCreateDemanda', (anoCompetencia) => {
                	this.errors = [];
                    this.ano_competencia = anoCompetencia;
                    this.resetForm();
                    this.getSetoresOrigem();
                    this.getSetoresDestino();
                    this.getTiposDemanda();
                    $("#demanda_create_modal").modal("show");
                });
            },

            emitEventGetDemandas(){
                eventBus.$emit('eventGetDemandas');
            },

            //OUTROS METODOS
            onSelectObrigacaoLegal(item){
                this.obrigacao_legal_sim_nao = item;
                this.demanda.obrigacao_legal = this.obrigacao_legal_sim_nao.value;
                if(this.demanda.obrigacao_legal == false){
                    this.mes_referencia = {};
                }
            },
            onSelectMesReferencia(item){
                this.mes_referencia = item;
                this.demanda.mes_referencia = this.mes_referencia.value;
            },
            onSelectSetorOrigem(item) {
                this.setor_origem = item;
                this.demanda.setor_origem_id = this.setor_origem.value;
            },
            onSelectSetorDestino(item) {
                this.setor_destino = item;
                this.demanda.setor_destino_id = this.setor_destino.value;
            },
            onSelectTipo(item) {
                this.tipo = item;
                this.demanda.tipo = this.tipo.value;
            },
            //FIM OUTROS METODOS

            resetForm()
            {
                this.errors = [];
                this.obrigacao_legal_sim_nao = {
                    'value': false,
                    'text': 'Não'
                };
                this.mes_referencia = {};
                this.setor_origem = {};
                this.setor_destino = {};
                this.tipo = {};
                this.demanda = {
                    competencia: this.ano_competencia,
                    obrigacao_legal: false,
                    mes_referencia: null,
                    setor_origem_id: '',
                    setor_destino_id: '',
                    objetivo: '',
                    tipo: ''
                }
            },

            validateData(data){
                var regex = "(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4}";
                var re = new RegExp(regex);
                return (re.test(data));
            },

            checkForm:function() {
                this.errors = [];

                if(this.demanda.competencia &&
                    (this.demanda.obrigacao_legal != null) &&
                    this.demanda.setor_origem_id &&
                    this.demanda.setor_destino_id &&
                    this.demanda.objetivo &&
                    this.demanda.tipo
                ) {

                    if((this.demanda.obrigacao_legal) == true && (this.demanda.mes_referencia == null)){
                        this.errors.push("Selecione o mês de referência.");
                        return false;
                    }

                    return true;
                }
                
                if(!this.demanda.competencia) this.errors.push("Identifique a competência");
                if(this.demanda.obrigacao_legal == null) this.errors.push("Identifique se é obrigação legal.");
                if(!this.demanda.setor_origem_id) this.errors.push("Selecione o setor de origem");
                if(!this.demanda.setor_destino_id) this.errors.push("Selecione o setor de destino");
                if(!this.demanda.objetivo) this.errors.push("Informe o objetivo");
                if(!this.demanda.tipo) this.errors.push("Selecione o tipo");
            },

            createDemanda()
            {
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.post('/demanda', this.demanda).then(response => {
                        this.isLoading = false;
                        $("#demanda_create_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetDemandas();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            },
            getSetoresOrigem(){
                var urlSetores = '/setor/combo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlSetores,
                    params: {
                        check_setor_user: true
                    }
                })
                .then(response => {
                    let me = this;
                    me.isLoading = false;
                    me.setores_origem = [];
                    $.each(response.data, function (key, value) {
                        me.setores_origem.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar setores');
                });
            },
            getSetoresDestino(){
                var urlSetores = '/setor/combo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlSetores,
                    // params: {
                    //     check_user: checkUserParam
                    // }
                })
                .then(response => {
                    let me = this;
                    me.isLoading = false;
                    me.setores_destino = [];
                    $.each(response.data, function (key, value) {
                        me.setores_destino.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar setores');
                });
            },
            getTiposDemanda(){
                var urlTiposDemanda = '/demanda/tipo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlTiposDemanda
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.tipos = [];
                    $.each(response.data, function (key, item) {
                        me.tipos.push({
                            'value': item.value,
                            'text': item.text
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar tipos de demanda');
                });
            },
        }
    }
</script>