<template>
    <div class="container">
        <div>
            <demanda-search/>
            <demanda-create/>
            <demanda-update/>
            <demanda-delete/>
            <demanda-tramitar/>
            <demanda-hitorico/>
            <demanda-gerenciar-status/>
            <demanda-anexos-modal/>
            <demanda-add-anexo-modal/>
            <demanda-remove-anexo-modal/>
        </div>
        <div class="row remove-padding-left-right-grid">
            <div class="col-xl-12 col-lg-12 grid-margin stretch-card">
                <div class="card card-border-top">
                    <div class="card-body">
                        <h4 class="card-title">Demandas</h4>
                        <p class="card-description text-right"> 
                            <button @click="gerarRelatorioPdf()"  type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-adobe-acrobat"></i>Relatório
                            </button>
                            <button @click="initSearch()" type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-filter"></i>
                                {{ titleButtonFilter }}
                            </button>
                            <button @click="initAdd()"  type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-plus-circle"></i>Nova
                            </button>
                        </p>
                        <div class="table-responsive" style="min-height: 60vh;">
                            <table class="table table-hover">
                                <thead>
                                    <tr class="d-flex">
                                        <th :style="columns.one" class="text-center"> Nº Sequência</th>
                                        <th :style="columns.two"> Setor Origem/Setor Destino</th>
                                        <th :style="columns.three"> Tipo </th>
                                        <th :style="columns.four"> Objetivo </th>
                                        <th :style="columns.five" class="text-center"> Data Prazo </th>
                                        <th :style="columns.six" class="text-center"> Data Hora</th>
                                        <th :style="columns.seven" class="text-center"> Status </th>
                                        <th :style="columns.eight" class="text-center"> Ações </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="d-flex" v-for="(item, index) in demandas">
                                        <td :style="columns.one" class="vertical-center-middle text-center">
                                            <b>{{ item.numero_sequencia }}</b>
                                        </td>
                                        <td :style="columns.two" class="vertical-center-middle">
                                            {{ item.setor_origem_nome }} / <br/>
                                            {{ item.setor_destino_nome }}
                                        </td>
                                        <td :style="columns.three" class="vertical-center-middle"> 
                                            {{ item.tipo }}
                                        </td>
                                        <td :style="columns.four" class="vertical-center-middle"> 
                                            {{ item.objetivo }} 
                                        </td>
                                        <td :style="columns.five" class="vertical-center-middle text-center"> 
                                            {{ item.data_prazo | formatDataBr }} 
                                        </td>
                                        <td :style="columns.six" class="vertical-center-middle text-center">
                                            {{ item | formatColumnDataListDemandas }}
                                        </td>
                                        <td :style="columns.seven" class="vertical-center-middle text-center"> 
                                            <span 
                                                v-bind:style="{ 'background-color' : formatColorStatus(item.status) }" class="label">
                                                {{ item.status | formatStatus }}
                                            </span><br/>
                                        </td>
                                        <td :style="columns.eight" class="vertical-center-middle text-center">
                                            <div class="dropdown">
                                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-outline-primary">
                                                    <i class="mdi mdi-format-list-bulleted"></i>
                                                </button>
                                                
                                                <div class="dropdown-menu" 
                                                    x-placement="top-start" >
                                                    <a @click="initUpdate(item)" class="dropdown-item">
                                                        Editar
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initGerenciarStatus(item)" class="dropdown-item">
                                                        Gerenciar Status
                                                    </a>
                                                    <div class="dropdown-divider" v-if="item.status == 'atestado'"></div>
                                                    <a @click="initTramitar(item)" class="dropdown-item" v-if="item.status == 'atestado'">
                                                        Tramitar
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initDelete(item)" class="dropdown-item">
                                                        Deletar
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initHistorico(item)" class="dropdown-item">
                                                        Histórico
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initAnexos(item)" class="dropdown-item">
                                                        Anexos
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="demandas.length == 0">
                                        <td colspan="9" class="text-center">
                                            Nenhum item encontrado.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="mt-3">
                            <pagination :paginationData="pagination" @navigate="goToPage"></pagination>
                        </div>

                    </div>
                </div>
            </div>
        </div>
      
    </div>
</template>

<style type="text/css" scoped>
    .card-title {
        margin-bottom: 0px !important;
        font-size: 18px !important;
        padding-top: 16px !important;
        padding-left: 10px !important;
    }
    .card-description {
        margin-bottom: 8px !important;
    }
    .card-border-top {
        border-top: 3px solid #149AF9 !important;
    }
    .card-body {
        padding: 0px 10px !important;
    }
    .btn {
        padding: 7px 7px !important;
    }
    .vertical-center-middle {
        white-space:normal;
        text-align: justify;
        display: flex;
        align-items: center;
    }

    .text-center {
        justify-content: center
    }
    
    /*table thead tr th, table tbody tr td{
        font-size: 4px;
    }*/
    
    table tbody tr td{
        font-size: 13px;
    }
</style>

<script>

    import moment from 'moment';
    import { eventBus } from '../../app';
    import Pagination from '../paginacao/Pagination.vue';

    export default {

        components: {
            Pagination
        },

        data(){
            return {
                titleButtonFilter: 'Filtro(s)',
                columns: {
                    one: 'width: 9%;', //numero
                    two: 'width: 20%;', // setores
                    three: 'width: 11%;', //tipo
                    four: 'width: 30%;', //objetivo
                    five: 'width: 10%;', // data prazo
                    six: 'width: 7%;', // data hora
                    seven: 'width: 7%;', // status
                    eight: 'width: 5%;' //acoes
                },
                
                isLoading: false,
                
                //ARRAYS
                demandas: [],

                //SEARCH DATA
                filtros: [],

                //PAGINATION
                pagination: {},

                competencia_url: null,

                numero_sequencia_url: null
            }
        },

        mounted() {
            this.registryEvents();
            var urlSplit = window.location.pathname.split('/');
            var competencia = parseInt(urlSplit[3]);
            var windowLocationSearch = window.location.search;
            var numSeqUrl = new URLSearchParams(windowLocationSearch).get('numero_sequencia_url');
            this.numero_sequencia_url = numSeqUrl;
            if(!isNaN(competencia)){
                this.competencia_url = competencia;
                this.getDemandas();
            }
            this.setTitleFilterButton();
        },

        filters: {
            formatDataBr: function (date) {
                if (date) {
                    return moment(date).format('DD/MM/YYYY');
                }
                return 'Não Informado';
            },
            formatDataHoraBr: function (date) {
                if (date) {
                    return moment(date).format('DD-MM-YYYY HH:mm');
                }
                return 'Não Informado';
            },
            formatColumnDataListDemandas: function (param) {
                if(param.status == 'recebido' || param.status == 'em_atraso'){
                    return 'Recebimento: '+moment(param.data_recebimento).format('DD/MM/YYYY HH:mm');
                }
                else if(param.status == 'concluido'){
                    return 'Conclusão: '+moment(param.data_conclusao).format('DD/MM/YYYY HH:mm');
                }
                else if(param.status == 'atestado'){
                    return 'Atesto: '+moment(param.data_atesto).format('DD/MM/YYYY HH:mm');
                }
                else if(param.status == 'criado' || param.status == 'com_pendencia'){
                    return 'Não Informado';
                }
            },
            formatStatus: function (param) {
                let tiposStatus = {
                    'criado': 'CRIADO',
                    'recebido': 'RECEBIDO',
                    'concluido': 'CONCLUÍDO',
                    'com_pendencia': 'PENDÊNCIA',
                    'atestado': 'ATESTADO',
                    'em_atraso': 'EM ATRASO'
                };
                return tiposStatus[param];
            },
        },

        methods: {
            formatTipo(tipoParam){
                return this.tipos_demanda[tipoParam];
            },
            formatColorStatus(param){
                if (param == 'criado') {
                    return '#F39C12';
                }
                else if (param == 'recebido' || param == 'concluido' || param == 'atestado') {
                    return 'green';
                }
                else if (param == 'com_pendencia' || param == 'em_atraso') {
                    return '#DD4B39';
                }
            },
            //REGISTRY EVENTS
            registryEvents(){
                this.registryEventGetDemandas();
                this.registryEventGetDemandasFilter();
            },
            registryEventGetDemandas(){
                eventBus.$on('eventGetDemandas', (page) => {
                    this.getDemandas(page);
                });
            },
            registryEventGetDemandasFilter(){
                eventBus.$on('eventGetDemandasFilter', (filtrosEvent) => {
                    this.filtros = filtrosEvent;
                    this.setTitleFilterButton();
                    this.getDemandas();
                });
            },
            //FIM REGISTRY EVENTS

            emitEventOpenModalSearch(){
                eventBus.$emit('eventOpenModalSearchDemanda', 
                    this.filtros,
                    this.competencia_url,
                    this.numero_sequencia_url
                );
            },
            emitEventOpenModalCreate(){
                eventBus.$emit('eventOpenModalCreateDemanda', this.competencia_url);
            },
            emitEventOpenModalUpdate(item){
                eventBus.$emit('eventOpenModalUpdateDemanda', 
                    Object.assign({}, item),
                    this.pagination.current_page
                );
            },
            emitEventOpenModalGerenciarStatus(item){
                eventBus.$emit('eventOpenModalGerenciarStatusDemanda', 
                    Object.assign({}, item),
                    this.pagination.current_page
                );
            },
            emitEventOpenModalTramitar(item){
                eventBus.$emit('eventOpenModalTramitarDemanda', 
                    item,
                    this.pagination.current_page
                );
            },
            emitEventOpenModalDelete(item){
                eventBus.$emit('eventOpenModalDeleteDemanda', 
                    item,
                    this.pagination.current_page
                );
            },
            emitEventOpenModalHistorico(item){
                eventBus.$emit('eventOpenModalHistoricoDemanda', 
                    item,
                    this.pagination.current_page
                );
            },
            emitEventOpenModalAnexosDemanda(item){
                eventBus.$emit('eventOpenModalAnexosDemanda', 
                    item,
                    this.pagination.current_page
                );
            },

            initSearch(){
                this.emitEventOpenModalSearch();
            },
            initAdd()
            {
                this.emitEventOpenModalCreate();
            }, 
            initUpdate(item)
            {
                this.emitEventOpenModalUpdate(item);
            },
            initGerenciarStatus(item){
                this.emitEventOpenModalGerenciarStatus(item);
            },
            initTramitar(item){
                this.emitEventOpenModalTramitar(item);
            },
            initDelete(item)
            {
                this.emitEventOpenModalDelete(item);
            },
            initHistorico(item){
                this.emitEventOpenModalHistorico(item);
            },
            initAnexos(item)
            {
                this.emitEventOpenModalAnexosDemanda(item);
            },

            gerarRelatorioPdf()
            {
                var urlRelatorio = '/demanda/relatorio';

                this.isLoading = true;

                if (this.filtros.length > 0) {
                    urlRelatorio += "?"; 
                    var me = this;
                    $.each(me.filtros, function (key, value) {
                        urlRelatorio += 'filters[]='+JSON.stringify(value)+'&';
                    });
                }

                var child = window.open(urlRelatorio); 
                child.focus(); 

                this.isLoading = false;
            },

            getDemandas(page)
            {
                var urlDemandas = '/demanda/listdata';

                this.isLoading = true;
                this.demandas = [];

                //POR PADRÃO OS ARQUIVOS SÃO LISTADOS PELA COMPETENCIA INFORMADA
                if (this.filtros.length == 0) {
                    this.filtros.push({ 
                        'property': 'competencia',
                        'value': this.competencia_url
                    });
                }

                if(this.numero_sequencia_url){
                    this.filtros =  this.filtros.filter(function(filtro) {
                        return filtro.property != 'numero_sequencia';
                    });
                    this.filtros.push({ 
                        'property': 'numero_sequencia',
                        'value': this.numero_sequencia_url
                    });
                    this.setTitleFilterButton();
                }

                axios({
                    method: 'GET',
                    url: urlDemandas,
                    params: {
                        filters: this.filtros,
                        page: page //UTILIZADO NA PAGINAÇÃO
                    }
                })
                .then(response => {
                    this.demandas = response.data.demandas.data;
                    this.pagination = response.data.pagination;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao atualizar a pagina');
                });
            },
            goToPage: function (page)
            {
                this.pagination.current_page = page;
                this.getDemandas(page);
            },
            setTitleFilterButton(){
                if(this.filtros.length > 0){
                    var textFilter = this.filtros.length > 1 ? 'Filtros' : 'Filtro';
                    this.titleButtonFilter = '('+this.filtros.length+') '+textFilter;
                }
                else {
                    this.titleButtonFilter = 'Filtrar'; 
                }
            },
        }
    }
</script>
