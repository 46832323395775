<template>
    <div class="modal fade" id="arquivo_digital_add_anexo_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Novo(a)</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>
                        </div>

                        <div>
                            <div class="form-group">
                                <label class="col-form-label">Nome:</label>
                                <input type="text" placeholder="Nome" v-model="anexo_nome" class="form-control">
                            </div>
                            <div class="form-group">
                                <label class="col-form-label" for="title">Data de Pagamento:</label>
                                <the-mask 
                                class="form-control"
                                placeholder="01/01/2021"
                                :mask="['##/##/####']"
                                :masked="configDateMask.maskedValue"
                                v-model="anexo_data_pagamento"/>
                            </div>
                            <div class="form-group">
                                <label>Anexo:</label>
                                <input 
                                    class="form-control-file" 
                                    id="file" 
                                    type="file" 
                                    ref="file" 
                                    name="file"
                                    v-on:change="onFileChange">
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="salvarAnexo">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../../app';
    import Dot from '../../loadder/Dot.vue';
    import { TheMask } from 'vue-the-mask';

    export default {

    	components: {
            Dot,
            TheMask,
        },

    	data(){
            return {
                configDateMask: {
                    maskedValue: true
                },
            	isLoading: false,

                
                errors: [],
                //files: [],

                arquivo_pdf: '',
                formData: {},
                attachment: '',
                anexo_nome: '',
                anexo_data_pagamento: '',

                arquivo_digital: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalAddAnexoArquivoDigital();
        },

        methods: {
            registryEventOpenModalAddAnexoArquivoDigital(){
                eventBus.$on('eventOpenModalAddAnexoArquivoDigital', (arquivoDigitalParam) => {
                	this.errors = [];
                    this.resetForm();
                    this.arquivo_digital = arquivoDigitalParam;
                    $("#arquivo_digital_add_anexo_modal").modal("show");
                });
            },

            emitEventGetAnexosArquivoDigital(){
                eventBus.$emit('eventGetAnexosArquivoDigital');
            },
            validationSizeFile(file){
                this.errors = [];
                var fileSize = (file.size/1024/1024);
                if (fileSize > 100) {
                    this.errors.push('Arquivo muito GRANDE. Selecione um arquivo de no máximo 100MB (MegaBytes) de tamanho.');
                    return false;
                }
                return true;
            },
            validationTypeFile(file){
                this.errors = [];
                var fileType = file.type;
                var typesAceptsImages = ["application/pdf"];
                if (typesAceptsImages.includes(fileType) == false) {
                    this.errors.push('Tipo de arquivo não permitido. Selecione um arquivo do tipo PDF.');
                    return false;
                }
                return true;
            },
            onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                
                if(files[0] && files[0].name){
                    let fileName = files[0].name;
                    let fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
                    this.anexo_nome = fileNameWithoutExtension;
                }

                this.createFile(files[0]);
            },
            createFile(file) {
                let reader = new FileReader();
                let vm = this;
                reader.onload = (e) => {
                    vm.arquivo_pdf = e.target.result;
                };
                reader.readAsDataURL(file);
                this.errors = [];
            },
            //OUTROS METODOS
            
            //FIM OUTROS METODOS

            resetForm()
            {
                this.errors = [];
                this.arquivo_digital = {};
                this.attachment = '';
                this.arquivo_pdf = '';
                this.formData = {};
                $("#file").val('');
                this.$refs.file.value = null;
                this.anexo_nome = '';
                this.anexo_data_pagamento = '';
            },

            validateData(data){
                var regex = "(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4}";
                var re = new RegExp(regex);
                return (re.test(data));
            },

            salvarAnexo(){
                if(!this.anexo_nome){
                    this.errors.push('Informe um nome para o arquivo.');
                    return false;
                }
                if(!this.anexo_data_pagamento || (this.validateData(this.anexo_data_pagamento) == false)){
                    this.errors.push('Informe uma data de pagamento válida.');
                    return false;
                }
                if (this.$refs.file.files.length == 0) {
                    this.errors.push('Selecione um arquivo');
                    return false;
                }


                this.attachment = this.$refs.file.files[0];

                if (this.validationTypeFile(this.attachment) == true &&
                    this.validationSizeFile(this.attachment) == true) {

                    this.formData = new FormData();
                    this.formData.append('nome', this.anexo_nome);
                    this.formData.append('data_pagamento', this.anexo_data_pagamento);
                    this.formData.append('file', this.attachment);
                    this.formData.append('arquivos_digital_id', this.arquivo_digital.id);

                    this.isLoading = true;

                    let baseRUL = window.location.origin+'/arquivodigital/anexos/upload';
                    axios.post(baseRUL, this.formData, 
                        {headers: {'Content-Type': 'multipart/form-data'}}
                    )
                    .then(response => {
                        this.isLoading = false;
                        toastr.success(response.data.message);
                        $("#arquivo_digital_add_anexo_modal").modal("hide");
                        this.emitEventGetAnexosArquivoDigital();
                    })
                    .catch(error => {
                        let me = this;
                        me.isLoading = false;
                        let erroObject = error.response.data;
                        me.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            let mensagem = 'Falha ao enviar arquivo!';
                            let erroObject = error.response.data;
                            if(erroObject.message){
                                mensagem = erroObject.message;
                            }
                            toastr.error(mensagem);
                        }
                    });
                }
            }
        }
    }
</script>