<template>
    <div class="modal fade" id="organizacao_create_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Novo(a)</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Nome:</label>
                                <input type="text" v-model="organizacao.nome" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">UF: *</label>
                                <basic-select :options="ufs"
                                        :selected-option="uf"
                                        placeholder="Selecione a opção"
                                        @select="onSelectUF">
                                </basic-select>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Tipo: *</label>
                                <basic-select :options="tipos"
                                        :selected-option="tipo"
                                        placeholder="Selecione a opção"
                                        @select="onSelectTipo">
                                </basic-select>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Periodicidade RGF: *</label>
                                <basic-select :options="periodicidades_rgf"
                                        :selected-option="periodicidade_rgf"
                                        placeholder="Selecione a opção"
                                        @select="onSelectPeriodicidadeRGF">
                                </basic-select>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="createOrganizacao">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            BasicSelect
        },


    	data(){
            return {
            	isLoading: false,

                //ARRAYS
            	errors: [],
                ufs: [
                    {
                        'value': 'RN',
                        'text': 'RN'
                    },
                    {
                        'value': 'CE',
                        'text': 'CE'
                    },
                    {
                        'value': 'PB',
                        'text': 'PB'
                    }
                ],
                tipos: [
                    {
                        'value': 'prefeitura',
                        'text': 'PREFEITURA'
                    },
                    {
                        'value': 'camara_municipal',
                        'text': 'CÂMARA MUNICIPAL'
                    }
                ],
                periodicidades_rgf: [
                    {
                        'value': 'quadrimestral',
                        'text': 'QUADRIMESTRAL'
                    },
                    {
                        'value': 'semestral',
                        'text': 'SEMESTRAL'
                    }
                ],

                //OBJECTS
                uf: {},
                tipo: {},
                periodicidade_rgf: {},
                organizacao: {
                    nome: '',
                    uf: '',
                    tipo: '',
                    periodicidade_rgf: ''
                }
            }
        },

        mounted(){
        	this.registryEventOpenModalCreateOrganizacao();
        },

        methods: {
            registryEventOpenModalCreateOrganizacao(){
                eventBus.$on('eventOpenModalCreateOrganizacao', () => {
                	this.errors = [];
                    this.resetForm();
                    $("#organizacao_create_modal").modal("show");
                });
            },

            emitEventGetOrganizacoes(){
                eventBus.$emit('eventGetOrganizacoes');
            },

            resetForm()
            {
                this.errors = [];
                this.uf = {};
                this.tipo = {};
                this.periodicidade_rgf = {};
                this.organizacao = {
                    nome: '',
                    uf: '',
                    tipo: '',
                    periodicidade_rgf: ''
                }
            },

            //OUTROS METODOS
            onSelectUF(item) {
                this.uf = item;
                this.organizacao.uf = this.uf.value;
            },
            onSelectTipo(item) {
                this.tipo = item;
                this.organizacao.tipo = this.tipo.value;
            },
            onSelectPeriodicidadeRGF(item) {
                this.periodicidade_rgf = item;
                this.organizacao.periodicidade_rgf = this.periodicidade_rgf.value;
            },
            //FIM OUTROS METODOS

            checkForm:function() {
                this.errors = [];

                if(this.organizacao.nome &&
                    this.organizacao.uf &&
                    this.organizacao.tipo &&
                    this.organizacao.periodicidade_rgf
                ) {
                    return true;
                }
                
                if(!this.organizacao.nome) this.errors.push("Informe o nome");
                if(!this.organizacao.uf) this.errors.push("Selecione o UF");
                if(!this.organizacao.tipo) this.errors.push("Selecione o Tipo");
                if(!this.organizacao.periodicidade_rgf) this.errors.push("Selecione a periodicidade RGF");
            },

            createOrganizacao()
            {
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.post('/organizacao', this.organizacao).then(response => {
                        this.isLoading = false;
                        $("#organizacao_create_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetOrganizacoes();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            },
        }
    }
</script>