<template>
	<div v-if="visible">
        <div class="dot-opacity-loader"><span></span> <span></span> <span></span></div>
    </div>
</template>

<script>
	export default {

		props: ['isVisible'],

		data(){
			return {
				visible: false
			}
		},

		watch: {
	        isVisible: function(newVal) { 
	           this.visible = newVal;
	        }
      	},
	}
</script>