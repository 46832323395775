<template>
    <div class="modal fade" id="arquivo_digital_anexos_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Anexos</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">

                            <div>
                                <button 
                                    @click.prevent.stop="initOpenModalAddAnexo()" 
                                    class="btn btn-success">
                                    <span class="glyphicon glyphicon-plus"></span>
                                    Adicionar Anexo
                                </button>
                            </div>

                            <br/>

                            <div class="panel panel-default">
                                <div class="panel-body">
                                    <div class="table-responsive" style="min-height: 60vh;">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr class="d-flex">
                                                    <th :style="columns.one"> Nome</th>
                                                    <th :style="columns.two" class="text-center"> Ações </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="d-flex" v-for="(item, index) in anexos_arquivo_digital">
                                                    <td :style="columns.one" class="vertical-center-middle">
                                                        {{ item.nome }}
                                                    </td>
                                                    <td :style="columns.two" class="vertical-center-middle text-center"> 
                                                        <div class="dropdown">
                                                            <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-outline-primary">
                                                                <i class="mdi mdi-format-list-bulleted"></i>
                                                            </button>
                                                            
                                                            <div class="dropdown-menu" 
                                                                x-placement="top-start" >
                                                                <a @click="openAnexoPdf(item.id)" class="dropdown-item">
                                                                    Abrir
                                                                </a>
                                                                <div class="dropdown-divider"></div>
                                                                <a @click="initDeleteAnexo(item)" class="dropdown-item">
                                                                    Remover
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr v-if="anexos_arquivo_digital.length == 0">
                                                    <td colspan="2" class="text-center">
                                                        Nenhum item encontrado.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .modal-dialog {
        min-width: 700px;
    }
</style> 

<script>

    import { eventBus } from '../../../app';
    import Dot from '../../loadder/Dot.vue';

    export default {

        components: {
            Dot,
        },

        data(){
            return {
                isLoading: false,
                columns: {
                    one: 'width: 85%;',
                    two: 'width: 15%;'
                },

                //ARRAYS
                anexos_arquivo_digital: [],
                tipos: [],

                //OBJECTS
                arquivo_digital: {}
            }
        },

        mounted(){
            this.registryEventOpenModalAnexosArquivoDigital();
            this.registryEventGetAnexosArquivoDigital();
        },

        methods: {
            registryEventOpenModalAnexosArquivoDigital(){
                eventBus.$on('eventOpenModalAnexosArquivoDigital', (arquivoDigitalParam) => {
                    this.errors = [];
                    this.arquivo_digital = arquivoDigitalParam;
                    this.getAnexosArquivoDigital();
                    $("#arquivo_digital_anexos_modal").modal("show");
                });
            },

            registryEventGetAnexosArquivoDigital(){
                eventBus.$on('eventGetAnexosArquivoDigital', () => {
                    this.getAnexosArquivoDigital();
                });
            },

            initOpenModalAddAnexo(){
                eventBus.$emit('eventOpenModalAddAnexoArquivoDigital', this.arquivo_digital);
            },

            initDeleteAnexo(anexo)
            {
                eventBus.$emit('eventOpenModalDeleteAnexoArquivoDigital', anexo);
            },

            openAnexoPdf(anexoId){
                var urlArquivoDigitalPdf = '/arquivodigital/anexos/pdf?anexo_id='+anexoId;
                this.isLoading = true;
                var child = window.open(urlArquivoDigitalPdf); 
                child.focus(); 
                this.isLoading = false;
            },

            getAnexosArquivoDigital(){
                var urlAnexosArquivoDigital = '/arquivodigital/anexos';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlAnexosArquivoDigital,
                    params: {
                        arquivo_digital_id: this.arquivo_digital.id
                    }
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.anexos_arquivo_digital = response.data.anexos_arquivo_digital;
                })
                .catch(error => {
                    this.isLoading = false;
                    let erroObject = error.response.data;
                    let messageError = 'Falha ao listar anexos do arquivo digital';
                    if( error.response.status === 409 ){
                        if(erroObject.message){
                            messageError = erroObject.message;
                        }
                    }
                    toastr.error(messageError);
                });
            },
        }
    }
</script>