<template>
    <div class="modal fade" id="tramitar_demanda_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Atualizar</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Origem:</label>
                                <input type="text" class="form-control" v-model="setor_origem.text" readonly>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Último Setor de Destino: (Setor Atual)</label>
                                <input type="text" class="form-control" v-model="localizacao_atual" readonly>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Novo Setor Destino:</label>
                                <basic-select :options="setores"
                                        :selected-option="setor_destino"
                                        placeholder="Selecione o setor de destino"
                                        @select="onSelectSetorDestino">
                                </basic-select>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="tramitarDemanda">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            BasicSelect
        },


    	data(){
            return {
                currentPagePagination: null,

            	isLoading: false,

                //ARRAYS
            	errors: [],
                setores: [],

                //OBJECTS
                setor_origem: {},
                setor_destino: {},
                update_demanda: {},
                update_demanda_old: {},
                localizacao_atual: ''
            }
        },

        mounted(){
        	this.registryEventOpenModalTramitarDemanda();
        },

        methods: {
            registryEventOpenModalTramitarDemanda(){
                eventBus.$on('eventOpenModalTramitarDemanda', (updateDemanda, currentPage) => {
                	this.errors = [];
                    this.setDataDemanda(updateDemanda);
                    this.currentPagePagination = currentPage;
                    this.getSetores();
                    $("#tramitar_demanda_modal").modal("show");
                });
            },

            setDataDemanda(demandaParam){

                this.update_demanda = Object.assign({}, demandaParam);
                this.update_demanda_old = Object.assign({}, demandaParam);
                this.setor_destino = {};

                this.setor_origem = {
                    value: demandaParam.setor_origem_id,
                    text: demandaParam.setor_origem_nome                    
                };

                this.localizacao_atual = demandaParam.setor_destino_nome;
            },

            //OUTROS METODOS
            onSelectSetorDestino(item) {
                this.setor_destino = item;
                this.update_demanda.setor_destino_id = this.setor_destino.value;
            },
            //FIM OUTROS METODOS

            emitEventGetDemandas(){
                eventBus.$emit('eventGetDemandas', this.currentPagePagination);
            },

            checkForm:function(acao) {
                this.errors = [];

                if(this.update_demanda.setor_destino_id == this.update_demanda_old.setor_destino_id
                ) {
                    this.errors.push("Altere os dados");
                    return false;
                }

                if(this.update_demanda.setor_origem_id &&
                    this.update_demanda.setor_destino_id
                ) {
                    return true;
                }
                
                if(!this.update_demanda.setor_origem_id) this.errors.push("Selecione o setor de origem");
                if(!this.update_demanda.setor_destino_id) this.errors.push("Selecione o setor de destino");
            },

            tramitarDemanda()
            {
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.put('/demanda/tramitar/' + this.update_demanda.id, this.update_demanda)
                    .then(response => {
                        this.isLoading = false;
                        $("#tramitar_demanda_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetDemandas();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            },
            getSetores(){
                var urlSetores = '/setor/combo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlSetores
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.setores = [];
                    $.each(response.data, function (key, value) {
                        me.setores.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar setores');
                });
            }
        }
    }
</script>