<template>
    <div class="modal fade" id="arquivo_digital_importar_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Novo(a)</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>
                            <div class="row form-group">
                                <div class="col-lg-9">
                                    <label class="col-form-label" for="title">Tipo:</label>
                                    <basic-select :options="tipos"
                                            :selected-option="tipo"
                                            placeholder="Selecione o tipo"
                                            @select="onSelectTipo">
                                    </basic-select>
                                </div>
                                <div class="col-lg-3">
                                    <label class="col-form-label" for="title">Competência:</label>
                                    <input style="height: 44px;" type="number" placeholder="Não Informado" v-model="arquivo_digital.competencia" class="form-control" disabled>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-lg-12">
                                    <label class="col-form-label" for="title">Unidade Gestora:</label>
                                    <basic-select :options="unidades_gestoras"
                                            :selected-option="unidade_gestora"
                                            placeholder="Selecione a Unidade Gestora"
                                            @select="onSelectUnidadeGestora">
                                    </basic-select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Arquivo:</label>
                                <input 
                                    class="form-control-file" 
                                    id="file" 
                                    type="file" 
                                    ref="file" 
                                    name="file"
                                    v-on:change="onFileChange">
                            </div>
                        </div>                      
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="importarArquivoDigital">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .modal-dialog {
        min-width: 700px;
    }
    .button-add-remove-input {
        width: 45px; 
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-clear-orgaos-selected-licitacao {
        width: 45px; 
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
    }
</style> 

<script>

    import { Money } from 'v-money';
	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { TheMask } from 'vue-the-mask';
    import { BasicSelect, MultiSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            Money,
            TheMask,
            BasicSelect,
            MultiSelect
        },


    	data(){
            return {
                configDateMask: {
                    maskedValue: true
                },
                //MASK-MONEY
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'R$ ',
                    //suffix: ' #',
                    precision: 2,
                    masked: false
                },
            	isLoading: false,

                //ARRAYS
            	errors: [],
                tipos: [
                    {
                        'text': 'Empenho', 
                        'value': 'empenho'
                    },
                    /*
                    {
                        'text': 'Liquidação', 
                        'value': 'liquidacao'
                    },
                    {
                        'text': 'Pagamento', 
                        'value': 'pagamento'
                    },
                    {
                        'text': 'Prestação de Contas', 
                        'value': 'prestacao_contas'
                    },
                    {
                        'text': 'Licitação', 
                        'value': 'licitacao'
                    },
                    {
                        'text': 'Setor Pessoal', 
                        'value': 'setor_pessoal'
                    },
                    {
                        'text': 'Outros', 
                        'value': 'outros'
                    }
                    */
                ],
                unidades_gestoras: [],

                //OBJECTS
                tipo: {},
                unidade_gestora: {},
                arquivo_digital: {
                    tipo: '',
                    unidade_gestora_id: '',
                    competencia: '',
                    descricao_resumida: ''
                },
                ano_competencia: null,

                arquivo_pdf: '',
                formData: {},
                attachment: '',
                anexo_nome: '',
            }
        },

        mounted(){
        	this.registryEventOpenModalImportarArquivoDigital();
        },

        methods: {
            registryEventOpenModalImportarArquivoDigital(){
                eventBus.$on('eventOpenModalImportarArquivoDigital', (anoCompetencia) => {
                	this.errors = [];
                    this.ano_competencia = anoCompetencia;
                    this.resetForm();
                    this.getUnidadesGestoras();
                    $("#arquivo_digital_importar_modal").modal("show");
                });
            },
            emitEventGetArquivosDigital(){
                eventBus.$emit('eventGetArquivosDigital');
            },
            //OUTROS METODOS
            onSelectTipo(item) {
                this.tipo = item;
                this.arquivo_digital.tipo = this.tipo.value;
            },
            onSelectUnidadeGestora(item) {
                this.unidade_gestora = item;
                this.arquivo_digital.unidade_gestora_id = this.unidade_gestora.value;
            },
            validationSizeFile(file){
                this.errors = [];
                var fileSize = (file.size/1024/1024);
                if (fileSize > 10) {
                    this.errors.push('Arquivo muito GRANDE. Selecione um arquivo de no máximo 10MB (MegaBytes) de tamanho.');
                    return false;
                }
                return true;
            },
            validationTypeFile(file){
                this.errors = [];
                var fileType = file.type;
                var typesAceptsFiles = [
                    "application/vnd.ms-excel", // .xls
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // .xlsx
                ];
                if (typesAceptsFiles.includes(fileType) == false) {
                    this.errors.push('Tipo de arquivo não permitido. Selecione um arquivo do tipo EXCEL.');
                    return false;
                }
                return true;
            },
            onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createFile(files[0]);
            },
            createFile(file) {
                let reader = new FileReader();
                let vm = this;
                reader.onload = (e) => {
                    vm.arquivo_pdf = e.target.result;
                };
                reader.readAsDataURL(file);
                this.errors = [];
            },
            //FIM OUTROS METODOS

            resetForm()
            {
                this.errors = [];                
                this.tipo = {};
                this.unidade_gestora = {};
                this.arquivo_digital = {
                    tipo: '',
                    unidade_gestora_id: null,
                    competencia: this.ano_competencia
                };
                this.attachment = '';
                this.arquivo_pdf = '';
                this.formData = {};
                $("#file").val('');
            },
            
            checkForm:function() {
                this.errors = [];
                if(this.arquivo_digital.tipo &&
                    this.arquivo_digital.unidade_gestora_id &&
                    this.arquivo_digital.competencia
                ) {
                    return true;
                }
                if(!this.arquivo_digital.tipo) this.errors.push("Selecione o tipo");
                if(!this.arquivo_digital.unidade_gestora_id) this.errors.push("Selecione a Unidade Gestora");
                if(!this.arquivo_digital.competencia) this.errors.push("Informe a competência");
                $("#arquivo_digital_importar_modal").scrollTop(0);
                return false;
            },
            importarArquivoDigital()
            {
                if(this.checkForm()){ 
                    if (this.$refs.file.files.length == 0) {
                        this.errors.push('Selecione um arquivo');
                        return false;
                    }
                    this.attachment = this.$refs.file.files[0];
                    
                    if (this.validationTypeFile(this.attachment) == true) {

                        this.formData = new FormData();
                        this.formData.append('tipo', this.arquivo_digital.tipo);
                        this.formData.append('unidade_gestora_id', this.arquivo_digital.unidade_gestora_id);
                        this.formData.append('competencia', this.arquivo_digital.competencia);
                        this.formData.append('arquivo', this.attachment);
                        this.isLoading = true;
                        let baseRUL = window.location.origin+'/arquivodigital/storeImportar';
                        axios.post(baseRUL, this.formData, 
                            {headers: {'Content-Type': 'multipart/form-data'}}
                        )
                        .then(response => {
                            this.isLoading = false;
                            $("#arquivo_digital_importar_modal").modal("hide");
                            toastr.success(response.data.message);
                            this.emitEventGetArquivosDigital();
                        })
                        .catch(error => {
                            let me = this;
                            this.isLoading = false;
                            let erroObject = error.response.data;
                            this.errors = [];
                            if( error.response.status === 422 ){
                                if (erroObject.errors) {
                                    $.each(erroObject.errors, function (key, value) {
                                        me.errors.push(value[0]);
                                    });
                                }
                            }
                            else {
                                toastr.error(erroObject.message);
                            }
                        });
                    }
                }
            },
            getUnidadesGestoras(){
                var urlUnidadesGestoras = '/unidadegestora/combo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlUnidadesGestoras
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.unidades_gestoras = [];
                    $.each(response.data, function (key, item) {
                        me.unidades_gestoras.push({
                            'value': item.id,
                            'text': item.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar unidades gestoras.');
                });
            },
        }
    }
</script>