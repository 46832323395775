<template>
    <div class="modal fade" id="arquivo_digital_detalhes_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Detalhes</b>
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table table-bordered color-white" v-if="arquivo_digital.tipo == 'empenho'">
                                <tbody>
                                    <tr>
                                        <td colspan="4" class="font-center font-dezoito">
                                            <b>DADOS DO EMPENHO</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <b>Número:</b> {{ arquivo_digital.empenho_numero }}
                                        </td>
                                        <td>
                                            <b>Data de Emissão:</b> 
                                            {{ arquivo_digital.empenho_data_emissao | formatDataBr }}
                                        </td>
                                        <td>
                                            <b>Valor:</b> {{ arquivo_digital.empenho_valor | formatMoneyBr }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <b>Unidade Orçam.:</b> {{ arquivo_digital.empenho_unidade_orcamentaria_nome }}
                                        </td>
                                        <td colspan="2">
                                            <b>Fornecedor:</b> {{ arquivo_digital.empenho_fornecedor }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <b>Espécie:</b> {{ arquivo_digital.empenho_especie }}
                                        </td>
                                        <td colspan="2">
                                            <b>Fonte de Recursos:</b> {{ arquivo_digital.empenho_fonte_recursos }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <b>Classificação:</b> {{ arquivo_digital.empenho_classificacao_despesa }}
                                        </td>
                                        <td colspan="2">
                                            <b>Ação:</b> {{ arquivo_digital.empenho_acao }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <b>Credor:</b> {{ arquivo_digital.empenho_credor }}
                                        </td>
                                        <td colspan="2">
                                            <b>Documento do Credor:</b> {{ arquivo_digital.empenho_documento_credor }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <b>Unidade Gestora:</b> {{ arquivo_digital.empenho_credor }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <b>Data de Liquidação:</b> {{ arquivo_digital.empenho_data_liquidacao | formatDataBr }}
                                        </td>
                                        <td colspan="2">
                                            <b>Documento de Pagamento:</b> {{ arquivo_digital.empenho_data_pagamento | formatDataBr }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" style="text-align: justify;">
                                            <b>Objeto:</b> {{ arquivo_digital.empenho_objeto }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-bordered color-white" v-if="arquivo_digital.tipo == 'liquidacao'">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="font-center font-dezoito">
                                            <b>DADOS DA LIQUIDAÇÃO</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Número do Empenho da Liquidação:</b> {{ arquivo_digital.liquidacao_empenho_numero }}
                                        </td>
                                        <td>
                                            <b>Data de Vencimento:</b> 
                                            {{ arquivo_digital.liquidacao_data_vencimento | formatDataBr }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Aviso de Liquidação:</b> {{ arquivo_digital.liquidacao_numero_aviso }}
                                        </td>
                                        <td>
                                            <b>Data de Emissão:</b> {{ arquivo_digital.liquidacao_data_emissao | formatDataBr }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Documento Fiscal:</b> {{ arquivo_digital.liquidacao_documento_fiscal }}
                                        </td>
                                        <td>
                                            <b>Valor:</b> {{ arquivo_digital.liquidacao_valor | formatMoneyBr }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-bordered color-white" v-if="arquivo_digital.tipo == 'pagamento'">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="font-center font-dezoito">
                                            <b>DADOS DO PAGAMENTO DE LIQUIDAÇÃO/EMPENHO</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Número do Aviso de Liquidação:</b> {{ arquivo_digital.pagamento_liquidacao_numero_aviso }}
                                        </td>
                                        <td>
                                            <b>Data de Movimentação:</b> 
                                            {{ arquivo_digital.pagamento_data_movimentacao | formatDataBr }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Número de Sequência:</b> {{ arquivo_digital.pagamento_numero_sequencia }}
                                        </td>
                                        <td>
                                            <b>Valor Pago:</b> {{ arquivo_digital.pagamento_valor_pago | formatMoneyBr }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <b>Conta Contábil:</b> {{ arquivo_digital.pagamento_conta_contabil }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-bordered color-white" v-if="arquivo_digital.tipo == 'prestacao_contas'">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="font-center font-dezoito">
                                            <b>DADOS DA PRESTAÇÃO DE CONTAS</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Nome:</b> {{ arquivo_digital.prestacao_contas_nome }}
                                        </td>
                                        <td>
                                            <b>Ano:</b> {{ arquivo_digital.prestacao_contas_ano }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-bordered color-white" v-if="arquivo_digital.tipo == 'licitacao'">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="font-center font-dezoito">
                                            <b>DADOS DA LICITAÇÃO</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Órgão(s):</b>
                                            <span v-for="(item, index) in arquivo_digital.licitacao_orgaos">
                                                {{ item }}
                                                <span v-if="(index+1) < (arquivo_digital.licitacao_orgaos).length">
                                                    ,
                                                </span>
                                            </span>
                                        </td>
                                        <td>
                                            <b>Data da Licitação:</b> 
                                            {{ arquivo_digital.licitacao_data | formatDataBr }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Número da Licitação:</b> {{ arquivo_digital.licitacao_numero }}
                                        </td>
                                        <td>
                                            <b>Modalidade:</b> {{ arquivo_digital.licitacao_modalidade | formatModalidadeLicitacao }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style="text-align: justify;">
                                            <b>Objeto:</b> {{ arquivo_digital.licitacao_objeto }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Vencedor(es):</b>
                                            <span v-for="(item, index) in JSON.parse(arquivo_digital.licitacao_vencedor)">
                                                {{ item }}
                                                <span v-if="(index+1) < (JSON.parse(arquivo_digital.licitacao_vencedor).length)">
                                                    ,
                                                </span>
                                            </span>
                                        </td>
                                        <td>
                                            <b>Valor:</b> {{ arquivo_digital.licitacao_valor | formatMoneyBr }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-bordered color-white" v-if="arquivo_digital.tipo == 'setor_pessoal'">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="font-center font-dezoito">
                                            <b>DADOS RELACIONADOS AO SETOR PESSOAL</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Nome:</b> {{ arquivo_digital.setor_pessoal_nome }}
                                        </td>
                                        <td>
                                            <b>CPF:</b> {{ arquivo_digital.setor_pessoal_cpf | formatCpf }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Cargo:</b> {{ arquivo_digital.setor_pessoal_cargo }}
                                        </td>
                                        <td>
                                            <b>Secretaria:</b> {{ arquivo_digital.setor_pessoal_secretaria }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    table {
      table-layout: fixed;
    }
    td {
        white-space: normal;
        word-wrap: break-word;
    }
    .modal-dialog {
        min-width: 900px;
    }
    .font-bold {
        font-weight: bold;
    }

    .font-center {
        text-align: center;
    }

    .font-dezoito {
        font-size: 18px;
    }
    .color-white {
        background-color: #fff;
    }

</style> 

<script>

    import moment from 'moment';
    import { eventBus } from '../../app';

    export default {

    	data(){
            return {
                //OBJECTS
                arquivo_digital: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalDetalhesArquivoDigital();
        },

        filters: {
            formatDataBr: function (date) {
                return date ? moment(date).format('DD/MM/YYYY') : null;
            },
            formatMoneyBr: function (valor){
                var formatter = new Intl.NumberFormat('pt-br', {
                  style: "currency",
                  currency: "BRL"
                });
                return formatter.format(valor);
            },
            formatModalidadeLicitacao: function (modalidadeParam) {
                var allModalidades = {
                    'concorrencia': 'Concorrência',
                    'pregao_presencial': 'Pregão Presencial',
                    'pregao_eletronico': 'Pregão Eletrônico',
                    'tomada_precos': 'Tomada de Preços',
                    'dispensa_presencial': 'Dispensa Presencial',
                    'dispensa_eletronica': 'Dispensa Eletrônica'
                };
                return allModalidades[modalidadeParam];
            },
            formatCpf: function(cpf){
                if(cpf){
                    cpf =  cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
                    return cpf;
                }
                else {
                    return "-";
                }
            }
        },

        methods: {
            registryEventOpenModalDetalhesArquivoDigital(){
                eventBus.$on('eventOpenModalDetalhesArquivoDigital', (arquivoDigitalParam) => {
                    this.arquivo_digital = Object.assign({}, arquivoDigitalParam);
                    if(this.arquivo_digital.tipo == 'licitacao'){
                        this.teste();
                    }
                    $("#arquivo_digital_detalhes_modal").modal("show");
                });
            },
            async teste(){
                let orgaos = await this.getOrgaos();
                let orgaosTemp = [];
                $.each(JSON.parse(this.arquivo_digital.licitacao_orgaos), function (key, item) {
                    let temp = orgaos.filter(function(orgao) {
                         return orgao.id == item;
                    });
                    orgaosTemp.push(temp[0].nome);
                });
                this.arquivo_digital.licitacao_orgaos = orgaosTemp;
            },
            getOrgaos(){
                return new Promise(resolve => {
                    var urlOrgaos = '/orgao/combo';
                    this.isLoading = true;
                    axios({
                        method: 'GET',
                        url: urlOrgaos
                    })
                    .then(response => {
                        let me = this;
                        this.isLoading = false;
                        resolve(response.data);
                    })
                    .catch(error => {
                        this.isLoading = false;
                        toastr.error('Falha ao listar órgaos.');
                        resolve([]);
                    });
                });
            },
        }
    }
</script>