<template>
    <div class="modal fade" id="demanda_gerenciar_status_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Gerenciar Status</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Status:</label>
                                <basic-select :options="all_status"
                                        :selected-option="status"
                                        placeholder="Selecione o status"
                                        @select="onSelectStatus">
                                </basic-select>
                            </div>

                            <span v-if="status.value == 'com_pendencia'">
                                <div class="form-group">
                                    <label class="col-form-label" for="title">Observação:</label>
                                    <textarea 
                                        class="form-control" 
                                        rows="3"
                                        v-model="update_demanda.observacao"
                                        placeholder="Observação"></textarea>
                                </div>
                            </span>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="updateDemanda">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            BasicSelect
        },


    	data(){
            return {
                currentPagePagination: null,

            	isLoading: false,

                //ARRAYS
            	errors: [],
                all_status: [
                    {
                        value: 'criado',
                        text: 'Criado'
                    },
                    {
                        value: 'recebido',
                        text: 'Recebido'
                    },
                    {
                        value: 'concluido',
                        text: 'Concluído'
                    },
                    {
                        value: 'com_pendencia',
                        text: 'Pendência'
                    },
                    {
                        value: 'atestado',
                        text: 'Atestado'
                    }
                ],

                //OBJECTS
                status: {},
                update_demanda: {},
                update_demanda_old: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalGerenciarStatusDemanda();
        },

        methods: {
            registryEventOpenModalGerenciarStatusDemanda(){
                eventBus.$on('eventOpenModalGerenciarStatusDemanda', (updateDemanda, currentPage) => {
                	this.errors = [];
                    this.setDataDemanda(updateDemanda);
                    this.currentPagePagination = currentPage;
                    $("#demanda_gerenciar_status_modal").modal("show");
                });
            },

            setDataDemanda(demandaParam){
                this.update_demanda = Object.assign({}, demandaParam);
                this.update_demanda_old = Object.assign({}, demandaParam);
                this.status = {
                    value: demandaParam.status,
                    text: this.formatStatus(demandaParam.status)
                };
            },

            formatStatus(param) {
                let statusLocal = {
                    'criado': 'Criado',
                    'recebido': 'Recebido',
                    'concluido': 'Concluído',
                    'com_pendencia': 'Pendência',
                    'atestado': 'Atestado',
                    'em_atraso': 'Em Atraso'
                };
                return statusLocal[param];
            },

            //OUTROS METODOS
            onSelectStatus(item) {
                this.status = item;
                this.update_demanda.status = this.status.value;
                if(this.status.value == 'criado' || this.status.value == 'recebido' || this.status.value == 'concluido'){
                    this.update_demanda.observacao = null;                    
                }
            },
            //FIM OUTROS METODOS

            emitEventGetDemandas(){
                eventBus.$emit('eventGetDemandas', this.currentPagePagination);
            },

            checkForm:function(acao) {
                this.errors = [];

                if(this.update_demanda.status == this.update_demanda_old.status &&
                    this.update_demanda.observacao == this.update_demanda_old.observacao) {
                    this.errors.push("Altere os dados");
                    return false;
                }

                if(this.update_demanda.status) {
                    if(this.update_demanda.status == 'com_pendencia'){
                        if(!this.update_demanda.observacao) {
                            this.errors.push("Informe uma observação.");
                            return false;
                        }
                    }
                    return true;
                }
                
                if(!this.update_demanda.status) this.errors.push("Selecione o status");
            },

            updateDemanda()
            {
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.put('/demanda/gerenciarstatus/' + this.update_demanda.id, this.update_demanda)
                    .then(response => {
                        this.isLoading = false;
                        $("#demanda_gerenciar_status_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetDemandas();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            }
        }
    }
</script>