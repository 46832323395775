<template>
    <div class="modal fade" id="usuario_create_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Novo</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Nome:</label>
                                <input type="text" placeholder="Nome" v-model="usuario.name" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Email:</label>
                                <input type="email" placeholder="Email" v-model="usuario.email" class="form-control">
                            </div>

                            <div class="row form-group">
                                <div class="col-lg-6">
                                    <label class="col-form-label">Senha: *</label>
                                    <input 
                                        class="form-control" 
                                        type="password" 
                                        placeholder="Senha" 
                                        v-model="usuario.password">
                                </div>
                                <div class="col-lg-6">
                                    <label class="col-form-label">Confirme Senha: *</label>
                                    <input 
                                        class="form-control" 
                                        type="password" 
                                        placeholder="Confirme Senha" 
                                        v-model="usuario.password_confirmation">
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Órgão:</label>
                                <basic-select :options="orgaos"
                                        :selected-option="orgao"
                                        placeholder="Selecione o Órgão"
                                        @select="onSelectOrgao">
                                </basic-select>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Setor:</label>
                                <basic-select :options="setores"
                                        :selected-option="setor"
                                        placeholder="Selecione o setor"
                                        @select="onSelectSetor">
                                </basic-select>
                            </div>

                            <div class="form-group" v-show="usuario.setor_id > 0">
                                <label class="col-form-label" for="title">Responsável Setor:</label>
                                <basic-select :options="opcoes_responsavel_setor"
                                        :selected-option="responsavel_setor"
                                        placeholder="Selecione"
                                        @select="onSelectResponsavelSetor">
                                </basic-select>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="createUsuario">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            BasicSelect
        },


    	data(){
            return {
                configDateMask: {
                    maskedValue: false
                },
            	isLoading: false,

                //ARRAYS
            	errors: [],
                orgaos: [],
                setores: [],
                opcoes_responsavel_setor: [
                    {
                        'value': true,
                        'text': 'Sim'
                    },
                    {
                        'value': false,
                        'text': 'Não'
                    }
                ],

                //OBJECTS
                orgao: {},
                setor: {},
                responsavel_setor: {
                    'value': false,
                    'text': 'Não'
                },
                usuario: {
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    orgao_id: '',
                    setor_id: '',
                    responsavel_setor: false
                }
            }
        },

        mounted(){
        	this.registryEventOpenModalCreateUsuario();
        },

        methods: {
            registryEventOpenModalCreateUsuario(){
                eventBus.$on('eventOpenModalCreateUsuario', () => {
                	this.errors = [];
                    this.resetForm();
                    this.getOrgaos();
                    this.getSetores();
                    $("#usuario_create_modal").modal("show");
                });
            },

            emitEventGetUsuarios(){
                eventBus.$emit('eventGetUsuarios');
            },

            onSelectOrgao(item) {
                this.orgao = item;
                this.usuario.orgao_id = this.orgao.value;
                this.setor = {};
                this.usuario.setor_id = null;
                this.responsavel_setor = {
                    'value': false,
                    'text': 'Não'
                };
                this.usuario.responsavel_setor = false;
            },
            onSelectSetor(item) {
                this.setor = item;
                this.usuario.setor_id = this.setor.value;
                this.orgao = {};
                this.usuario.orgao_id = null;
                this.responsavel_setor = {
                    'value': false,
                    'text': 'Não'
                };
                this.usuario.responsavel_setor = false;
            },
            onSelectResponsavelSetor(item) {
                this.responsavel_setor = item;
                this.usuario.responsavel_setor = this.responsavel_setor.value;
            },

            resetForm()
            {
                this.errors = [];
                this.orgao = {};
                this.setor = {};
                this.responsavel_setor = {
                    'value': false,
                    'text': 'Não'
                };
                this.usuario = {
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    orgao_id: '',
                    setor_id: '',
                    responsavel_setor: false
                }
            },

            checkForm:function() {
                this.errors = [];

                if(this.usuario.name &&
                    this.usuario.email &&
                    this.usuario.password &&
                    this.usuario.password_confirmation
                ) {

                    if(!this.usuario.orgao_id && !this.usuario.setor_id){
                        this.errors.push("Selecione um ÓRGÃO ou SETOR para o usuário.")
                        return false;
                    }

                    return true;
                }
                
                if(!this.usuario.name) this.errors.push("Informe o nome");
                if(!this.usuario.email) this.errors.push("Informe o email");
                if(!this.usuario.password) this.errors.push("Informe a senha");
                if(!this.usuario.password_confirmation) this.errors.push("Confirme a senha");
                // if(!this.usuario.setor_id) this.errors.push("Selecione o setor.");
            },
            createUsuario()
            {
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.post('/usuario', this.usuario).then(response => {
                        this.isLoading = false;
                        $("#usuario_create_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetUsuarios();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            },
            getOrgaos(){
                var urlOrgaos = '/orgao/combo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlOrgaos
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.orgaos = [];
                    $.each(response.data, function (key, value) {
                        me.orgaos.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar órgãos');
                });
            },
            getSetores(){
                var urlSetores = '/setor/combo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlSetores
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.setores = [];
                    $.each(response.data, function (key, value) {
                        me.setores.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar setores');
                });
            },
        }
    }
</script>