<template>
    <div class="modal fade" id="organizacao_update_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Atualizar</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Nome:</label>
                                <input type="text" v-model="update_organizacao.nome" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">UF: *</label>
                                <basic-select :options="ufs"
                                        :selected-option="uf"
                                        placeholder="Selecione a opção"
                                        @select="onSelectUF">
                                </basic-select>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Tipo: *</label>
                                <basic-select :options="tipos"
                                        :selected-option="tipo"
                                        placeholder="Selecione a opção"
                                        @select="onSelectTipo">
                                </basic-select>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Periodicidade RGF: *</label>
                                <basic-select :options="periodicidades_rgf"
                                        :selected-option="periodicidade_rgf"
                                        placeholder="Selecione a opção"
                                        @select="onSelectPeriodicidadeRGF">
                                </basic-select>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="updateOrganizacao">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            BasicSelect
        },


    	data(){
            return {
                currentPagePagination: null,

            	isLoading: false,

                //ARRAYS
            	errors: [],
                ufs: [
                    {
                        'value': 'RN',
                        'text': 'RN'
                    },
                    {
                        'value': 'CE',
                        'text': 'CE'
                    },
                    {
                        'value': 'PB',
                        'text': 'PB'
                    }
                ],
                tipos: [
                    {
                        'value': 'prefeitura',
                        'text': 'PREFEITURA'
                    },
                    {
                        'value': 'camara_municipal',
                        'text': 'CÂMARA MUNICIPAL'
                    }
                ],
                periodicidades_rgf: [
                    {
                        'value': 'quadrimestral',
                        'text': 'QUADRIMESTRAL'
                    },
                    {
                        'value': 'semestral',
                        'text': 'SEMESTRAL'
                    }
                ],

                //OBJECTS
                uf: {},
                tipo: {},
                periodicidade_rgf: {},
                update_organizacao: {},
                update_organizacao_old: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalUpdateOrganizacao();
        },

        methods: {
            registryEventOpenModalUpdateOrganizacao(){
                eventBus.$on('eventOpenModalUpdateOrganizacao', (updateOrganizacao, currentPage) => {
                	this.errors = [];
                    this.setDataOrganizacao(updateOrganizacao);
                    this.currentPagePagination = currentPage;
                    $("#organizacao_update_modal").modal("show");
                });
            },

            setDataOrganizacao(organizacaoParam){
                this.update_organizacao = Object.assign({}, organizacaoParam);
                this.update_organizacao_old = Object.assign({}, organizacaoParam);

                this.uf = {
                    value: organizacaoParam.uf,
                    text: organizacaoParam.uf
                };

                let periodicidadeRgfText = 'QUADRIMESTRAL';
                let tipoText = 'PREFEITURA';

                if(organizacaoParam.periodicidade_rgf == 'semestral'){
                    periodicidadeRgfText = 'SEMESTRAL';
                }

                if(organizacaoParam.tipo == 'camara_municipal'){
                    tipoText = 'CÂMARA MUNICIPAL';  
                }
                
                this.periodicidade_rgf = {
                    value: organizacaoParam.periodicidade_rgf,
                    text: periodicidadeRgfText
                };

                this.tipo = {
                    value: organizacaoParam.tipo,
                    text: tipoText
                };
            },

            emitEventGetOrganizacoes(){
                eventBus.$emit('eventGetOrganizacoes', this.currentPagePagination);
            },

            //OUTROS METODOS
            onSelectUF(item) {
                this.uf = item;
                this.update_organizacao.uf = this.uf.value;
            },
            onSelectTipo(item) {
                this.tipo = item;
                this.update_organizacao.tipo = this.tipo.value;
            },
            onSelectPeriodicidadeRGF(item) {
                this.periodicidade_rgf = item;
                this.update_organizacao.periodicidade_rgf = this.periodicidade_rgf.value;
            },
            //FIM OUTROS METODOS

            checkForm:function(acao) {
                this.errors = [];

                if(this.update_organizacao.nome == this.update_organizacao_old.nome &&
                    this.update_organizacao.uf == this.update_organizacao_old.uf &&
                    this.update_organizacao.tipo == this.update_organizacao_old.tipo &&
                    this.update_organizacao.periodicidade_rgf == this.update_organizacao_old.periodicidade_rgf
                ) {
                    this.errors.push("Altere os dados");
                    return false;
                }

                if(this.update_organizacao.nome &&
                    this.update_organizacao.uf &&
                    this.update_organizacao.tipo &&
                    this.update_organizacao.periodicidade_rgf) {
                    return true;
                }

                if(!this.update_organizacao.nome) this.errors.push("Informe o nome");
                if(!this.update_organizacao.uf) this.errors.push("Selecione o UF");
                if(!this.update_organizacao.tipo) this.errors.push("Selecione o Tipo");
                if(!this.update_organizacao.periodicidade_rgf) this.errors.push("Selecione a periodicidade RGF");
            },

            updateOrganizacao()
            {
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.put('/organizacao/' + this.update_organizacao.id, this.update_organizacao)
                    .then(response => {

                        this.isLoading = false;
                        $("#organizacao_update_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetOrganizacoes();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            },
        }
    }
</script>