<template>
    <div class="modal fade" id="arquivo_digital_importacoes_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Detalhes</b>
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table table-bordered color-white">
                                <thead>
                                    <tr class="d-flex">
                                        <th style="width: 20%;" class="font-center"> Número Sequencial</th>
                                        <th style="width: 50%;" class="font-center"> Data da Importação </th>
                                        <th style="width: 20%;" class="font-center"> Competência </th>
                                        <th style="width: 10%;" class="font-center"> Ações </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="d-flex" v-for="(item, index) in importacoes">
                                        <td style="width: 20%;" class="font-center">
                                            {{  item.import_file_ref }}
                                        </td>
                                        <td style="width: 50%;" class="font-center">
                                            {{  item.created_at | formatDataTimeBr }}
                                        </td>
                                        <td style="width: 20%;" class="font-center">
                                            {{  item.competencia }}
                                        </td>
                                        <td style="width: 10%;" class="vertical-center-middle text-center">
                                            <div class="dropdown">
                                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-outline-primary">
                                                    <i class="mdi mdi-format-list-bulleted"></i>
                                                </button>
                                                <div class="dropdown-menu" 
                                                    x-placement="top-start" >
                                                    <a @click="initDelete(item)" class="dropdown-item">
                                                        Remover Registros
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    table {
      table-layout: fixed;
    }
    td {
        white-space: normal;
        word-wrap: break-word;
    }
    .modal-dialog {
        min-width: 900px;
    }
    .font-bold {
        font-weight: bold;
    }

    .font-center {
        text-align: center;
    }

    .font-dezoito {
        font-size: 18px;
    }
    .color-white {
        background-color: #fff;
    }

</style> 

<script>

    import moment from 'moment';
    import { eventBus } from '../../app';

    export default {

    	data(){
            return {
                //OBJECTS
                importacoes: []
            }
        },

        mounted(){
        	this.registryEventOpenModalImportacoesArquivoDigital();
        },

        filters: {
            formatDataTimeBr: function (date) {
                return moment(date).format('DD/MM/YYYY HH:mm');
            }
        },

        methods: {
            registryEventOpenModalImportacoesArquivoDigital(){
                eventBus.$on('eventOpenModalImportacoesArquivoDigital', () => {
                    this.getImportacoes();
                    $("#arquivo_digital_importacoes_modal").modal("show");
                });
            },
            emitEventOpenModalDeleteItensImportados(item){
                eventBus.$emit('eventOpenModalDeleteImportacoesArquivoDigital', item);
            },
            initDelete(item)
            {
                this.emitEventOpenModalDeleteItensImportados(item);
            },
            getImportacoes(){
                this.isLoading = true;
                    axios({
                        method: 'GET',
                        url: '/arquivodigital/importacoes'
                    })
                    .then(response => {
                        this.isLoading = false;
                        this.importacoes = [];
                        this.importacoes = response.data;
                    })
                    .catch(error => {
                        this.isLoading = false;
                        toastr.error('Falha ao listar dados.');
                        resolve([]);
                    });
            },
        }
    }
</script>