<template>
    <div class="modal fade" id="organizacao_competencia_arquivo_digital_delete_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Deletar</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            Deseja realmente DELETAR a competência?
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-danger"
                            @click.prevent.stop="deleteCompetencia">
                            Deletar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../../app';
    import Dot from '../../loadder/Dot.vue';

    export default {

    	components: {
            Dot
        },


    	data(){
            return {
                currentPagePagination: null,

            	isLoading: false,

                //ARRAYS
            	errors: [],

                //OBJECTS
                delete_competencia: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalDeleteCompetenciaArquivoDigitalOrganizacao();
        },

        methods: {
            registryEventOpenModalDeleteCompetenciaArquivoDigitalOrganizacao(){
                eventBus.$on('eventOpenModalDeleteCompetenciaArquivoDigitalOrganizacao', (deleteCompetencia, currentPage) => {
                	this.errors = [];
                    this.delete_competencia = Object.assign({}, deleteCompetencia);
                    this.currentPagePagination = currentPage;
                    $("#organizacao_competencia_arquivo_digital_delete_modal").modal("show");
                });
            },

            emitEventGetCompetenciasArquivoDigitalOrganizacao(){
                eventBus.$emit('eventGetCompetenciasArquivoDigitalOrganizacao', this.currentPagePagination);
            },

            deleteCompetencia()
            {
                this.isLoading = true;
                axios.delete(
                    '/organizacao/competenciaarquivodigital/' + this.delete_competencia.id
                )
                .then(response => {
                    this.isLoading = false;
                    $("#organizacao_competencia_arquivo_digital_delete_modal").modal("hide");
                    toastr.success(response.data.message);
                    this.emitEventGetCompetenciasArquivoDigitalOrganizacao();
                })
                .catch(error => {
                    this.isLoading = false;
                    let erroObject = error.response.data;
                    if(erroObject.message)
                    {
                        toastr.error(erroObject.message);
                    }
                });
            }

            
            
        }
    }
</script>