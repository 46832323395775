<template>
    <div class="modal fade" id="arquivo_digital_search_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Filtrar</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">

                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="row form-group">
                                <div class="col-lg-9">
                                    <label class="col-form-label" for="title">Tipo:</label>
                                    <basic-select :options="tipos"
                                            :selected-option="tipo"
                                            placeholder="Selecione o tipo"
                                            @select="onSelectTipo">
                                    </basic-select>
                                </div>
                                <div class="col-lg-3">
                                    <label class="col-form-label" for="title">Competência:</label>
                                    <input style="height: 44px;" 
                                        type="number" 
                                        placeholder="Ex: 2022" 
                                        v-model="competencia_search" 
                                        class="form-control" 
                                        disabled>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Descrição Resumida:</label>
                                <textarea
                                    class="form-control" 
                                    rows="4"
                                    v-model="descricao_resumida_search"
                                    placeholder="Descrição..."></textarea>
                            </div>

                            <div v-if="tipo_search == 'empenho'">
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Número do Empenho:</label>
                                        <the-mask 
                                        class="form-control"
                                        placeholder="12345678/2022"
                                        :mask="['########/####']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="empenho_numero_search"/>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Data Emissão do Empenho:</label>
                                        <the-mask 
                                        class="form-control"
                                        placeholder="01/01/2021"
                                        :mask="['##/##/####']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="empenho_data_emissao_search"/>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Credor:</label>
                                        <input type="text" placeholder="1 - Joao da Silva" v-model="empenho_credor_search" class="form-control">
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">CPF/CNPJ do Credor:</label>
                                        <the-mask 
                                        class="form-control"
                                        placeholder="123.456.789-09/12.345.678/0001-00"
                                        :mask="['###.###.###-##', '##.###.###/####-##']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="empenho_documento_credor_search"/>
                                    </div>
                                </div>
                            </div>
                            <div v-if="tipo_search == 'liquidacao'">
                                <div class="form-group">
                                    <label class="col-form-label" for="title">Empenho:</label>
                                    <basic-select :options="empenhos"
                                            :selected-option="empenho"
                                            placeholder="Selecione o empenho"
                                            @select="onSelectEmpenho">
                                    </basic-select>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-4">
                                        <label class="col-form-label" for="title">Data Vencimento:</label>
                                        <the-mask 
                                        class="form-control"
                                        placeholder="01/01/2021"
                                        :mask="['##/##/####']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="liquidacao_data_vencimento_search"/>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="col-form-label" for="title">Número Aviso de Liquidação:</label>
                                        <input 
                                        type="number" 
                                        placeholder="Número Aviso de Liquidação" 
                                        v-model="liquidacao_numero_aviso_search" 
                                        class="form-control">
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="col-form-label" for="title">Data de Emissão:</label>
                                        <the-mask 
                                        class="form-control"
                                        placeholder="01/01/2021"
                                        :mask="['##/##/####']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="liquidacao_data_emissao_search"/>
                                    </div>
                                </div>
                            </div>
                            <div v-if="tipo_search == 'pagamento'">
                                <div class="form-group">
                                    <label class="col-form-label" for="title">Liquidação:</label>
                                    <basic-select :options="liquidacoes"
                                            :selected-option="liquidacao"
                                            placeholder="Selecione a liquidação"
                                            @select="onSelectLiquidacao">
                                    </basic-select>
                                </div>
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Data de Movimentação da Liquidação:</label>
                                        <the-mask 
                                        class="form-control"
                                        placeholder="01/01/2021"
                                        :mask="['##/##/####']"
                                        :masked="configDateMask.maskedValue"
                                        v-model="pagamento_data_movimentacao_search"/>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Número de Sequência:</label>
                                        <input type="number" placeholder="Número de Sequência" v-model="pagamento_numero_sequencia_search" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div v-if="tipo_search == 'prestacao_contas'">
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Nome:</label>
                                        <input type="text" placeholder="Nome" v-model="prestacao_contas_nome_search" class="form-control">
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Ano:</label>
                                        <input type="number" placeholder="Ano" v-model="prestacao_contas_ano_search" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div v-if="tipo_search == 'licitacao'">
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Número da Licitação:</label>
                                        <input type="number" placeholder="Número da Licitação" v-model="licitacao_numero_search" class="form-control">
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Modalidade:</label>
                                        <basic-select :options="modalidades_licitacao"
                                                :selected-option="modalidade_licitacao"
                                                placeholder="Selecione a modalidade"
                                                @select="onSelectModalidade">
                                        </basic-select>
                                    </div>
                                </div>
                            </div>
                            <div v-if="tipo_search == 'setor_pessoal'">
                                <div class="row form-group">
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">Nome:</label>
                                        <input type="text" placeholder="Nome" v-model="setor_pessoal_nome_search" class="form-control">
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="col-form-label" for="title">CPF:</label>
                                        <the-mask
                                            class="form-control" 
                                            placeholder="012.456.789-00" 
                                            v-model="setor_pessoal_cpf_search"
                                            :mask="['###.###.###-##']"/> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                    	<div 
	                        v-if="(this.tipo_search.length > 0 ||
                                // this.competencia_search != (new Date()).getFullYear() ||
	                        this.descricao_resumida_search.length > 0 ||
                            this.empenho_numero_search.length > 0 ||
                            this.empenho_data_emissao_search.length > 0 ||
                            this.empenho_credor_search.length > 0 ||
                            this.empenho_documento_credor_search.length > 0 ||
                            this.liquidacao_data_vencimento_search.length > 0 ||
                            this.liquidacao_numero_aviso_search.length > 0 ||
                            this.liquidacao_data_emissao_search.length > 0 ||
                            this.pagamento_liquidacao_numero_aviso_search.length > 0 ||
                            this.pagamento_data_movimentacao_search.length > 0 ||
                            this.pagamento_numero_sequencia_search.length > 0 ||
                            this.prestacao_contas_nome_search.length > 0 ||
                            this.prestacao_contas_ano_search > 0 ||
                            this.licitacao_numero_search > 0 ||
                            this.setor_pessoal_nome_search.length > 0 ||
                            this.setor_pessoal_cpf_search.length > 0
                            )">
	                        <button type="button" @click.prevent.stop="limparFiltros" class="btn btn-danger pull-left">
	                            Limpar Filtros
	                        </button>
	                    </div>
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="filterArquivosDigital">
                            Filtrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .modal-dialog {
        min-width: 700px;
    }
</style> 

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { TheMask } from 'vue-the-mask';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            TheMask,
            BasicSelect
        },

    	data(){
            return {
                configDateMask: {
                    maskedValue: true
                },
            	isLoading: false,

                //ARRAYS
            	filtros: [],
                errors: [],
                tipos: [
                    {
                        'text': 'Empenho', 
                        'value': 'empenho'
                    },
                    {
                        'text': 'Liquidação', 
                        'value': 'liquidacao'
                    },
                    {
                        'text': 'Pagamento', 
                        'value': 'pagamento'
                    },
                    {
                        'text': 'Prestação de Contas', 
                        'value': 'prestacao_contas'
                    },
                    {
                        'text': 'Licitação', 
                        'value': 'licitacao'
                    },
                    {
                        'text': 'Setor Pessoal', 
                        'value': 'setor_pessoal'
                    },
                    {
                        'text': 'Outros', 
                        'value': 'outros'
                    }
                ],
                empenhos: [],
                liquidacoes: [],
                modalidades_licitacao: [
                    {
                        'text': 'Concorrência', 
                        'value': 'concorrencia'
                    },
                    {
                        'text': 'Pregão Presencial', 
                        'value': 'pregao_presencial'
                    },
                    {
                        'text': 'Pregão Eletrônico', 
                        'value': 'pregao_eletronico'
                    },
                    {
                        'text': 'Tomada de Preços', 
                        'value': 'tomada_precos'
                    },
                    {
                        'text': 'Dispensa Presencial', 
                        'value': 'dispensa_presencial'
                    },
                    {
                        'text': 'Dispensa Eletrônica', 
                        'value': 'dispensa_eletronica'
                    }
                ],

                //OBJECTS
                tipo: {},
                empenho: {},
                liquidacao: {},
                modalidade_licitacao: {},
                tipo_search: '',
                competencia_search: '',
                descricao_resumida_search: '',
                empenho_numero_search: '',
                empenho_data_emissao_search: '',
                empenho_credor_search: '',
                empenho_documento_credor_search: '',
                liquidacao_empenho_numero_search: '',
                liquidacao_data_vencimento_search: '',
                liquidacao_numero_aviso_search: '',
                liquidacao_data_emissao_search: '',
                pagamento_liquidacao_numero_aviso_search: '',
                pagamento_data_movimentacao_search: '',
                pagamento_numero_sequencia_search: '',
                prestacao_contas_nome_search: '',
                prestacao_contas_ano_search: '',
                licitacao_numero_search: '',
                licitacao_modalidade_search: '',
                setor_pessoal_nome_search: '',
                setor_pessoal_cpf_search: '',

                ano_competencia: null
            }
        },

        mounted(){
        	this.registryEventOpenModalSearchArquivoDigital();
        },

        methods: {
            registryEventOpenModalSearchArquivoDigital(){
                eventBus.$on('eventOpenModalSearchArquivoDigital', (filtrosParam, anoCompetencia) => {
                	this.filtros = filtrosParam;
                    this.ano_competencia = anoCompetencia;
                    this.checkClearFiltersForm();
                    $("#arquivo_digital_search_modal").modal("show");
                });
            },

            //EMIT EVENTS
            emitEventGetArquivosDigitalFilter(){
                eventBus.$emit('eventGetArquivosDigitalFilter', 
                    this.filtros
                );
            },
            //FIM EMIT EVENTS

            checkClearFiltersForm(){
                if(this.filtros.length <= 1){
                    this.clearFiltros();
                }
            },

            clearFiltros(){
                this.filtros = [];
                this.tipo = {};
                this.empenho = {};
                this.liquidacao = {};
                this.modalidade_licitacao = {};
                this.tipo_search = '';
                this.competencia_search = this.ano_competencia;
                this.descricao_resumida_search = '';
                this.empenho_numero_search = '';
                this.empenho_data_emissao_search = '';
                this.empenho_credor_search = '';
                this.empenho_documento_credor_search = '';
                this.liquidacao_empenho_numero_search = '';
                this.liquidacao_data_vencimento_search = '';
                this.liquidacao_numero_aviso_search = '';
                this.liquidacao_data_emissao_search = '';
                this.pagamento_liquidacao_numero_aviso_search = '';
                this.pagamento_data_movimentacao_search = '';
                this.pagamento_numero_sequencia_search = '';
                this.prestacao_contas_nome_search = '';
                this.prestacao_contas_ano_search = '';
                this.licitacao_numero_search = '';
                this.licitacao_modalidade_search = '';
                this.setor_pessoal_nome_search = '';
                this.setor_pessoal_cpf_search = '';
            },

            limparFiltros(){
                this.clearFiltros();
                this.filterArquivosDigital();
            },

            //OUTROS METODOS
            onSelectTipo(item) {
                this.tipo = item;
                this.tipo_search = this.tipo.value;
                if(this.tipo_search == 'liquidacao'){
                    this.getEmpenhos();
                }
                else if(this.tipo_search == 'pagamento'){
                    this.getLiquidacoes();
                }
            },
            onSelectEmpenho(item) {
                this.empenho = item;
                this.liquidacao_empenho_numero_search = this.empenho.value;
            },
            onSelectLiquidacao(item){
                this.liquidacao = item;
                this.pagamento_liquidacao_numero_aviso_search = this.liquidacao.value;
            },
            onSelectModalidade(item){
                this.modalidade_licitacao = item;
                this.licitacao_modalidade_search = this.modalidade_licitacao.value;
            },
            //FIM OUTROS METODOS

            validateData(data){
                var regex = "(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4}";
                var re = new RegExp(regex);
                return (re.test(data));
            },

            filterArquivosDigital(){
                var currentYear = (new Date()).getFullYear();
                this.errors = [];
                this.filtros = [];

                if (!this.competencia_search) {
                    this.errors.push("Ano de competência não informado.");
                    return false;
                }

                if (this.tipo_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'tipo',
                        'value': this.tipo_search
                    });
                }
                if (this.competencia_search && this.competencia_search > 0) {
                    var anoLocal = this.competencia_search;
                    var anoLocalString = anoLocal.toString();
                    if (anoLocalString.length < 4) {
                        this.errors.push("Informe uma competência válida.");
                        return false;
                    }
                    this.filtros.push({ 
                        'property': 'competencia',
                        'value': this.competencia_search
                    });
                }
                if (this.descricao_resumida_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'descricao_resumida',
                        'value': this.descricao_resumida_search
                    });
                }
                if (this.empenho_numero_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'empenho_numero',
                        'value': this.empenho_numero_search
                    });
                }
                if (this.empenho_data_emissao_search.length > 0) {
                    if(this.validateData(this.empenho_data_emissao_search) == false){
                        this.errors.push("Informe uma DATA de emissão do empenho válida");
                        return false;
                    }
                    this.filtros.push({ 
                        'property': 'empenho_data_emissao',
                        'value': this.empenho_data_emissao_search
                    });
                }
                if (this.empenho_credor_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'empenho_credor',
                        'value': this.empenho_credor_search
                    });
                }
                if (this.empenho_documento_credor_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'empenho_documento_credor',
                        'value': this.empenho_documento_credor_search
                    });
                }
                if (this.liquidacao_data_vencimento_search.length > 0) {
                    if(this.validateData(this.liquidacao_data_vencimento_search) == false){
                        this.errors.push("Informe uma DATA de vencimento da liquidação válida");
                        return false;
                    }
                    this.filtros.push({ 
                        'property': 'liquidacao_data_vencimento',
                        'value': this.liquidacao_data_vencimento_search
                    });
                }
                
                if (this.liquidacao_empenho_numero_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'liquidacao_empenho_numero',
                        'value': this.liquidacao_empenho_numero_search
                    });
                }
                if (this.liquidacao_numero_aviso_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'liquidacao_numero_aviso',
                        'value': this.liquidacao_numero_aviso_search
                    });
                }
                if (this.liquidacao_data_emissao_search.length > 0) {
                    if(this.validateData(this.liquidacao_data_emissao_search) == false){
                        this.errors.push("Informe uma DATA de emissão da liquidação válida");
                        return false;
                    }
                    this.filtros.push({ 
                        'property': 'liquidacao_data_emissao',
                        'value': this.liquidacao_data_emissao_search
                    });
                }
                if (this.pagamento_liquidacao_numero_aviso_search > 0) {
                    this.filtros.push({ 
                        'property': 'pagamento_liquidacao_numero_aviso',
                        'value': this.pagamento_liquidacao_numero_aviso_search
                    });
                }
                if (this.pagamento_data_movimentacao_search.length > 0) {
                    if(this.validateData(this.pagamento_data_movimentacao_search) == false){
                        this.errors.push("Informe uma DATA de movimentação do pagamento válida");
                        return false;
                    }
                    this.filtros.push({ 
                        'property': 'pagamento_data_movimentacao',
                        'value': this.pagamento_data_movimentacao_search
                    });
                }
                if (this.pagamento_numero_sequencia_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'pagamento_numero_sequencia',
                        'value': this.pagamento_numero_sequencia_search
                    });
                }
                if (this.prestacao_contas_nome_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'prestacao_contas_nome',
                        'value': this.prestacao_contas_nome_search
                    });
                }
                if (this.prestacao_contas_ano_search > 0) {
                    this.filtros.push({ 
                        'property': 'prestacao_contas_ano',
                        'value': this.prestacao_contas_ano_search
                    });
                }
                if (this.licitacao_numero_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'licitacao_numero',
                        'value': this.licitacao_numero_search
                    });
                }
                if (this.licitacao_modalidade_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'licitacao_modalidade',
                        'value': this.licitacao_modalidade_search
                    });
                }
                if (this.setor_pessoal_nome_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'setor_pessoal_nome',
                        'value': this.setor_pessoal_nome_search
                    });
                }
                if (this.setor_pessoal_cpf_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'setor_pessoal_cpf',
                        'value': this.setor_pessoal_cpf_search
                    });
                }

                $("#arquivo_digital_search_modal").modal("hide");
                this.emitEventGetArquivosDigitalFilter();
            },
            getEmpenhos(){
                var urlEmpenhos = '/arquivodigital/empenhos';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlEmpenhos
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.empenhos = [];
                    $.each(response.data, function (key, item) {
                        me.empenhos.push({
                            'value': item.empenho_numero,
                            'text': "Número: "+item.empenho_numero+". Credor: "+item.empenho_credor+". Valor: "+me.formatMoneyBr(item.empenho_valor)
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar empenhos');
                });
            },
            getLiquidacoes(){
                var urlLiquidacoes = '/arquivodigital/liquidacoes';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlLiquidacoes
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.liquidacoes = [];
                    $.each(response.data, function (key, item) {
                        me.liquidacoes.push({
                            'value': item.liquidacao_numero_aviso,
                            'text': "Liquidação Nº Empenho: "+item.liquidacao_empenho_numero+". Aviso de Liquidação: "+item.liquidacao_numero_aviso+". Valor: "+me.formatMoneyBr(item.liquidacao_valor)
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar liquidacoes');
                });
            },
            formatMoneyBr(valor){
                var formatter = new Intl.NumberFormat('pt-br', {
                  style: "currency",
                  currency: "BRL"
                });
                return formatter.format(valor);
            },
        }
    }
</script>