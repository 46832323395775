<template>
    <div>

        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="card card-statistics">
                    <div class="card-body">
                        <div class="d-flex flex-md-column flex-xl-row flex-wrap justify-content-between align-items-md-center justify-content-xl-between">
                            <div class="float-left">
                                <i class="mdi mdi-receipt text-warning icon-lg"></i>
                            </div>
                            <div class="float-right">
                                <p class="mb-0 text-right">Total de Demandas</p>
                                <div class="fluid-container">
                                    <h3 class="font-weight-medium text-right mb-0">
                                        {{ dashboard_data.demandas.total }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <p class="text-muted mt-3 mb-0 text-left text-md-center text-xl-left">
                            <i class="mdi mdi-alert-octagon mr-1" aria-hidden="true"></i> 
                            Total de Demandas no Sistema (Ano <b> {{ currentYear }}</b>)
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="card card-statistics">
                    <div class="card-body">
                        <div class="d-flex flex-md-column flex-xl-row flex-wrap justify-content-between align-items-md-center justify-content-xl-between">
                            <div class="float-left">
                                <i class="mdi mdi-cube text-warning icon-lg"></i>
                            </div>
                            <div class="float-right">
                                <p class="mb-0 text-right">Demandas Criadas</p>
                                <div class="fluid-container">
                                    <h3 class="font-weight-medium text-right mb-0">
                                        {{ dashboard_data.demandas.criada }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <p class="text-muted mt-3 mb-0 text-left text-md-center text-xl-left">
                            <i class="mdi mdi-reload mr-1" aria-hidden="true"></i>
                            Demandas Aguardando Recebimento no Setor Destino (Ano <b> {{ currentYear }}</b>)
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="card card-statistics">
                    <div class="card-body">
                        <div class="d-flex flex-md-column flex-xl-row flex-wrap justify-content-between align-items-md-center justify-content-xl-between">
                            <div class="float-left">
                                <i class="mdi mdi-cube text-warning icon-lg"></i>
                            </div>
                            <div class="float-right">
                                <p class="mb-0 text-right">Demandas em Andamento</p>
                                <div class="fluid-container">
                                    <h3 class="font-weight-medium text-right mb-0">
                                        {{ dashboard_data.demandas.em_andamento }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <p class="text-muted mt-3 mb-0 text-left text-md-center text-xl-left">
                            <i class="mdi mdi-reload mr-1" aria-hidden="true"></i>
                            Demandas Com Status Recebido ou Com Pendencias (Ano <b> {{ currentYear }}</b>)
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="card card-statistics">
                    <div class="card-body">
                        <div class="d-flex flex-md-column flex-xl-row flex-wrap justify-content-between align-items-md-center justify-content-xl-between">
                            <div class="float-left">
                                <i class="mdi mdi-cube text-danger icon-lg"></i>
                            </div>
                            <div class="float-right">
                                <p class="mb-0 text-right">Demandas em Atraso</p>
                                <div class="fluid-container">
                                    <h3 class="font-weight-medium text-right mb-0">
                                        {{ dashboard_data.demandas.em_atraso }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <p class="text-muted mt-3 mb-0 text-left text-md-center text-xl-left">
                            <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i>
                            Total de Demandas em Atraso (Ano <b> {{ currentYear }}</b>)
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="card card-statistics">
                    <div class="card-body">
                        <div class="d-flex flex-md-column flex-xl-row flex-wrap justify-content-between align-items-md-center justify-content-xl-between">
                            <div class="float-left" style="color: #008000;">
                                <i class="mdi mdi-briefcase-clock icon-lg"></i>
                            </div>
                            <div class="float-right">
                                <p class="mb-0 text-right">Demandas Concluídas</p>
                                <div class="fluid-container">
                                    <h3 class="font-weight-medium text-right mb-0">
                                        {{ dashboard_data.demandas.concluidas }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <p class="text-muted mt-3 mb-0 text-left text-md-center text-xl-left">
                            <i class="mdi mdi-calendar mr-1" aria-hidden="true"></i>
                            Total de Demandas Concluídas (Ano <b> {{ currentYear }}</b>)
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 grid-margin stretch-card">
                <div class="card card-statistics">
                    <div class="card-body">
                        <div class="d-flex flex-md-column flex-xl-row flex-wrap justify-content-between align-items-md-center justify-content-xl-between">
                            <div class="float-left" style="color: #008000;">
                                <i class="mdi mdi-briefcase-check icon-lg"></i>
                            </div>
                            <div class="float-right">
                                <p class="mb-0 text-right">Total de Demandas Atestadas</p>
                                <div class="fluid-container">
                                    <h3 class="font-weight-medium text-right mb-0">
                                        {{ dashboard_data.demandas.atestadas }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <p class="text-muted mt-3 mb-0 text-left text-md-center text-xl-left">
                            <i class="mdi mdi-alert-octagon mr-1" aria-hidden="true"></i> 
                            Total de Demandas Atestadas (Ano <b> {{ currentYear }}</b>)
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
           <div class="col-md-12 grid-margin">
              <div class="card">
                 <div class="card-body">
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                       <h2 class="card-title mb-0">Demandas Por Órgãos</h2>
                    </div>
                    <div>
                       <bar-chart></bar-chart>
                    </div>
                 </div>
              </div>
           </div>
        </div>

        <div class="row">
           <div class="col-md-12 grid-margin">
              <div class="card">
                 <div class="card-body">
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                       <h2 class="card-title mb-0">Demandas</h2>
                    </div>
                    <div>
                       <line-chart></line-chart>
                    </div>
                 </div>
              </div>
           </div>
        </div>

        <div class="row">
            <div class="col-lg-12 grid-margin">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Demandas e Classificação de Prazos</h4>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th> # </th>
                                        <th> Demandas </th>
                                        <th> Porcentagem </th>
                                        <th class="text-center"> Quantidade </th>
                                        <th class="text-center"> Data Inicio Prazo </th>
                                        <th class="text-center"> Data Fim Prazo </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="font-weight-medium"> 1 </td>
                                        <td> Demandas Urgentes </td>
                                        <td>
                                            <div class="progress">
                                                <div 
                                                class="progress-bar bg-danger progress-bar-striped" role="progressbar" 
                                                :style="{'width': ((dashboard_data.demandas_prazos.total_demandas_red/dashboard_data.demandas_prazos.total_demandas)*100)+'%'}" 
                                                aria-valuenow="25" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{ dashboard_data.demandas_prazos.total_demandas_red }}
                                        </td>
                                        <td class="text-center">
                                            <!-- {{ dateRedInit }} -->
                                            -
                                        </td>
                                        <td class="text-center text-danger">
                                            {{ dateRedFim }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="font-weight-medium"> 2 </td>
                                        <td> Demandas Intermediárias </td>
                                        <td>
                                            <div class="progress">
                                                <div 
                                                class="progress-bar bg-warning progress-bar-striped" role="progressbar" 
                                                :style="{'width': ((dashboard_data.demandas_prazos.total_demandas_yellow/dashboard_data.demandas_prazos.total_demandas)*100)+'%'}" 
                                                aria-valuenow="25" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{ dashboard_data.demandas_prazos.total_demandas_yellow }}
                                        </td>
                                        <td class="text-center">
                                            {{ dateYellowInit }}
                                        </td>
                                        <td class="text-center text-danger">
                                            {{ dateYellowFim }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="font-weight-medium"> 3 </td>
                                        <td> Demandas Com Mais Prazo </td>
                                        <td>
                                            <div class="progress">
                                                <div 
                                                class="progress-bar bg-success progress-bar-striped" role="progressbar" 
                                                :style="{'width': ((dashboard_data.demandas_prazos.total_demandas_green/dashboard_data.demandas_prazos.total_demandas)*100)+'%'}" 
                                                aria-valuenow="25" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{ dashboard_data.demandas_prazos.total_demandas_green }}
                                        </td>
                                        <td class="text-center">
                                            {{ dateGreenInit }}
                                        </td>
                                        <td class="text-center text-danger">
                                            {{ dateGreenFim }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="font-weight-medium"> 4 </td>
                                        <td> Total de Demandas </td>
                                        <td>
                                            <div class="progress">
                                                <div 
                                                class="progress-bar bg-primary progress-bar-striped" role="progressbar" 
                                                :style="{'width': '100%'}" 
                                                aria-valuenow="25" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{ dashboard_data.demandas_prazos.total_demandas }}
                                        </td>
                                        <td class="text-center">
                                            <!-- > {{ dateGreenFim }} -->
                                            ...
                                        </td>
                                        <td class="text-center">
                                            ...
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 
        <div class="row">
            <div class="col-md-6 col-xl-4 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Todo</h4>
                        <div class="add-items d-flex">
                            <input type="text" class="form-control todo-list-input" placeholder="What do you need to do today?">
                            <button class="add btn btn-primary font-weight-medium todo-list-add-btn">Add</button>
                        </div>
                        <div class="list-wrapper">
                            <ul class="d-flex flex-column-reverse todo-list todo-list-custom">
                                <li class="completed">
                                    <div class="form-check form-check-flat">
                                        <label class="form-check-label">
                                        <input class="checkbox" type="checkbox" checked> Call John </label>
                                    </div>
                                    <i class="remove mdi mdi-close-circle-outline"></i>
                                </li>
                                <li>
                                    <div class="form-check form-check-flat">
                                        <label class="form-check-label">
                                        <input class="checkbox" type="checkbox"> Create invoice </label>
                                    </div>
                                    <i class="remove mdi mdi-close-circle-outline"></i>
                                </li>
                                <li>
                                    <div class="form-check form-check-flat">
                                        <label class="form-check-label">
                                        <input class="checkbox" type="checkbox"> Print Statements </label>
                                    </div>
                                    <i class="remove mdi mdi-close-circle-outline"></i>
                                </li>
                                <li class="completed">
                                    <div class="form-check form-check-flat">
                                        <label class="form-check-label">
                                        <input class="checkbox" type="checkbox" checked> Prepare for presentation </label>
                                    </div>
                                    <i class="remove mdi mdi-close-circle-outline"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-4 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Schedules</h4>
                        <div class="shedule-list d-flex align-items-center justify-content-between mb-3">
                            <h3>27 Sep 2018</h3>
                            <small>21 Events</small>
                        </div>
                        <div class="event border-bottom py-3">
                            <p class="mb-2 font-weight-medium">Skype call with alex</p>
                            <div class="d-flex align-items-center">
                                <div class="badge badge-success">3:45 AM</div>
                                <small class="text-muted ml-2">London, UK</small>
                                <div class="image-grouped ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="event py-3 border-bottom">
                            <p class="mb-2 font-weight-medium">Data Analysing with team</p>
                            <div class="d-flex align-items-center">
                                <div class="badge badge-warning">12.30 AM</div>
                                <small class="text-muted ml-2">San Francisco, CA</small>
                                <div class="image-grouped ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="event py-3">
                            <p class="mb-2 font-weight-medium">Meeting with client</p>
                            <div class="d-flex align-items-center">
                                <div class="badge badge-danger">4.15 AM</div>
                                <small class="text-muted ml-2">San Diego, CA</small>
                                <div class="image-grouped ml-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-xl-4 grid-margin stretch-card">
                <div class="row flex-grow">
                    <div class="col-md-6 col-xl-12 grid-margin grid-margin-md-0 grid-margin-xl stretch-card">
                        <div class="card card-revenue">
                            <div class="card-body d-flex align-items-center">
                                <div class="d-flex flex-grow">
                                    <div class="mr-auto">
                                        <p class="highlight-text mb-0 text-white"> $168.90 </p>
                                        <p class="text-white"> This Month </p>
                                        <div class="badge badge-pill"> 18% </div>
                                    </div>
                                    <div class="ml-auto align-self-end">
                                        <div id="revenue-chart" sparkType="bar" sparkBarColor="#e6ecf5" barWidth="2"> 4,3,10,9,4,3,8,6,7,8 </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-12 stretch-card">
                        <div class="card card-revenue-table">
                            <div class="card-body">
                                <div class="revenue-item d-flex">
                                    <div class="revenue-desc">
                                        <h6>Member Profit</h6>
                                        <p class="font-weight-light"> Average Weekly Profit </p>
                                    </div>
                                    <div class="revenue-amount">
                                        <p class="text-primary"> +168.900 </p>
                                    </div>
                                </div>
                                <div class="revenue-item d-flex">
                                    <div class="revenue-desc">
                                        <h6>Total Profit</h6>
                                        <p class="font-weight-light"> Weekly Customer Orders </p>
                                    </div>
                                    <div class="revenue-amount">
                                        <p class="text-primary"> +6890.00 </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-5 d-flex align-items-center">
                                <canvas id="UsersDoughnutChart" class="400x160 mb-4 mb-md-0" height="200"></canvas>
                            </div>
                            <div class="col-md-7">
                                <h4 class="card-title font-weight-medium mb-0 d-none d-md-block">Active Users</h4>
                                <div class="wrapper mt-4">
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="d-flex align-items-center">
                                            <p class="mb-0 font-weight-medium">67,550</p>
                                            <small class="text-muted ml-2">Email account</small>
                                        </div>
                                        <p class="mb-0 font-weight-medium">80%</p>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar bg-primary" role="progressbar" style="width: 88%" aria-valuenow="88" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="wrapper mt-4">
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="d-flex align-items-center">
                                            <p class="mb-0 font-weight-medium">21,435</p>
                                            <small class="text-muted ml-2">Requests</small>
                                        </div>
                                        <p class="mb-0 font-weight-medium">34%</p>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar bg-success" role="progressbar" style="width: 34%" aria-valuenow="34" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-7">
                                <h4 class="card-title font-weight-medium mb-3">Amount Due</h4>
                                <h1 class="font-weight-medium mb-0">$5998</h1>
                                <p class="text-muted">Milestone Completed</p>
                                <p class="mb-0">Payment for next week</p>
                            </div>
                            <div class="col-md-5 d-flex align-items-end mt-4 mt-md-0">
                                <canvas id="conversionBarChart" height="150"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body py-5">
                        <div class="d-flex flex-row justify-content-center align-items">
                            <i class="mdi mdi-facebook text-facebook icon-lg"></i>
                            <div class="ml-3">
                                <h6 class="text-facebook font-weight-semibold mb-0">2.62 Subscribers</h6>
                                <p class="text-muted card-text">You main list growing</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body py-5">
                        <div class="d-flex flex-row justify-content-center align-items">
                            <i class="mdi mdi-google-plus text-google icon-lg"></i>
                            <div class="ml-3">
                                <h6 class="text-google font-weight-semibold mb-0">3.4k Followers</h6>
                                <p class="text-muted card-text">You main list growing</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body py-5">
                        <div class="d-flex flex-row justify-content-center align-items">
                            <i class="mdi mdi-twitter text-twitter icon-lg"></i>
                            <div class="ml-3">
                                <h6 class="text-twitter font-weight-semibold mb-0">3k followers</h6>
                                <p class="text-muted card-text">You main list growing</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<style type="text/css" scoped></style>

<script>
    import moment from 'moment';
    import { eventBus } from '../../app';
    import firebaseHelper from "../../firebaseManageToken";

    export default {
    
        components: {
        },
    
        data(){
            return {
                isLoading: false,
                currentYear: new Date().getFullYear(),

                // currentDate: new Date(),
                currentDate: moment().format('DD/MM/YYYY'),

                dateRedInit: moment().format('DD/MM/YYYY'),
                dateRedFim: moment().add(3, 'days').format('DD/MM/YYYY'),
                
                dateYellowInit: moment().add(4, 'days').format('DD/MM/YYYY'),
                dateYellowFim: moment().add(7, 'days').format('DD/MM/YYYY'),

                dateGreenInit: moment().add(8, 'days').format('DD/MM/YYYY'),
                dateGreenFim: moment().add(15, 'days').format('DD/MM/YYYY'),

                lineChartData: {
                    titulo: 'Demandas',
                    labels: [],
                    dados: []
                },
                barChartData: {
                    titulo: 'Demandas Por Setor',
                    labels: [],
                    dados: []
                },
    
                dashboard_data: {
                    demandas: {
                        total: 0,
                        em_atraso: 0,
                        concluidas: 0,
                        em_andamento: 0,
                        atestadas: 0,
                    },
                    quantidade_usuarios: 0,

                    demandas_prazos: {
                        total_demandas: 0,
                        total_demandas_red: 0,
                        total_demandas_yellow: 0,
                        total_demandas_green: 0,
                    }
                }
            }
        },
        mounted()
        {

            //CALL FUNCTION ACTIVE PUSH NOTIFICATION FIREBASE
            firebaseHelper.firebaseObject.methods.firebaseInit();

            this.getDataDashboard();

        },
        methods: {
            getDataDashboard(){
                var urlDataDashboard = 'getdatadashboard';
                this.filtros = [];

                axios({
                    method: 'GET',
                    url: urlDataDashboard
                })
                .then(response => {
                    let me = this;
                    let dashboardData = response.data;
                    me.dashboard_data = dashboardData;

                    // console.log(JSON.stringify(me.dashboard_data));
                    // console.log(JSON.stringify(me.dashboard_data.bar_chart_dashboard));

                    me.setDataBarChart(me.dashboard_data.bar_chart_dashboard);
                    me.setDataLineChart(me.dashboard_data.line_chart_dashboard);
                })
                .catch(error => {
                    toastr.error('Falha ao listar dados do dashboard!');
                });
            },

            setDataBarChart(barChartDataParam){
                let me = this;
                let demandasEmAndamento = [];
                let demandasEmAtraso = [];
                let demandasConcluidas = [];

                $.each(barChartDataParam, function (key, value) {
                    me.barChartData.labels.push(value.orgao_sigla);
                    demandasEmAndamento.push(parseInt(value.total_demandas_em_andamento));
                    demandasEmAtraso.push(parseInt(value.total_demandas_em_atraso));
                    demandasConcluidas.push(parseInt(value.total_demandas_concluidas));
                });

                me.barChartData.dados.push(
                    {
                        label: 'Demandas em Andamento',
                        data: demandasEmAndamento,
                        backgroundColor: "orange",
                        borderColor: "#ffaf00",
                        borderWidth: 1
                    },
                    {
                        label: 'Demandas Em Atraso',
                        data: demandasEmAtraso,
                        backgroundColor: "red",
                        borderColor: "#FF0017",
                        borderWidth: 1
                    },
                    {
                        label: 'Demandas Concluídas',
                        data: demandasConcluidas,
                        backgroundColor: "#008000",
                        borderColor: "#008000",
                        borderWidth: 1
                    }
                );

                eventBus.$emit('eventSetDataBarChart', me.barChartData);
            },

            setDataLineChart(lineChartDataParam){
                let me = this;
                let quantDemandas = [];
                let demandasAtestadasNoPrazo = [];
                let demandasAtestadasEmAtraso = [];

                $.each(lineChartDataParam, function (key, value) {
                    me.lineChartData.labels.push(value.mes_label);
                    quantDemandas.push(parseInt(value.quant_demandas));
                    demandasAtestadasNoPrazo.push(parseInt(value.demandas_atestadas_no_prazo));
                    demandasAtestadasEmAtraso.push(parseInt(value.demandas_atestadas_em_atraso));
                });

                me.lineChartData.dados.push(
                    {
                        label: "Total Demandas",
                        data: quantDemandas,
                        backgroundColor: "#2196f3",
                        borderColor: "#2196f3",
                        borderWidth: 1,
                        fill: true
                    },
                    {
                        label: "Atestadas No Prazo",
                        data: demandasAtestadasNoPrazo,
                        backgroundColor: "#008000",
                        borderColor: "#008000",
                        borderWidth: 1,
                        fill: true
                    },
                    {
                        label: "Atestadas Em Atraso",
                        data: demandasAtestadasEmAtraso,
                        backgroundColor: "red",
                        borderColor: "red",
                        borderWidth: 1,
                        fill: true
                    }
                );

                // me.barChartData.dados.push(
                //     {
                //         label: 'Demandas em Andamento',
                //         data: demandasEmAndamento,
                //         backgroundColor: "orange",
                //         borderColor: "#ffaf00",
                //         borderWidth: 1
                //     },
                //     {
                //         label: 'Demandas Em Atraso',
                //         data: demandasEmAtraso,
                //         backgroundColor: "red",
                //         borderColor: "#FF0017",
                //         borderWidth: 1
                //     },
                //     {
                //         label: 'Demandas Concluídas',
                //         data: demandasConcluidas,
                //         backgroundColor: "#008000",
                //         borderColor: "#0c83e2",
                //         borderWidth: 1
                //     }
                // );

                eventBus.$emit('eventSetDataLineChart', me.lineChartData);

            }
        }
    }
</script>