import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken, isSupported } from "firebase/messaging";

if ("Notification" in window && Notification.permission === "granted"){
    const firebaseConfig = {
        apiKey: process.env.MIX_FIREBASE_API_KEY,
        authDomain: process.env.MIX_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.MIX_FIREBASE_PROJECT_ID,
        storageBucket: process.env.MIX_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.MIX_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.MIX_FIREBASE_APP_ID,
        measurementId: process.env.MIX_FIREBASE_MEASUREMENT_ID
    };
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging();

    //OUVINDO NOTIFICAÇÃO
    onMessage(messaging, (payload) => {
         if(payload.notification){            
            toastr.options.closeButton = true;
            toastr.options.progressBar = true;
            if(payload?.data){
               let competencia = null;
               let numeroSequencia = null;
               for (const [key, value] of Object.entries(payload?.data)) {
                  let dataNotification = JSON.parse(value);
                  competencia = dataNotification?.competencia;
                  numeroSequencia = dataNotification?.numero_sequencia;
               }
               if(competencia && numeroSequencia){
                  let urlDestiny = window.location.origin+'/demanda/competencia/'+competencia;
                  toastr.options.onclick = function() { 
                     window.location.href = urlDestiny+'?numero_sequencia_url='+numeroSequencia;
                  }
               }               
            }
            toastr.info(payload.notification.body, payload.notification.title, {timeOut: 10000});
         }
    });
}

//REGISTRY serviceWorker
// navigator.serviceWorker.register("./firebase-messaging-sw.js").then((registration) => {
//     console.log('registration: ', registration);
//     // const messaging = firebase.messaging();
//     // messaging.useServiceWorker(registration);
// })
// .catch((err) => {
//     console.log('err: ', err);
// });
