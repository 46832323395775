<template>
    <div class="container">
        <div>
            <arquivodigital-search/>
            <arquivodigital-create/>
            <arquivodigital-update/>
            <arquivodigital-delete/>
            <arquivodigital-detalhes/>
            <arquivodigital-anexos-modal/>
            <arquivodigital-add-anexo-modal/>
            <arquivodigital-remove-anexo-modal/>
        </div>
        <div class="row remove-padding-left-right-grid">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card card-border-top">
                    <div class="card-body">
                        <h4 class="card-title">Arquivos Digitalizados</h4>
                        <p class="card-description text-right"> 
                            <button @click="initSearch()" type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-filter"></i>
                                {{ titleButtonFilter }}
                            </button>
                            <button @click="initAdd()"  type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-plus-circle"></i>Novo
                            </button>
                        </p>
                        <div class="table-responsive" style="min-height: 60vh;">
                            <table class="table table-hover">
                                <thead>
                                    <tr class="d-flex">
                                        <th :style="columns.one"> Descrição Resumida</th>
                                        <th :style="columns.two"> Tipo </th>
                                        <th :style="columns.three"> Data Cadastro</th>
                                        <th :style="columns.four" class="text-center"> Competência</th>
                                        <th :style="columns.five" class="text-center"> Ações </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="d-flex" v-for="(item, index) in arquivos_digital">
                                        <td :style="columns.one" class="vertical-center-middle">
                                            {{ item.descricao_resumida }}
                                        </td>
                                        <td :style="columns.two" class="vertical-center-middle"> 
                                            {{ item.tipo | formatTipo }}
                                        </td>
                                        <td :style="columns.three" class="vertical-center-middle"> 
                                            {{ item.created_at | formatDataBr }} 
                                        </td>
                                        <td :style="columns.four" class="vertical-center-middle text-center"> 
                                            {{ item.competencia }} 
                                        </td>
                                        <td :style="columns.five" class="vertical-center-middle text-center">
                                            <div class="dropdown">
                                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-outline-primary">
                                                    <i class="mdi mdi-format-list-bulleted"></i>
                                                </button>
                                                
                                                <div class="dropdown-menu" 
                                                    x-placement="top-start" >
                                                    <a @click="initUpdate(item)" class="dropdown-item">
                                                        Editar
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initDelete(item)" class="dropdown-item">
                                                        Deletar
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initAnexos(item)" class="dropdown-item">
                                                        Anexos
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initDetalhes(item)" class="dropdown-item">
                                                        Detalhes
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="arquivos_digital.length == 0">
                                        <td colspan="9" class="text-center">
                                            Nenhum item encontrado.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="mt-3">
                            <pagination :paginationData="pagination" @navigate="goToPage"></pagination>
                        </div>

                    </div>
                </div>
            </div>
        </div>
      
    </div>
</template>

<style type="text/css" scoped>
    .card-title {
        margin-bottom: 0px !important;
        font-size: 18px !important;
        padding-top: 16px !important;
        padding-left: 10px !important;
    }
    .card-description {
        margin-bottom: 8px !important;
    }
    .card-border-top {
        border-top: 3px solid #149AF9 !important;
    }
    .card-body {
        padding: 0px 10px !important;
    }
    .btn {
        padding: 7px 7px !important;
    }
    .vertical-center-middle {
        white-space:normal;
        text-align: justify;
        display: flex;
        align-items: center;
    }

    .text-center {
        justify-content: center
    }
    
    /*table thead tr th, table tbody tr td{
        font-size: 4px;
    }*/
    
    table tbody tr td{
        font-size: 13px;
    }
</style>

<script>

    import moment from 'moment';
    import { eventBus } from '../../app';
    import Pagination from '../paginacao/Pagination.vue';

    export default {

        components: {
            Pagination
        },

        data(){
            return {
                titleButtonFilter: '(1) Filtro',
                columns: {
                    one: 'width: 60%;',
                    two: 'width: 15%;',
                    three: 'width: 9%;',
                    four: 'width: 10%;',
                    five: 'width: 5%;'
                },
                
                isLoading: false,
                
                //ARRAYS
                arquivos_digital: [],

                //SEARCH DATA
                filtros: [],

                //PAGINATION
                pagination: {},


                competencia_url: null
            }
        },

        mounted() {
            this.registryEvents();

            var urlSplit = window.location.pathname.split('/');
            var competencia = parseInt(urlSplit[3]);
            if(!isNaN(competencia)){
                this.competencia_url = competencia;
                this.getArquivosDigital();
            }
            this.setTitleFilterButton();
        },

        filters: {
            formatDataBr: function (date) {
                if (date) {
                    return moment(date).format('DD/MM/YYYY');
                }
                return 'Não Informado';
            },
            formatTipo: function (tipoParam) {
                var allTipos = {
                    'empenho': 'Empenho',
                    'liquidacao': 'Liquidação',
                    'pagamento': 'Pagamento',
                    'prestacao_contas': 'Prestação de Contas',
                    'licitacao': 'Licitação',
                    'setor_pessoal': 'Setor Pessoal',
                    'outros': 'Outros'
                };
                return allTipos[tipoParam];
            }
        },

        methods: {
            //REGISTRY EVENTS
            registryEvents(){
                this.registryEventGetArquivosDigital();
                this.registryEventGetArquivosDigitalFilter();
            },
            registryEventGetArquivosDigital(){
                eventBus.$on('eventGetArquivosDigital', (page) => {
                    this.getArquivosDigital(page);
                });
            },
            registryEventGetArquivosDigitalFilter(){
                eventBus.$on('eventGetArquivosDigitalFilter', (filtrosEvent) => {
                    this.filtros = filtrosEvent;
                    this.setTitleFilterButton();
                    this.getArquivosDigital();
                });
            },
            //FIM REGISTRY EVENTS

            emitEventOpenModalSearch(){
                eventBus.$emit('eventOpenModalSearchArquivoDigital', 
                    this.filtros,
                    this.competencia_url
                );
            },
            emitEventOpenModalCreate(){
                eventBus.$emit('eventOpenModalCreateArquivoDigital', this.competencia_url);
            },
            emitEventOpenModalUpdate(item){
                eventBus.$emit('eventOpenModalUpdateArquivoDigital', 
                    Object.assign({}, item),
                    this.pagination.current_page
                );
            },
            emitEventOpenModalAnexos(item){
                eventBus.$emit('eventOpenModalAnexosArquivoDigital', 
                    item,
                    this.pagination.current_page
                );
            },
            emitEventOpenModalDetalhes(item){
                eventBus.$emit('eventOpenModalDetalhesArquivoDigital', 
                    item
                );
            },
            emitEventOpenModalDelete(item){
                eventBus.$emit('eventOpenModalDeleteArquivoDigital', 
                    item,
                    this.pagination.current_page
                );
            },
            initSearch(){
                this.emitEventOpenModalSearch();
            },
            initAdd()
            {
                this.emitEventOpenModalCreate();
            }, 
            initUpdate(item)
            {
                this.emitEventOpenModalUpdate(item);
            },
            initDelete(item)
            {
                this.emitEventOpenModalDelete(item);
            },
            initAnexos(item)
            {
                this.emitEventOpenModalAnexos(item);
            },
            initDetalhes(item){
                if(item.tipo == 'outros'){
                    toastr.error('Todas as informações do arquivo já estão listadas.');
                    return false;
                }
                this.emitEventOpenModalDetalhes(item);
            },

            openArquivoPdf(item){
                var urlPdf = '/arquivodigital/pdf?arquivo_digital_id='+item.id;

                this.isLoading = true;

                var child = window.open(urlPdf); 
                child.focus(); 

                this.isLoading = false;
            },

            getArquivosDigital(page)
            {
                var urlArquivosDigital = '/arquivodigital/listdata';

                this.isLoading = true;
                this.arquivos_digital = [];

                //POR PADRÃO OS ARQUIVOS SÃO LISTADOS PELA COMPETENCIA INFORMADA
                if (this.filtros.length == 0) {
                    this.filtros.push({ 
                        'property': 'competencia',
                        'value': this.competencia_url
                    });
                }

                axios({
                    method: 'GET',
                    url: urlArquivosDigital,
                    params: {
                        filters: this.filtros,
                        page: page //UTILIZADO NA PAGINAÇÃO
                    }
                })
                .then(response => {
                    this.arquivos_digital = response.data.arquivos_digital.data;
                    this.pagination = response.data.pagination;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao atualizar a pagina');
                });
            },
            goToPage: function (page)
            {
                this.pagination.current_page = page;
                this.getArquivosDigital(page);
            },
            setTitleFilterButton(){
                if(this.filtros.length > 0){
                    var textFilter = this.filtros.length > 1 ? 'Filtros' : 'Filtro';
                    this.titleButtonFilter = '('+this.filtros.length+') '+textFilter;
                }
                else {
                    this.titleButtonFilter = 'Filtrar'; 
                }
            },
        }
    }
</script>
