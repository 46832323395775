<template>
    <div>
        <canvas id="line-chart-dashboard" style="width: 984px; height: 262px;"></canvas>
    </div>
</template>

<script>
    import { eventBus } from '../../app';
    import Chart from 'chart.js';
    
    export default {

        data(){
            return {
                lineChartData: null,

                // dataGraph: {
                //     labels: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
                //     datasets: [
                //         {
                //             label: "Demanda Um",
                //             data: [3, 3, 8, 5, 7, 4, 6, 4, 6, 3],
                //             backgroundColor: "#2196f3",
                //             borderColor: "#0c83e2",
                //             borderWidth: 1,
                //             fill: true
                //         },
                //         {
                //             label: "Demanda Dois",
                //             data: [7, 5, 14, 7, 12, 6, 10, 6, 11, 5],
                //             backgroundColor: "#19d895",
                //             borderColor: "#15b67d",
                //             borderWidth: 1,
                //             fill: true
                //         }
                //     ]
                // },

                // optionsGraph: {
                //     responsive: true,
                //     maintainAspectRatio: true,
                //     scales: {
                //         yAxes: [
                //             {
                //                 gridLines: {
                //                     color: "#F2F6F9"
                //                 },
                //                 ticks: {
                //                     beginAtZero: true,
                //                     // min: 0,
                //                     // max: 20,
                //                     // stepSize: 10
                //                 }
                //             }
                //         ],
                //         xAxes: [
                //             {
                //                 gridLines: {
                //                     color: "#F2F6F9"
                //                 },
                //                 ticks: {
                //                     beginAtZero: true
                //                 }
                //             }
                //         ]
                //     },
                //     legend: {
                //         display: true
                //     },
                //     elements: {
                //         point: {
                //             radius: 2
                //         }
                //     },
                //     layout: {
                //         padding: {
                //             left: 0,
                //             right: 0,
                //             top: 0,
                //             bottom: 0
                //         }
                //     },
                //     stepsize: 1
                // }
            }
        },

        mounted () {

            let me = this;
            eventBus.$on('eventSetDataLineChart', (lineChartData) => {
                me.lineChartData = lineChartData;
                me.renderizarGrafico();
            });
        },
        
        methods: {
            renderizarGrafico: function () {
                let me = this;

                const ctx = document.getElementById('line-chart-dashboard');
                new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: me.lineChartData.labels,
                        datasets: me.lineChartData.dados                        
                    },
                    options: {
                        layout: {
                            padding: {
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: true,
                        scales: {
                            yAxes: [
                                {
                                    gridLines: {
                                        color: "#F2F6F9"
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        // min: 0,
                                        // max: 20,
                                        // stepSize: 10
                                    }
                                }
                            ],
                            xAxes: [
                                {
                                    gridLines: {
                                        color: "#F2F6F9"
                                    },
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }
                            ]
                        },
                        legend: {
                            display: true
                        },
                        elements: {
                            point: {
                                radius: 2
                            }
                        },
                        stepsize: 1

                    }
                });
            }
        }

        /*mounted () {
            // Overwriting base render method with actual data.
            this.renderChart({
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                datasets: [
                    {
                        label: 'Quantidade por Secretaria',
                        backgroundColor: '#4B94BF',
                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
                    }
                ]
            })
        }*/
    }
</script>