<template>
    <div class="modal fade" id="orgao_search_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Filtrar</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">

                            <div class="form-group">
                                <label class="col-form-label">Nome:</label>
                                <input type="text" placeholder="Nome do Órgão" v-model="nome_search" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Unidade Gestora:</label>
                                <basic-select :options="unidades_gestoras"
                                        :selected-option="unidade_gestora"
                                        placeholder="Selecione a Unidade Gestora"
                                        @select="onSelectUnidadeGestora">
                                </basic-select>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                    	<div 
	                        v-if="(this.nome_search.length > 0 || 
	                        this.unidade_gestora_id_search > 0 )">
	                        <button type="button" @click.prevent.stop="limparFiltros" class="btn btn-danger pull-left">
	                            Limpar Filtros
	                        </button>
	                    </div>
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="filterOrgao">
                            Filtrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { TheMask } from 'vue-the-mask';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            TheMask,
            BasicSelect
        },


    	data(){
            return {
                configDateMask: {
                    maskedValue: false
                },
            	isLoading: false,

                //ARRAYS
            	filtros: [],
                unidades_gestoras: [],

                //OBJECTS
                unidade_gestora: {},
                nome_search: '',
                unidade_gestora_id_search: null
            }
        },

        mounted(){
        	this.registryEventOpenModalSearchOrgao();
        },

        methods: {
            registryEventOpenModalSearchOrgao(){
                eventBus.$on('eventOpenModalSearchOrgao', (filtrosParam) => {
                	this.filtros = filtrosParam;
                    this.checkClearFiltersForm();
                    this.getUnidadesGestoras();
                    $("#orgao_search_modal").modal("show");
                });
            },

            //EMIT EVENTS
            emitEventGetOrgaosFilter(){
                eventBus.$emit('eventGetOrgaosFilter', 
                    this.filtros
                );
            },
            //FIM EMIT EVENTS

            //OUTROS METODOS
            onSelectUnidadeGestora(item) {
                this.unidade_gestora = item;
                this.unidade_gestora_id_search = this.unidade_gestora.value;
            },
            //FIM OUTROS METODOS

            checkClearFiltersForm(){
                if(this.filtros.length == 0){
                    this.clearFiltros();
                }
            },

            clearFiltros(){
                this.filtros = [];
                this.unidade_gestora = {};
                this.nome_search = '';
                this.unidade_gestora_id_search = null;
            },

            limparFiltros(){
                this.clearFiltros();
                this.filterOrgao();
            },

            //OUTROS METODOS
            onSelectMunicipio(item) {
                this.municipio = item;
                this.municipio_id_search = this.municipio.value;
            },
            //FIM OUTROS METODOS

            filterOrgao(){
                this.filtros = [];
                
                if (this.nome_search.length > 0) {
                    this.filtros.push({ 
                        'property': 'nome',
                        'value': this.nome_search
                    });
                }

                if (this.unidade_gestora_id_search > 0) {
                    this.filtros.push({ 
                        'property': 'unidade_gestora_id',
                        'value': this.unidade_gestora_id_search
                    });
                }

                $("#orgao_search_modal").modal("hide");
                this.emitEventGetOrgaosFilter();
            },
            getUnidadesGestoras(){

                var urlUnidadesGestoras = '/unidadegestora/combo';
                
                this.isLoading = true;
                
                axios({
                    method: 'GET',
                    url: urlUnidadesGestoras
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.unidades_gestoras = [];
                    $.each(response.data, function (key, value) {
                        me.unidades_gestoras.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar unidades gestoras');
                });
            },
        }
    }
</script>