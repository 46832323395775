<!-- Pagination -->
<template>
	<nav>
		<ul class="pagination d-flex justify-content-end">
			<li class="page-item">
                <a 
                	href="#" 
                	@click.prevent.stop="prevNextPage(pagination.current_page - 1)" 
                	class="page-link">
                    <i class="mdi mdi-chevron-left"></i>
                </a>
            </li>
	        
	        <li 
	        	class="page-item" 
	        	v-for="page in pagesNumber" 
	        	:class="[ page == isActived ? 'active' : '']">
	            <a href="#" class="page-link" @click.prevent.stop="changePage(page)">
	                {{ page }}
	            </a>
	        </li>

	        <li class="page-item">
                <a 
                	href="#" 
                	class="page-link"
                	@click.prevent.stop="prevNextPage(pagination.current_page + 1)">
                    <i class="mdi mdi-chevron-right"></i>
                </a>
            </li>
	    </ul>
	</nav>
</template>

<script>
    export default {
    	
    	props: ['paginationData'],

        data(){
            return {
                pagination: {
			        total: 0, 
			        current_page: 0,
			        per_page: 0,
			        last_page: 0,
			        from: 0, 
			        to: 0
		      	},
		      	offset: 2
            }
        },

        watch: {
	        paginationData: function(newVal) { 
	           this.pagination = newVal;
	        }
      	},

        computed: {
            isActived: function(){
                return this.pagination.current_page;
            },
            pagesNumber: function(){
                if(!this.pagination.to){
                    return [];
                }

                var from = this.pagination.current_page - this.offset;
                
                if(from < 1){
                    from = 1;
                }

                var to = from + (this.offset * 2);

                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }

                var pagesArray = [];

                while (from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;
            }
        },

        methods: {
            changePage: function (page)
            {
            	this.$emit('navigate', page);
            },
            prevNextPage: function (page) 
            {
                if (page == 0 || page >= this.pagination.last_page+1 ) {
                    return false;
                }
                this.changePage(page);
            }

        }
    }
</script>