<template>
    <div class="modal fade" id="unidade_gestora_update_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Atualizar</b>
                        </h5>
                        <!-- <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Nome:</label>
                                <input type="text" v-model="update_unidade_gestora.nome" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">CNPJ:</label>
                                <the-mask 
                                    class="form-control"
                                    placeholder="12.345.678/0001-99"
                                    :mask="['##.###.###/####-##']"
                                    :masked="configDateMask.maskedValue"
                                    v-model="update_unidade_gestora.cnpj"/>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Endereço:</label>
                                <input type="text" placeholder="Rua XV de Maio" v-model="update_unidade_gestora.endereco" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Resposável:</label>
                                <input type="text" placeholder="Nome do Responsável" v-model="update_unidade_gestora.responsavel" class="form-control">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Município: *</label>
                                <basic-select :options="municipios"
                                        :selected-option="municipio"
                                        placeholder="Selecione o Município"
                                        @select="onSelectMunicipio">
                                </basic-select>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="updateUnidadeGestora">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { TheMask } from 'vue-the-mask';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            TheMask,
            BasicSelect
        },


    	data(){
            return {
                configDateMask: {
                    maskedValue: false
                },
                currentPagePagination: null,

            	isLoading: false,

                //ARRAYS
            	errors: [],
                municipios: [],

                //OBJECTS
                municipio: {},
                update_unidade_gestora: {},
                update_unidade_gestora_old: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalUpdateUnidadeGestora();
        },

        methods: {
            registryEventOpenModalUpdateUnidadeGestora(){
                eventBus.$on('eventOpenModalUpdateUnidadeGestora', (updateUnidadeGestora, currentPage) => {
                	this.errors = [];
                    this.currentPagePagination = currentPage;
                    $("#unidade_gestora_update_modal").modal("show");
                    this.setDataUnidadeGestora(updateUnidadeGestora);
                    this.getOrganizacoes();
                });
            },

            setDataUnidadeGestora(unidadeGestoraParam){
                this.update_unidade_gestora = Object.assign({}, unidadeGestoraParam);
                this.update_unidade_gestora_old = Object.assign({}, unidadeGestoraParam);

                this.municipio = {
                    value: unidadeGestoraParam.organizacao_id,
                    text: unidadeGestoraParam.organizacao_nome
                };
            },

            emitEventGetUnidadesGestoras(){
                eventBus.$emit('eventGetUnidadesGestoras', this.currentPagePagination);
            },

            //OUTROS METODOS
            onSelectMunicipio(item) {
                this.municipio = item;
                this.update_unidade_gestora.organizacao_id = this.municipio.value;
            },
            //FIM OUTROS METODOS

            checkForm:function(acao) {
                this.errors = [];

                if(this.update_unidade_gestora.nome == this.update_unidade_gestora_old.nome &&
                    this.update_unidade_gestora.cnpj == this.update_unidade_gestora_old.cnpj &&
                    this.update_unidade_gestora.endereco == this.update_unidade_gestora_old.endereco &&
                    this.update_unidade_gestora.responsavel == this.update_unidade_gestora_old.responsavel &&
                    this.update_unidade_gestora.organizacao_id == this.update_unidade_gestora_old.organizacao_id
                ) {
                    this.errors.push("Altere os dados");
                    return false;
                }

                if(this.update_unidade_gestora.nome &&
                    this.update_unidade_gestora.cnpj &&
                    this.update_unidade_gestora.endereco &&
                    this.update_unidade_gestora.responsavel &&
                    this.update_unidade_gestora.organizacao_id
                ) {
                    return true;
                }
                
                if(!this.update_unidade_gestora.nome) this.errors.push("Informe o nome");
                if(!this.update_unidade_gestora.cnpj) this.errors.push("Informe o CNPJ");
                if(!this.update_unidade_gestora.endereco) this.errors.push("Informe o endereço");
                if(!this.update_unidade_gestora.responsavel) this.errors.push("Informe o responsável");
                if(!this.update_unidade_gestora.organizacao_id) this.errors.push("Selecione o município");
            },

            updateUnidadeGestora()
            {
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.put('/unidadegestora/' + this.update_unidade_gestora.id, this.update_unidade_gestora)
                    .then(response => {

                        this.isLoading = false;
                        $("#unidade_gestora_update_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetUnidadesGestoras();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            },

            getOrganizacoes(){

                var urlMunicipios = '/organizacao/combo';
                
                this.isLoading = true;
                
                axios({
                    method: 'GET',
                    url: urlMunicipios
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.municipios = [];
                    $.each(response.data, function (key, value) {
                        me.municipios.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar municípios');
                });
            },
        }
    }
</script>