<template>
    <div class="modal fade" id="update_usuario_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>{{ modalTitle }}</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">

                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
	                            <label class="col-form-label">Nome:</label>
	                            <input type="text" v-model="usuario_update_dados.name" class="form-control" autofocus>
	                        </div>

	                        <div class="form-group">
	                            <label class="col-form-label">Email:</label>
	                            <input type="email" v-model="usuario_update_dados.email" class="form-control">
	                        </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="updateDados">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';

    export default {

    	components: {
            Dot,
        },


    	data(){
            return {
            	// updateMeusDados: false,
                errors: [],
                modalTitle: '',
                isLoading: false,
                usuario_update_dados: {
                    id: null,
                    name: null,
                    email: null
                },

                usuario_update_dados_old: {
                    id: null,
                    name: null,
                    email: null
                }
            }
        },

        mounted(){
        	var me = this;
            // $('#btn-meus-dados').on('click', function() {
            //     me.updateMeusDados = true;
            //     me.clearDados();
            //     me.setModalTitle('Meus Dados');
            //     $("#update_usuario_modal").modal("show");
            //     me.getMeusDados(me);
            // });

            this.registryEvents();
        },

        methods: {
            registryEvents(){
                this.registryEventUpdateDadosUsuario();
            },
            registryEventUpdateDadosUsuario(){
                eventBus.$on('eventUpdateDadosUsuario', (usuario) => {
                    // this.updateMeusDados = false;
                    this.clearDados();
                    this.setModalTitle('Atualizar Dados');
                    $("#update_usuario_modal").modal("show");
                    this.usuario_update_dados = {   
                        id: usuario.id,
                        name: usuario.name,
                        email: usuario.email
                    };

                    this.usuario_update_dados_old = {
                        id: usuario.id,
                        name: usuario.name,
                        email: usuario.email
                    };
                });
            },

            setModalTitle(titulo){
                this.modalTitle = titulo;
            },
            clearDados()
            {
                this.errors = [];
                this.usuario_update_dados = {
                    id: null,
                    name: null,
                    email: null
                }
            },
            
            getMeusDados(contexto){
                contexto.isLoading = true;
                axios.get('/usuario/meusdados').then(function (response) {
                    contexto.isLoading = false;
                    var usuarioData = response.data;
                    
                    if (usuarioData.id > 0) {
                        contexto.usuario_update_dados = {
                            name: usuarioData.name,
                            email: usuarioData.email
                        };

                        contexto.usuario_update_dados_old = {
                            name: usuarioData.name,
                            email: usuarioData.email
                        };
                    }
                    else {
                        toastr.error('Falha ao carregar dados');
                    }
                })
                .catch(function (error) {
                    contexto.isLoading = false;
                    toastr.error('Falha ao carregar dados');
                });
            },
            //FIM LISTAGEM

            checkForm:function() {

                if(this.usuario_update_dados.name == this.usuario_update_dados_old.name && 
                    this.usuario_update_dados.email == this.usuario_update_dados_old.email) {
                    this.errors.push("Altere os dados do Usuario");
                    return false;
                }

                if(this.usuario_update_dados.name && 
                    this.usuario_update_dados.email) {
                    return true;
                }

                this.errors = [];
                if(!this.usuario_update_dados.name) this.errors.push("Informe o nome");
                if(!this.usuario_update_dados.email) this.errors.push("Informe o email");
            },
            updateDados()
            {
                var urlUpdateDados = '/usuario/update/meusdados';

                //SE USUARIO TIVER ID, ESTOU CLICANDO NA GRID
                if (this.usuario_update_dados.id > 0) {
                    urlUpdateDados = '/usuario/update/'+this.usuario_update_dados.id;
                }
                
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.put(urlUpdateDados, this.usuario_update_dados)
                    .then(response => {
                        this.isLoading = false;
                        $("#update_usuario_modal").modal("hide");
                        toastr.success(response.data.message);
                        location.reload();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            }
           
        }
    }
</script>