<template>
    <div class="modal fade" id="demanda_historico_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Histórico</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 
                        <div v-if="historico.length > 0">
                            <div v-for="(item, index) in historico">
                                <div class="alert alert-success">
                                    <ul>
                                        <li><b>Usuário:</b> {{ item.user.email }}</li>
                                        <li><b>Data:</b> {{ item.date | formatDataBr }}</li>
                                        <li><b>Origem:</b> {{ item.data.setor_origem }}</li>
                                        <li><b>Destino:</b> {{ item.data.setor_destino }}</li>
                                        <li>
                                            <b>Status:</b> {{ item.data.status | formatStatus }}
                                        </li>
                                        <li v-if="item.data.observacao">
                                            <b>Observação:</b> {{ item.data.observacao }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="gerarHistoricoPdf">
                            Gerar Histórico
                        </button>
                        <button type="button" class="btn btn-light" data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import moment from 'moment';
    import { eventBus } from '../../app';

    export default {

    	data(){
            return {
                //OBJECTS
                demanda: {},
                historico: []
            }
        },

        mounted(){
        	this.registryEventOpenModalHistoricoDemanda();
        },

        filters: {
            formatDataBr: function (date) {
                return moment(date).format('DD/MM/YYYY HH:mm');
            },
            formatStatus: function (param) {
                let tiposStatus = {
                    'criado': 'CRIADO',
                    'recebido': 'RECEBIDO',
                    'concluido': 'CONCLUÍDO',
                    'com_pendencia': 'COM PENDÊNCIA',
                    'atestado': 'ATESTADO'
                };
                return tiposStatus[param];
            },
        },

        methods: {
            registryEventOpenModalHistoricoDemanda(){
                eventBus.$on('eventOpenModalHistoricoDemanda', (demandaParam) => {
                    this.demanda = Object.assign({}, demandaParam);
                    this.historico = JSON.parse(this.demanda.historico);
                    $("#demanda_historico_modal").modal("show");
                });
            },
            gerarHistoricoPdf()
            {
                if(!this.demanda.id){
                    toastr.error('Identifique a demanda');
                    return false;
                }
                var urlHistoricoPdf = '/demanda/historicopdf?demanda_id='+this.demanda.id;
                this.isLoading = true;
                var child = window.open(urlHistoricoPdf); 
                child.focus(); 

                this.isLoading = false;
            },
        }
    }
</script>