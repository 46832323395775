<template>
    <div class="modal fade" id="demanda_update_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Atualizar</b>
                        </h5>
                    </div>
                    <div class="modal-body"> 

                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Setor Origem:</label>
                                <!-- <basic-select :options="setores"
                                        :selected-option="setor_origem"
                                        placeholder="Selecione o setor de origem"
                                        @select="onSelectSetorOrigem">
                                </basic-select> -->
                                <input type="text" class="form-control" v-model="setor_origem.text" readonly>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Setor Destino:</label>
                                <!-- <basic-select :options="setores"
                                        :selected-option="setor_destino"
                                        placeholder="Selecione o setor de destino"
                                        @select="onSelectSetorDestino"
                                        :isDisabled="true">
                                </basic-select> -->
                                <input type="text" class="form-control" v-model="setor_destino.text" readonly>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Tipo:</label>
                                <basic-select :options="tipos"
                                        :selected-option="tipo"
                                        placeholder="Selecione o tipo"
                                        @select="onSelectTipo">
                                </basic-select>
                            </div>

                            <div class="form-group">
                                <label class="col-form-label" for="title">Objetivo:</label>
                                <textarea 
                                    class="form-control" 
                                    rows="4"
                                    v-model="update_demanda.objetivo"
                                    placeholder="Objetivo..."></textarea>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success"
                            @click.prevent.stop="updateDemanda">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
	import { eventBus } from '../../app';
    import Dot from '../loadder/Dot.vue';
    import { BasicSelect } from 'vue-search-select';

    export default {

    	components: {
            Dot,
            BasicSelect
        },


    	data(){
            return {
                currentPagePagination: null,

            	isLoading: false,

                //ARRAYS
            	errors: [],
                setores: [],
                tipos: [],

                //OBJECTS
                demanda: {},
                setor_origem: {},
                setor_destino: {},
                tipo: {},
                update_demanda: {},
                update_demanda_old: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalUpdateDemanda();
        },

        methods: {
            registryEventOpenModalUpdateDemanda(){
                eventBus.$on('eventOpenModalUpdateDemanda', (updateDemanda, currentPage) => {
                	this.errors = [];
                    this.currentPagePagination = currentPage;
                    this.demanda = updateDemanda;
                    this.getSetores();
                    this.getTiposDemanda();
                    $("#demanda_update_modal").modal("show");
                });
            },

            setDataDemanda(demandaParam){
                this.update_demanda = Object.assign({}, demandaParam);
                this.update_demanda_old = Object.assign({}, demandaParam);

                this.setor_origem = {
                    value: demandaParam.setor_origem_id,
                    text: demandaParam.setor_origem_nome
                };

                this.setor_destino = {
                    value: demandaParam.setor_destino_id,
                    text: demandaParam.setor_destino_nome
                };
                this.tipo = {
                    value: demandaParam.tipo,
                    text: this.formatTipo(demandaParam.tipo)
                };
            },

            formatTipo(param) {
                let me = this;
                let indexTipoDemanda = me.tipos.findIndex(e => e.value === param);
                return (indexTipoDemanda >= 0) ? me.tipos[indexTipoDemanda].text : '-';
            },

            //OUTROS METODOS
            onSelectSetorOrigem(item) {
                this.setor_origem = item;
                this.update_demanda.setor_origem_id = this.setor_origem.value;
            },
            onSelectSetorDestino(item) {
                this.setor_destino = item;
                this.update_demanda.setor_destino_id = this.setor_destino.value;
            },
            onSelectTipo(item) {
                this.tipo = item;
                this.update_demanda.tipo = this.tipo.value;
            },
            //FIM OUTROS METODOS

            emitEventGetDemandas(){
                eventBus.$emit('eventGetDemandas', this.currentPagePagination);
            },

            checkForm:function(acao) {
                this.errors = [];

                if(this.update_demanda.setor_origem_id == this.update_demanda_old.setor_origem_id &&
                    this.update_demanda.setor_destino_id == this.update_demanda_old.setor_destino_id &&
                    this.update_demanda.objetivo == this.update_demanda_old.objetivo &&
                    this.update_demanda.tipo == this.update_demanda_old.tipo
                ) {
                    this.errors.push("Altere os dados");
                    return false;
                }

                if(this.update_demanda.setor_origem_id &&
                    this.update_demanda.setor_destino_id &&
                    this.update_demanda.objetivo &&
                    this.update_demanda.tipo
                ) {
                    return true;
                }
                
                if(!this.update_demanda.setor_origem_id) this.errors.push("Selecione o setor de origem");
                if(!this.update_demanda.setor_destino_id) this.errors.push("Selecione o setor de destino");
                if(!this.update_demanda.objetivo) this.errors.push("Informe o objetivo");
                if(!this.update_demanda.tipo) this.errors.push("Selecione o tipo");
            },

            updateDemanda()
            {
                if(this.checkForm()){
                    this.isLoading = true;
                    axios.put('/demanda/' + this.update_demanda.id, this.update_demanda)
                    .then(response => {
                        this.isLoading = false;
                        $("#demanda_update_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetDemandas();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
                }
            },
            getSetores(){
                var urlSetores = '/setor/combo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlSetores
                })
                .then(response => {
                    let me = this;
                    this.isLoading = false;
                    this.setores = [];
                    $.each(response.data, function (key, value) {
                        me.setores.push({
                            'value': value.id,
                            'text': value.nome
                        });
                    });
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar setores');
                });
            },
            getTiposDemanda(){
                var urlTiposDemanda = '/demanda/tipo';
                this.isLoading = true;
                axios({
                    method: 'GET',
                    url: urlTiposDemanda
                })
                .then(response => {
                    let me = this;
                    me.isLoading = false;
                    me.tipos = [];
                    $.each(response.data, function (key, item) {
                        me.tipos.push({
                            'value': item.value,
                            'text': item.text
                        });
                    });
                    me.setDataDemanda(me.demanda);
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao listar tipos de demanda');
                });
            },
        }
    }
</script>