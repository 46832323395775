<template>
    <div class="modal fade" id="arquivo_digital_update_data_anexo_modal" data-backdrop="static">
        <div class="modal demo-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Editar
                        </h5>
                    </div>
                    <div class="modal-body"> 
                        <dot :isVisible="isLoading"></dot>

                        <div v-if="!isLoading">
                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="col-form-label" for="title">Data de Pagamento:</label>
                            <the-mask 
                            class="form-control"
                            placeholder="01/01/2021"
                            :mask="['##/##/####']"
                            :masked="configDateMask.maskedValue"
                            v-model="arquivo_digital_anexo.data_pagamento"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button 
                            class="btn btn-success"
                            type="button" 
                            @click.prevent.stop="updateDataAnexoArquivoDigital">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
	import { eventBus } from '../../../app';
    import Dot from '../../loadder/Dot.vue';
    import { TheMask } from 'vue-the-mask';

    export default {

    	components: {
            Dot,
            TheMask
        },

    	data(){
            return {
                configDateMask: {
                    maskedValue: true
                },
            	isLoading: false,

                errors: [],

                arquivo_digital_anexo: {}
            }
        },

        mounted(){
        	this.registryEventOpenModalUpdateDataAnexoArquivoDigital();
        },

        methods: {
            emitEventGetAnexosArquivoDigital(){
                eventBus.$emit('eventGetAnexosArquivoDigital');
            },
            registryEventOpenModalUpdateDataAnexoArquivoDigital(){
                eventBus.$on('eventOpenModalUpdateDataAnexoArquivoDigital', (arquivoDigitalAnexoParam) => {
                    this.arquivo_digital_anexo = Object.assign({}, arquivoDigitalAnexoParam);;
                    this.errors = [];
                    if(this.arquivo_digital_anexo.data_pagamento){
                        var dataPagamentoLocal = moment(this.arquivo_digital_anexo.data_pagamento).format('DD/MM/YYYY');
                        this.arquivo_digital_anexo.data_pagamento = dataPagamentoLocal;
                    }
                    $("#arquivo_digital_update_data_anexo_modal").modal("show");
                });
            },

            validateData(data){
                var regex = "(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4}";
                var re = new RegExp(regex);
                return (re.test(data));
            },

            updateDataAnexoArquivoDigital(){
                if (!this.arquivo_digital_anexo.id) {
                    toastr.error('Anexo não identificado');
                    return false;
                }

                if(!this.arquivo_digital_anexo.data_pagamento || (this.validateData(this.arquivo_digital_anexo.data_pagamento) == false)){
                    this.errors.push('Informe uma data de pagamento válida.');
                    return false;
                }

                this.isLoading = true;
                    axios.put('/arquivodigital/anexos/' + this.arquivo_digital_anexo.id, this.arquivo_digital_anexo)
                    .then(response => {
                        this.isLoading = false;
                        $("#arquivo_digital_update_data_anexo_modal").modal("hide");
                        toastr.success(response.data.message);
                        this.emitEventGetAnexosArquivoDigital();
                    })
                    .catch(error => {
                        let me = this;
                        this.isLoading = false;
                        let erroObject = error.response.data;
                        this.errors = [];
                        if( error.response.status === 422 ){
                            if (erroObject.errors) {
                                $.each(erroObject.errors, function (key, value) {
                                    me.errors.push(value[0]);
                                });
                            }
                        }
                        else {
                            toastr.error(erroObject.message);
                        }
                    });
            }
        }
    }
</script>