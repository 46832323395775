import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken, isSupported } from "firebase/messaging";
import axios from "axios";

//1 - TA SENDO CHAMADO NO ARQUIVO DASHBOARD.VUE, ONDE REGISTRA O TOKEN
//2 -TEM UM LISTENER QUE ESCUTA PUSH's NO APP.JS

let firebaseObject = {
    methods: {
        firebaseInit: function() {

            let msgNotifyPermission = 'As configurações de notificações estão desabilitadas no seu navegador.';
            Notification.requestPermission().then((permission)=>{
                if(permission === 'denied' || permission === 'default'){
                    toastr.error(msgNotifyPermission);
                }
            });

            const firebaseConfig = {
                apiKey: process.env.MIX_FIREBASE_API_KEY,
                authDomain: process.env.MIX_FIREBASE_AUTH_DOMAIN,
                projectId: process.env.MIX_FIREBASE_PROJECT_ID,
                storageBucket: process.env.MIX_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: process.env.MIX_FIREBASE_MESSAGING_SENDER_ID,
                appId: process.env.MIX_FIREBASE_APP_ID,
                measurementId: process.env.MIX_FIREBASE_MEASUREMENT_ID
            };

            //SÓ É PERMITIDO SE O BROWSER ACEITAR PUSH NOTIFICATIONS
            if ("Notification" in window && Notification.permission === "granted"){
                initializeApp(firebaseConfig);
                const messaging = getMessaging();
                const keyApi = process.env.MIX_FIREBASE_WEB_PUSH_NOTIFICATION_PAIR_KEY;
                try {
                    getToken(messaging, { vapidKey: keyApi }).then((currentToken) => {
                        // console.log('CURRENT-TOKEN: ', currentToken);
                        if (currentToken) {
                            this.sendTokenToServer(currentToken);
                        } 
                        else {
                            console.warn("Failed to get token.");
                        }
                        
                    }).catch((err) => {
                        console.log(
                            "An error occurred while retrieving token. ",
                            err
                        );
                        this.setTokenSentToServer(false)
                    });
                } catch (e) {
                    console.log(e);
                }
            }
            // isSupported().then((supported) => {
            //     console.log('isSupported: ', supported);
            // });
        },
        isTokenSentToServer: function(currentToken) {
            return (
                window.localStorage.getItem("sentFirebaseMessagingToken") === currentToken
            );
        },
        setTokenSentToServer: function(currentToken) {
            window.localStorage.setItem("sentFirebaseMessagingToken", currentToken ? currentToken : "");
        },
        sendTokenToServer: function(currentToken) {
            if (!this.isTokenSentToServer(currentToken)) {
                console.log('CALL SAVE TOKEN REMOTE...');
                axios.post("usuario/fcm-token", {
                    fcm_token: currentToken
                })
                .then((result) => {
                    if (result.data.success) {
                        this.setTokenSentToServer(currentToken);
                    }
                }).catch((err) => {
                    console.log(
                        "ERRO:  ",
                        err
                    );                    
                });
            }
        },
    },
};

export default {
    firebaseObject,
};