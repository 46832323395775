<template>
    <div class="container">
        <div>
            <usuario-search/>
            <usuario-create/>
            <usuario-update/>
            <usuario-delete/>
            <usuario-update-dados/>
            <usuario-update-password/>
        </div>
        <div class="row remove-padding-left-right-grid">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card card-border-top">
                    <div class="card-body">
                        <h4 class="card-title">Usuários</h4>
                        <p class="card-description text-right"> 
                            <button @click="initSearch()" type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-filter"></i>
                                {{ titleButtonFilter }}
                            </button>
                            <button @click="initAdd()"  type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-plus-circle"></i>Novo
                            </button>
                        </p>
                        <div class="table-responsive" style="min-height: 60vh;">
                            <table class="table table-hover">
                                <thead>
                                    <tr class="d-flex">
                                        <!-- <th class="col-1 text-center"> # </th> -->
                                        <th :style="columns.one"> Nome/ E-mail </th>    
                                        <!-- <th class="col-2 text-left"> Email </th> -->
                                        <th :style="columns.two"> Órgão </th>
                                        <th :style="columns.three"> Setor </th>
                                        <th :style="columns.four"> Data Cadastro </th>
                                        <th :style="columns.five"> Ações </th>
                                    </tr>
                                </thead>
                                <tbody style="font-size: 20px;">
                                    <tr class="d-flex" v-for="(item, index) in usuarios">
                                        <!-- <td class="text-center"> {{ index + 1 }} </td> -->
                                        <td :style="columns.one"> 
                                            {{ item.name }} / <br/>
                                            {{ item.email }} 
                                        </td>
                                        <!-- <td class="text-left"></td> -->
                                        <td :style="columns.two" :class="(item.orgao_nome) ? 'text-left' : 'text-center'"> 
                                            {{ (item.orgao_nome) ? item.orgao_nome : '-' }} 
                                        </td>
                                        <td :style="columns.three" :class="(item.setor_nome) ? 'text-left' : 'text-center'"> 
                                            {{ (item.setor_nome) ? item.setor_nome : '-' }}
                                            <span v-if="item.setor_id" class="text-responsavel-format">
                                                <br/>
                                                {{ (item.responsavel_setor) ? 'Responsável pelo Setor' : '' }}
                                            </span>
                                        </td>
                                        <td :style="columns.four" class="text-left"> 
                                            {{ item.created_at | formatDataTimeBr }} 
                                        </td>
                                        <td :style="columns.five" class="text-center">
                                            <div class="dropdown">
                                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-outline-primary">
                                                    <i class="mdi mdi-format-list-bulleted"></i>
                                                </button>
                                                
                                                <div class="dropdown-menu" 
                                                    x-placement="top-start" >
                                                    <a @click="initUpdate(item)" class="dropdown-item">
                                                        Editar
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initDelete(item)" class="dropdown-item">
                                                        Deletar
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initUpdatePasswordUsuario(item)" class="dropdown-item">
                                                        Alterar Senha
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="usuarios.length == 0">
                                        <td colspan="5" class="text-center">
                                            Nenhum item encontrado.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="mt-3">
                            <pagination :paginationData="pagination" @navigate="goToPage"></pagination>
                        </div>

                    </div>
                </div>
            </div>
        </div>
      
    </div>
</template>

<style type="text/css" scoped>
    .card-title {
        margin-bottom: 0px !important;
        font-size: 18px !important;
        padding-top: 16px !important;
        padding-left: 10px !important;
    }
    .card-description {
        margin-bottom: 8px !important;
    }
    .card-border-top {
        border-top: 3px solid #149AF9 !important;
    }
    .card-body {
        padding: 0px 10px !important;
    }
    .text-responsavel-format {
        color: green;
        font-weight: bold;
    }
    .btn {
        padding: 7px 7px !important;
    }
    table tbody tr td{
        font-size: 13px;
    }
</style>

<script>

    import moment from 'moment';
    import { eventBus } from '../../app';
    import Pagination from '../paginacao/Pagination.vue';

    export default {

        components: {
            Pagination
        },

        data(){
            return {
                titleButtonFilter: 'Filtrar',
                columns: {
                    one: 'width: 30%;',
                    two: 'width: 20%;',
                    three: 'width: 32%;',
                    four: 'width: 12%;',
                    five: 'width: 5%;'
                },
                
                isLoading: false,
                //ARRAYS
                usuarios: [],

                //SEARCH DATA
                filtros: [],

                //PAGINATION
                pagination: {}
            }
        },

        mounted() {
            this.registryEvents();
            this.getUsuarios();
        },

        filters: {
            formatDataTimeBr: function (date) {
                return moment(date).format('DD/MM/YYYY HH:mm');
            }
        },

        methods: {
            //REGISTRY EVENTS
            registryEvents(){
                this.registryEventGetUsuarios();
                this.registryEventGetUsuariosFilter();
            },
            registryEventGetUsuarios(){
                eventBus.$on('eventGetUsuarios', (page) => {
                    this.filtros = [];
                    this.setTitleFilterButton();
                    this.getUsuarios(page);
                });
            },
            registryEventGetUsuariosFilter(){
                eventBus.$on('eventGetUsuariosFilter', (filtrosEvent) => {
                    this.filtros = filtrosEvent;
                    this.setTitleFilterButton();
                    this.getUsuarios();
                });
            },
            //FIM REGISTRY EVENTS
            emitEventOpenModalSearch(){
                eventBus.$emit('eventOpenModalSearchUsuario', this.filtros);
            },
            emitEventOpenModalCreate(){
                eventBus.$emit('eventOpenModalCreateUsuario');
            },
            emitEventOpenModalUpdate(item){
                eventBus.$emit('eventUpdateDadosUsuarioSelected', 
                    item,
                    this.pagination.current_page
                );
            },
            emitEventOpenModalDelete(item){
                eventBus.$emit('eventOpenModalDeleteUsuario', 
                    item,
                    this.pagination.current_page
                );
            },
            emitEventUpdatePasswordUsuario(item){
                eventBus.$emit('eventUpdatePasswordUsuarioSelected', 
                    item,
                    this.pagination.current_page
                );
            },

            initSearch(){
                this.emitEventOpenModalSearch();
            },
            initAdd()
            {
                this.emitEventOpenModalCreate();
            }, 
            initUpdate(item)
            {
                this.emitEventOpenModalUpdate(item);
            },
            initDelete(item)
            {
                this.emitEventOpenModalDelete(item);
            },
            initUpdatePasswordUsuario(usuario){
                this.emitEventUpdatePasswordUsuario(usuario);
            },

            getUsuarios(page)
            {
                var urlUsuarios = '/usuario/listdata';

                this.isLoading = true;

                axios({
                    method: 'GET',
                    url: urlUsuarios,
                    params: {
                        filters: this.filtros,
                        page: page //UTILIZADO NA PAGINAÇÃO
                    }
                })
                .then(response => {
                    this.usuarios = response.data.usuarios.data;
                    this.pagination = response.data.pagination;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao atualizar a pagina');
                });
            },
            goToPage: function (page)
            {
                this.pagination.current_page = page;
                this.getUsuarios(page);
            },
            setTitleFilterButton(){
                if(this.filtros.length > 0){
                    var textFilter = this.filtros.length > 1 ? 'Filtros' : 'Filtro';
                    this.titleButtonFilter = '('+this.filtros.length+') '+textFilter;
                }
                else {
                    this.titleButtonFilter = 'Filtrar'; 
                }
            },
        }
    }
</script>
